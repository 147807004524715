import { Record as ImmutableRecord, List, Map } from "immutable";

import { AVAILABLE_CURRENCIES, WEEKDAYS } from "@typings/globals";
import { PriceSubpart, PriceSubpartDTO } from "@typings/priceSubparts";

export enum PREMISES {
  OFFICE = "OFFICE",
  SHOP = "SHOP",
  WELLNESS_CENTER = "WELLNESS-CENTER",
  MEDICAL_PRACTICE = "MEDICAL-PRACTICE",
  FITNESS_OR_YOGA_CENTER = "FITNESS-OR-YOGA-CENTER",
  SCHOOL_OR_KINDERGARTEN = "SCHOOL-OR-KINDERGARTEN",
  FREELANCE = "FREELANCE",
  OTHER = "OTHER",
}

export interface PremisesEquipments {
  numberOfDesks?: number;
  numberOfToilets?: number;
  numberOfMeetingRooms?: number;
  numberOfConsultationRooms?: number;
  numberOfWaitingRooms?: number;
  numberOfShowers?: number;
  numberOfLockers?: number;
  numberOfClassrooms?: number;
  hasFullyEquippedKitchen?: boolean;
  hasCafeteria?: boolean;
  hasBathroomWithShower?: boolean;
  hasInventory?: boolean;
  hasCanteen?: boolean;
}

export enum EXTRA_SERVICES_B2B {
  B2B_CLEANING = "B2B-CLEANING",
  DISHES = "DISHES",
  INSIDE_FRIDGE = "INSIDE-FRIDGE",
  GLASS_WALLS = "GLASS-WALLS",
  COFFEE_MACHINE = "COFFEE-MACHINE",
  LAUNDRY = "LAUNDRY",
  LAUNDRY_CLOTHS = "LAUNDRY-CLOTHS",
}

export type PremisesServices = Map<PREMISES, PremisesService>;

export type PremisesService = Map<EXTRA_SERVICES_B2B, ImmutableRecord<Task>>;

export interface Task {
  service: EXTRA_SERVICES_B2B;
  duration: number;
  selected?: boolean;
}

export type B2BTasks = Record<
  EXTRA_SERVICES_B2B.B2B_CLEANING,
  ImmutableRecord<Task>
>;

export enum FREQUENCY {
  WEEKLY = "WEEKLY",
  EVERY_2_WEEKS = "EVERY_2_WEEKS",
  EVERY_4_WEEKS = "EVERY_4_WEEKS",
  ONCE = "ONCE",
  MORE_OFTEN = "MORE_OFTEN",
}

export enum CONTACT_US_FORM_FIELDS {
  COMPANY = "companyName",
  NAME = "name",
  PHONE = "phoneNumber",
  EMAIL = "email",
  MESSAGE = "message",
}

export enum PREMISES_COUNTABLE_EQUIPMENTS_OPTIONS {
  DESKS_EMPLOYEES = "numberOfDesks",
  TOILETS = "numberOfToilets",
  MEETING_ROOMS = "numberOfMeetingRooms",
  CONSULTATION_ROOMS = "numberOfConsultationRooms",
  WAITING_ROOMS = "numberOfWaitingRooms",
  SHOWERS = "numberOfShowers",
  LOCKERS = "numberOfLockers",
  CLASSROOMS = "numberOfClassrooms",
}

export enum PREMISES_BOOLEAN_EQUIPMENTS_OPTIONS {
  FULLY_EQUIPPED_KITCHEN = "hasFullyEquippedKitchen",
  CAFETERIA = "hasCafeteria",
  BATHROOM_WITH_SHOWER = "hasBathroomWithShower",
  INVENTORY_STOCK = "hasInventory",
  CANTEEN = "hasCanteen",
}

export interface PriceItem {
  amount: number;
  frequency: FREQUENCY;
}

export interface B2BPricingRaw {
  oncePricePerHour: number;
  weeklyPricePerHour: number;
  every2WeeksPricePerHour: number;
  every4WeeksPricePerHour: number;
  moreOftenPricePerHour: number;
  displayedPriceForFirstBooking: number;
  currency: AVAILABLE_CURRENCIES;
  hasSpecialPricing: boolean;
  specialPricingTranslatedMessage: string | null;
  isFetched?: boolean;
  discountsToDisplay: PriceSubpartDTO[];
}

export interface Service {
  name: EXTRA_SERVICES_B2B;
  duration: number;
}

export interface PremisesServicesRaw {
  premises: PREMISES;
  services: Service[];
}

export interface B2BPricing {
  pricesPerFrequency: List<PriceItem>;
  displayedPriceForFirstBooking: number;
  currency: AVAILABLE_CURRENCIES;
  hasSpecialPricing: boolean;
  specialPricingTranslatedMessage: string | null;
  isFetched?: boolean;
  discountsToDisplay: List<PriceSubpart>;
}

export interface Agent {
  firstName: string;
  lastName: string;
  isPrevious: boolean;
  uuid: string;
  label: string;
  profilePictureUrl?: string;
}

export interface AgentRaw {
  firstName: string;
  lastName: string;
  isPrevious: boolean;
  uuid: string;
  profilePictureUrl?: string;
}

export enum AGENT_PREFERENCE {
  ANY = "ANY",
  PRESELECTED = "PRESELECTED",
}

export enum B2B_SHIFTS {
  FROM_FIVE_TO_EIGHT = "5_8",
  FROM_EIGHT_TO_EIGHTEEN = "8_18",
  FROM_EIGHTEEN_TO_TWENTY_THREE = "18_23",
}

export interface NonStandardFrequencyShifts {
  dayOfWeek: WEEKDAYS;
  shift: B2B_SHIFTS;
  duration: number;
}

export enum CHECKOUT_FORM {
  COMPANY_NAME = "name",
  COMPANY_PHONE = "phone",
  COMPANY_VAT = "vat",
  COMPANY_SDI = "sdi",
  COMPANY_EMAIL = "email",
  COMPANY_LOCATION_ADDRESS = "address",
  COMPANY_LOCATION_FLOOR_AND_DOOR = "floorAndDoor",
  COMPANY_LOCATION_ENTRY_CODE = "entryCode",

  CONTACT_PERSON_FIRST_NAME = "contactPersonFirstName",
  CONTACT_PERSON_LAST_NAME = "contactPersonLastName",
  CONTACT_PERSON_PHONE = "contactPersonPhone",
  CONTACT_PERSON_EMAIL = "contactPersonEmail",

  ADDITIONAL_INFO = "additionalInfo",

  LOGIN_EMAIL = "loginEmail",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
  CITY = "city",

  TERMS = "terms",
  MARKETING_CONSENT = "marketingConsent",

  LOCATION_UUID = "locationUuid",

  KEY_PICK = "keyPick",
  SPECIAL_INSTRUCTIONS = "specialInstructions",
}

export enum KEY_PICK_B2B {
  SOMEONE_WILL_BE_THERE = "SOMEONE_WILL_BE_THERE",
  KEY_WILL_BE_PROVIDED = "KEY_WILL_BE_PROVIDED",
}

export enum CHECKOUT_FORM_ERROR_MAP {
  EMAIL_ALREADY_TAKEN = CHECKOUT_FORM.COMPANY_EMAIL,
  EMAIL_NOT_VALID = CHECKOUT_FORM.COMPANY_EMAIL,
  PHONE_NUMBER_NOT_VALID = CHECKOUT_FORM.COMPANY_PHONE,
  ADDRESS_NOT_VALID = CHECKOUT_FORM.COMPANY_LOCATION_ADDRESS,
  PASSWORD_TOO_SIMPLE = CHECKOUT_FORM.PASSWORD,
  VAT_NUMBER_ALREADY_TAKEN = CHECKOUT_FORM.COMPANY_VAT,
  SDI_CODE_NOT_VALID = CHECKOUT_FORM.COMPANY_SDI,
}

export const CONTACT_US_MODAL_BODY_ID = "contact-us-body";
