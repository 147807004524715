import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { EMPLOYEE_ABSENCE_ENDPOINT } from "@config/soft.endpoints";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("absences");

export const FETCH_EMPLOYEE_ABSENCES = action.make("fetch employee absences");

export const fetchEmployeeAbsences = () => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: EMPLOYEE_ABSENCE_ENDPOINT,
    action: FETCH_EMPLOYEE_ABSENCES,
    method: ASYNC_ACTION_TYPES.GET,
  });

  return dispatch(action);
};
