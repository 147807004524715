import { Record, List } from "immutable";

export enum ABSENCE_UI_STATE {
  PREVIOUS = "PREVIOUS",
  UPCOMING = "UPCOMING",
}

export interface Absence {
  uuid: string;
  agentName: string;
  absenceType: ABSENCE_UI_STATE;
  startDate: string;
  endDate: string;
  uiState: ABSENCE_UI_STATE;
}

export type AbsencesReducer = List<Record<Absence>>;
