import { Record } from "immutable";

import { defaultValues } from "@config/soft.defaultValues";

import { IUserNotifications } from "./userNotifications.typings";

export const UserNotificationsFactory = (
  input?: IUserNotifications,
): Record<IUserNotifications> => {
  return Record<IUserNotifications>(defaultValues.userNotifications)(input);
};
