/**
 * AsyncActionCreatorFactory serves as the main proxy for API communication.
 * We use it to perform async actions in Redux.
 * It is a curried function, whose first invocation serves as the config,
 * while the second one is the actual network request.
 * Please be careful when doing any changes in here.
 */

import * as Sentry from "@sentry/browser";

import * as HTTP_CODE from "@config/httpStatuses";

import { fetchFacade, ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { langFromPathname } from "@services/LangFromPathname";
import { isTranslationKey } from "@services/IsTranslationKey";
import { pipe } from "@services/Pipe";

import {
  AsyncActionResult,
  AsyncActionInstanceOpts,
  IReduxFetchAction,
  IConfig,
  SentryExtraData,
  ACTION_FAIL,
  ACTION_NO_DATA,
  ACTION_SUCCESS,
  ACTION_CANCELED,
  ERROR_ABORT,
  ERROR_NETWORK,
  getUnifiedErrorResponse,
  resolveApiEndpoint,
  defaultMeta,
  constructAbsoluteEndpoint,
  isEndpointDataSafeForTracking,
  isResponseAnError,
  replaceHost,
  replaceLang,
  extractEndpointPathFromFullPath,
  prepareSentryTagValue,
  prepareSentryExtraData,
} from "./AsyncActionCreatorFactory.helpers";

const AsyncActionCreatorFactory =
  (instanceOpts: AsyncActionInstanceOpts) =>
  async <ResponseT = any>(
    input: IReduxFetchAction,
    config: IConfig = {},
    sentryExtraData: SentryExtraData = {},
  ): Promise<AsyncActionResult<ResponseT>> => {
    const {
      action,
      body,
      extra,
      formData,
      meta = defaultMeta,
      method = ASYNC_ACTION_TYPES.GET,
      params,
      url,
    } = input;
    const language = langFromPathname();

    const urlPrefix: string = pipe(
      resolveApiEndpoint,
      constructAbsoluteEndpoint,
      (absoluteEndpoint: string) => replaceHost(absoluteEndpoint, config.host),
      (apiEndpoint: string) => replaceLang(apiEndpoint, language),
    )(config.apiEndpoint);

    /**
     * This is what gets returned when async action is called
     */
    let result: AsyncActionResult<ResponseT> = {
      params,
      status: null,
      ok: false,
      type: `${action}${ACTION_FAIL}`,
      error: null,
      errors: [],
      message: null,
      retry: null,
      translatedErrorMessage: null,
      payload: {},
      extra,
      meta: {
        ...defaultMeta,
        ...meta,
        previousAction: {
          ...meta.previousAction,
          type: action,
          payload: {
            ...(meta.previousAction.payload || {}),
          },
          formData,
        },
        url: `${urlPrefix}${url}`,
      },
      mapApiErrorsToForm: (setFormErrors, opts) => {
        if (result.errors.length) {
          for (const err of result.errors) {
            if (err.field) {
              setFormErrors({
                [err.field]: isTranslationKey(err.message)
                  ? instanceOpts.invalidValueText
                  : err.message,
              });
            } else {
              !opts?.preventGlobalError &&
                instanceOpts.onFetchErrorNotification(language);
              break; // prevent spamming with error notifications for every wrong field
            }
          }
        }

        return result;
      },
      onError: (callback, opts) => {
        const errorPayload = getUnifiedErrorResponse(result);

        if (opts?.matchCode && result.status === opts?.matchCode) {
          callback(errorPayload);
          return result;
        }

        if (
          !opts?.matchCode &&
          HTTP_CODE.CLIENT_ERROR_RANGE.includes(Number(result.status))
        ) {
          callback(errorPayload);
          return result;
        }

        return result;
      },
      onSuccess: callback => {
        if (HTTP_CODE.SUCCESS_RANGE.includes(Number(result.status))) {
          callback(result.payload as { data: ResponseT });
        }
        return result;
      },
      setResult: newResult => {
        result = newResult;
        return result;
      },
    };

    /** Actual resource fetching to & from the server starts here */
    try {
      const fetchConfig = {
        method,
        body: formData ? formData : JSON.stringify(body),
        headers: {
          ...config.headers,
        },
        signal: config.signal,
      };

      const response = await fetchFacade(`${urlPrefix}${url}`, fetchConfig);

      /**
       * Loop through functions provided in middleware array.
       * Return original response.
       * For safety & stability reasons middleware is currently not allowed to modify response,
       * it can only spy on it.
       * Code is prepared in such a way as to allow lifting this limitation in the future.
       */
      const responseAfterMiddleware = instanceOpts.middleware
        ? instanceOpts.middleware?.reduce((_, fn) => {
            fn(response);
            return response;
          }, response)
        : response;

      const { data, status, ok } = responseAfterMiddleware;

      /**
       *  This is for error logging purposes only.
       *  All erroneus network communication should pass through this block
       *  so that it could be reported to Sentry
       * */

      if (isResponseAnError(status)) {
        const canonicalUrl = extractEndpointPathFromFullPath(url);
        const httpMethod = fetchConfig.method?.toUpperCase() || "GET";

        if (isEndpointDataSafeForTracking(canonicalUrl)) {
          Sentry.captureMessage(`API_ERROR ${httpMethod} ${canonicalUrl}`, {
            tags: {
              API_ERROR: true,
              "API_ERROR--endpoint": url,
              "API_ERROR--method": fetchConfig.method,
              "API_ERROR--request": prepareSentryTagValue(fetchConfig.body),
              "API_ERROR--response": prepareSentryTagValue(data),
              "API_ERROR--response-code": status,
              "API_ERROR--error-code": data?.error || null,
            },
            extra: {
              ...prepareSentryExtraData(sentryExtraData),
            },
          });
        }
      }

      if (
        status === HTTP_CODE.OK ||
        status === HTTP_CODE.CREATED ||
        status === HTTP_CODE.ACCEPTED
      ) {
        return result.setResult({
          ...result,
          status,
          ok,
          error: null,
          errors: [],
          message: data.message,
          retry: data.retry,
          type: `${action}${ACTION_SUCCESS}`,
          payload: {
            data,
          },
        });
      }

      if (status === HTTP_CODE.NO_CONTENT) {
        return result.setResult({
          ...result,
          status,
          ok,
          error: null,
          errors: [],
          type: `${action}${ACTION_NO_DATA}`,
        });
      }

      if (HTTP_CODE.SERVER_ERROR_RANGE.includes(status)) {
        instanceOpts.onFetchErrorNotification(language, data.message);
        return result.setResult({
          ...result,
          status,
          ok,
          error: data.error || null,
          errors: data.errors || [],
          message: data.message || null,
          translatedErrorMessage: data.translatedErrorMessage || null,
        });
      }

      return result.setResult({
        ...result,
        status,
        ok,
        error: data.error || null,
        errors: data.errors || [],
        message: data.message || null,
        translatedErrorMessage: data.translatedErrorMessage || null,
      });
    } catch (err) {
      if (err.name === "AbortError") {
        return result.setResult({
          ...result,
          error: ERROR_ABORT,
          type: `${action}${ACTION_CANCELED}`,
          aborted: true,
        });
      }

      instanceOpts.onFetchErrorNotification(language);

      return result.setResult({
        ...result,
        error: ERROR_NETWORK,
        type: `${action}${ACTION_FAIL}`,
      });
    }
  };

export { AsyncActionCreatorFactory };
