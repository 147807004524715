import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

const action = new ReduxActionNameCreator("trans");

export const SET_LANGUAGE = action.make("set language");
export const SET_COUNTRY = action.make("set country");

export const setLanguage = (payload: string) => ({
  type: SET_LANGUAGE,
  payload,
});

export const setCountry = (payload: string) => ({
  type: SET_COUNTRY,
  payload,
});
