import React from "react";

import * as HTTP_CODE from "@config/httpStatuses";
import { LOGIN } from "@config/testIds/login";

import { TranslationDomain } from "@typings/translations";
import { OAUTH_PROVIDERS } from "@typings/oauth";
import { ILoginUser } from "@typings/user";

import { Modal } from "@ui/Molecules/Modal2";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { showToast } from "@containers/Toastify";

import { useTranslate } from "@hooks/useTranslate";
import { FeatureToggleProvider } from "@hooks/useFeatureToggle";

import { ModalContentLogin, FIELDS } from "../ModalContentLogin";

import { useAuthManager } from "./../..";

interface Props {
  title?: string;
  customHeader?: (handleClose?: () => void) => React.ReactNode;
  isMobileFullScreen?: boolean;
  translationScope?: TranslationDomain;
  onModalCloseRequest?: () => void;
  onModalLoginSuccess?: () => void;
  onModalLoginFailed?: (error: string | null) => void;
  application?: "client" | "agent" | null;
  initialValues?: {
    [FIELDS.EMAIL]?: string;
  };
}

const LoginModalComponent = (props: Props): React.ReactElement => {
  const {
    signIn,
    signInByAccessToken,
    restorePassword,
    closeLoginModal,
    setOauthUserData,
    isTemporaryLoginAllowed,
  } = useAuthManager();
  const translate = useTranslate(
    props.translationScope || "batmaid_pwa_generic",
  );
  const [error, setError] = React.useState<string | null>(null);
  const [notification, setNotification] = React.useState<string | null>(null);

  const handleLoginSuccess = () => {
    closeLoginModal();
    props.onModalLoginSuccess?.();
  };

  const handleSignIn = async (credentials: ILoginUser) => {
    // TODO: implement loader
    setError(null);

    const response = await signIn({ ...credentials });

    response.onSuccess(() => {
      handleLoginSuccess();
    });

    if (response.apiResponseData?.redirectUrl) {
      window.location.href = response.apiResponseData.redirectUrl;
      return;
    }

    response.onError(err => {
      const error = response.isErrorTranslated
        ? err
        : translate("batmaid_pwa_generic." + err);

      setError(error || translate("login_failed"));
      props.onModalLoginFailed?.(err);
    });

    return response;
  };

  const loginUserByAccessToken = async (
    provider: OAUTH_PROVIDERS,
    accessToken: string,
    profile: { firstName: string; lastName: string; email: string },
  ) => {
    const response = await signInByAccessToken(provider, accessToken, profile);

    /** Scenario 1: User logs in from Navbar
     *  OAuth login to Batmaid unsuccessful -> show toast
     */
    if (!isTemporaryLoginAllowed && response.status !== HTTP_CODE.OK) {
      showToast(translate("social_media_login_restricted"), { type: "dark" });

      return;
    }

    /**
     * Scenario 2: User logs in from Booking page
     * OAuth login to Batmaid unsuccessful -> user doesn't have a connected OAuth account
     * create 'temporary account' for Booking purpose
     */
    if (isTemporaryLoginAllowed && response.status !== HTTP_CODE.OK) {
      setOauthUserData({
        provider,
        accessToken,
        ...profile,
      });

      closeLoginModal();

      return;
    }

    handleLoginSuccess();
    closeLoginModal();
  };

  const recoverPassword = async (email: string): Promise<AsyncActionResult> => {
    const response = await restorePassword(email);
    response.onSuccess(resp => {
      setNotification(resp.data.message);
      setError(null);
    });

    response.onError(err => {
      setError(err.message);
    });

    return response;
  };

  const handleModalClose = () => {
    closeLoginModal();
    props.onModalCloseRequest?.();
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  return (
    <Modal
      title={props.title || translate("login_modal_title")}
      submitText={translate("button_confirm")}
      cancelText={translate("button_cancel")}
      onRequestClose={handleModalClose}
      withFooter={false}
      size="thin"
      customHeader={
        props.customHeader?.(props.onModalCloseRequest) || undefined
      }
      isMobileFullScreen={props.isMobileFullScreen}
      testId={LOGIN.MODAL_WRAPPER}
    >
      <FeatureToggleProvider>
        <ModalContentLogin
          loginUser={handleSignIn}
          loginUserByAccessToken={loginUserByAccessToken}
          onRecoverPassword={recoverPassword}
          isMobileFullScreen={props.isMobileFullScreen}
          onLoginSuccess={handleLoginSuccess}
          translations={{
            retryLoginText: translate("retry_login"),
            loginFailedText: translate("login_failed"),
            wrongEmailFormatText: translate("email_wrong_format"),
            emailLabelText: translate("email"),
            passwordLabelText: translate("password"),
            resetPasswordButtonText: translate("reset_password"),
            backToLoginButtonText: translate("back_to_login"),
            forgotPasswordLinkText: translate("forgot_password_link"),
            rememberMeCheckboxLabel: translate("remember_me"),
            loginButtonText: translate("login"),
            loginSeparatorText: translate("or"),
            continueWithFacebookText: translate("continue_with_facebook"),
            continueWithGoogleText: translate("continue_with_google"),
            continueWithAppleText: translate("continue_with_apple"),
          }}
          onSetErrors={setError}
          error={error}
          notification={notification}
          onCloseNotification={handleCloseNotification}
          initialValues={props.initialValues}
        />
      </FeatureToggleProvider>
    </Modal>
  );
};

export { LoginModalComponent };
