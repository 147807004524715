export const isSessionStorageAvailable = (): boolean => {
  try {
    window.sessionStorage;
  } catch {
    return false;
  }

  return true;
};

export const isSessionStorageKeyAvailable = (key: string): boolean => {
  if (isSessionStorageAvailable()) {
    return sessionStorage.getItem(key) !== null;
  }
  return false;
};
