import { AnyAction } from "redux";

import {
  JOBS_RATING_ENDPOINT,
  JOBS_RATING_PICTURES_ENDPOINT,
  DELETE_RATING_PICTURE_ENDPOINT,
} from "@config/endpoints";
import { MULTIPART_FORM_DATA } from "@config/contentTypes";

import { BAD_RATING_ISSUES, Issue, SubIssue } from "@typings/rating";
import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { buildUrl } from "@services/BuildUrl";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

import { IStore } from "../reducers";

import { getRatingState } from "./rating.selectors";
import { getRatingEndpointRequest } from "./rating.repository";

const action = new ReduxActionNameCreator("rating");

const SET_NEGATIVE_ISSUE = action.make("set negative issue");
const SET_NEGATIVE_SUBISSUE = action.make("set negative subIssue");
const SET_POSITIVE_ISSUE = action.make("set positive issue");
const FETCH_RATING = action.make("fetch rating");
const SET_SHARE_WITH_AGENT = action.make("set share with batmaid");
const SET_RATING = action.make("set rating");
const LEAVE_PROCESS = action.make("leave process");
const SET_COMMENT = action.make("set comment");
const SEND_RATING = action.make("send rating");
const ADD_PICTURE = action.make("add picture");
const DELETE_PICTURE = action.make("delete picture");

const fetchRating =
  (uuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: buildUrl(JOBS_RATING_ENDPOINT).setPath("uuid", uuid).done(),
      action: FETCH_RATING,
    });

    return dispatch(action);
  };

const setNegativeIssue = (issue: Issue): AnyAction => ({
  type: SET_NEGATIVE_ISSUE,
  payload: issue,
});

const setNegativeSubIssue = (
  issueKey: BAD_RATING_ISSUES,
  subIssue: SubIssue,
): AnyAction => ({
  type: SET_NEGATIVE_SUBISSUE,
  payload: { subIssue, issueKey },
});

const setPositiveIssue = (issue: Issue): AnyAction => ({
  type: SET_POSITIVE_ISSUE,
  payload: issue,
});

const setShareWithAgent = (share: boolean): AnyAction => ({
  type: SET_SHARE_WITH_AGENT,
  payload: share,
});

const setRating = (rating: number): AnyAction => ({
  type: SET_RATING,
  payload: rating,
});

const setComment = (comment: string): AnyAction => ({
  type: SET_COMMENT,
  payload: comment,
});

const leaveProcess = (): AnyAction => ({
  type: LEAVE_PROCESS,
});

const sendRating =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getRatingEndpointRequest(store());

    const action = await asyncActionCreator({
      action: SEND_RATING,
      url: buildUrl(JOBS_RATING_ENDPOINT)
        .setPath("uuid", getRatingState(store()).get("job").uuid)
        .done(),
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

const addPicture =
  (picture: File, loadingUuid: string) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const formData = new FormData();
    formData.append("picture", picture);

    const action = await asyncActionCreator(
      {
        url: buildUrl(JOBS_RATING_PICTURES_ENDPOINT)
          .setPath("uuid", getRatingState(store()).get("job").uuid)
          .done(),
        action: ADD_PICTURE,
        method: ASYNC_ACTION_TYPES.POST,
        formData,
        params: { loadingUuid },
      },
      {
        headers: {
          "Content-Type": MULTIPART_FORM_DATA,
        },
      },
    );

    return dispatch(action);
  };

interface DeletePictureParams {
  pictureUuid: string;
  forceHardDelete: boolean;
}

const deletePicture =
  ({ pictureUuid, forceHardDelete }: DeletePictureParams) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: buildUrl(DELETE_RATING_PICTURE_ENDPOINT)
        .setPath("uuid", getRatingState(store()).get("job").uuid)
        .setPath("pictureUuid", pictureUuid)
        .addQuery("forceHardDelete", `${forceHardDelete}`)
        .done(),
      action: DELETE_PICTURE,
      method: ASYNC_ACTION_TYPES.DELETE,
      params: { pictureUuid },
    });

    return dispatch(action);
  };

export {
  SET_NEGATIVE_ISSUE,
  setNegativeIssue,
  SET_NEGATIVE_SUBISSUE,
  setNegativeSubIssue,
  fetchRating,
  FETCH_RATING,
  setShareWithAgent,
  SET_SHARE_WITH_AGENT,
  setRating,
  SET_RATING,
  leaveProcess,
  LEAVE_PROCESS,
  setPositiveIssue,
  SET_POSITIVE_ISSUE,
  setComment,
  SET_COMMENT,
  sendRating,
  SEND_RATING,
  addPicture,
  ADD_PICTURE,
  deletePicture,
  DELETE_PICTURE,
};
