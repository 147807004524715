import { Record, List, Map, mergeWith } from "immutable";
import unionby from "lodash.unionby";

import { defaultValues } from "@config/soft.defaultValues";

import {
  EXTRA_SERVICES_BOOKING,
  BookingTasks,
  Task,
  CLEANING_FREQUENCY,
  PART_OF_DAY,
  IAgent,
  IAgentRaw,
  EXTRA_SERVICES_LEGACY,
  AGENT_PREFERENCE,
} from "@typings/booking";
import { AVAILABLE_CURRENCIES } from "@typings/globals";
import { Contract, EmployeeDetails } from "@typings/contracts";

import {
  generateStaticDays,
  generateStaticBookingHoursForBatwork,
} from "@services/StaticTimeGenerators";

import {
  HourItem,
  DayItemRaw,
  BookingAvailabilityRaw,
  BookingAvailability,
  BookingReducerState,
} from "./booking.typings";

const {
  booking: { tasks, cleaningFrequency },
} = defaultValues;

export const bookingFormInitialState = {
  serviceLocationId: 0,
  address: "",
  floorAndDoor: "",
  entryCode: "",
};

const hoursMapFactory = (input: DayItemRaw[]) =>
  input.reduce(
    (
      acc: Map<string, List<HourItem>>,
      curr: DayItemRaw,
    ): Map<string, List<HourItem>> => {
      return acc.set(
        curr.day,
        List(
          curr.hours.map((item): HourItem => {
            const hour = item.time.split(":")[0];
            let partOfDay: PART_OF_DAY = PART_OF_DAY.MORNING;

            if (Number(hour) >= 12 && Number(hour) < 17) {
              partOfDay = PART_OF_DAY.AFTERNOON;
            } else if (Number(hour) >= 17) {
              partOfDay = PART_OF_DAY.EVENING;
            }

            return {
              duration: item.time,
              availableAgents: List(item.availableAgents),
              partOfDay,
            };
          }),
        ),
      );
    },
    Map(),
  );

export const pricingInitialState = {
  pricesPerFrequency: List(
    Object.keys(CLEANING_FREQUENCY)
      .filter(key => key !== CLEANING_FREQUENCY.MORE_OFTEN)
      .map(key => ({
        frequency: key as CLEANING_FREQUENCY,
        amount: 0,
        selected: key === cleaningFrequency,
      })),
  ),
  displayedPriceForFirstBooking: 0,
  currency: AVAILABLE_CURRENCIES.CHF,
  hasSpecialPricing: false,
  specialPricingTranslatedMessage: "",
  totalServiceVouchers: null,
};

export const bookingAvailabilityInitialState = {
  defaultSelection: "",
  canSelectPreviousWeek: true,
  canSelectNextWeek: true,
  weekStartDate: "",
  days: List(generateStaticDays()),
  hours: hoursMapFactory(
    generateStaticDays().map(day => ({
      day,
      hours: generateStaticBookingHoursForBatwork(),
    })),
  ),
  hasNoAvailableDates: true,
};

/**
 * The /booking/availability endpoint only returns days with available agents.
 * Because of that we need to generate the calendar days on the frontend
 * and then update them with the availability data from the API.
 */
export const bookingAvailabilityFactory = (
  input: BookingAvailabilityRaw,
): Record<BookingAvailability> => {
  const staticDays = generateStaticDays(input.weekStartDate);
  const staticHours = staticDays.map(day => ({
    day,
    hours: generateStaticBookingHoursForBatwork(),
  }));

  const apiHoursMap = hoursMapFactory(input.days);
  const placeholderHoursMap = hoursMapFactory(staticHours);

  const mergedHours = mergeWith<Map<string, List<HourItem>>>(
    (oldVal, newVal) =>
      List(unionby(newVal.toJS(), oldVal.toJS(), "duration")).sortBy(
        (item: any) => item.duration,
      ),
    placeholderHoursMap,
    apiHoursMap,
  );

  return Record<BookingAvailability>(bookingAvailabilityInitialState)({
    ...input,
    hours: mergedHours,
    days: List(staticDays),
    hasNoAvailableDates: !input.days.length,
  });
};

export const taskFactory = (input: Task): Record<Task> =>
  Record<Task>({ ...input })();

export const bookingTasks = Record<BookingTasks>({
  [EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS,
    duration: tasks[EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS].selected,
  }),
  [EXTRA_SERVICES_BOOKING.IRONING]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.IRONING,
    duration: tasks.IRONING.duration,
    selected: tasks.IRONING.selected,
  }),
  [EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY,
    duration: tasks[EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY].selected,
  }),
  [EXTRA_SERVICES_BOOKING.INSIDE_OVEN]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.INSIDE_OVEN,
    duration: tasks[EXTRA_SERVICES_BOOKING.INSIDE_OVEN].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.INSIDE_OVEN].selected,
  }),
  [EXTRA_SERVICES_BOOKING.INSIDE_CABINETS]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.INSIDE_CABINETS,
    duration: tasks[EXTRA_SERVICES_BOOKING.INSIDE_CABINETS].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.INSIDE_CABINETS].selected,
  }),
  [EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE,
    duration: tasks[EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE].selected,
  }),
  [EXTRA_SERVICES_BOOKING.HOME_CLEANING]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.HOME_CLEANING,
    duration: tasks[EXTRA_SERVICES_BOOKING.HOME_CLEANING].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.HOME_CLEANING].selected,
    bedrooms: defaultValues.booking.bedroomNo,
    bathrooms: defaultValues.booking.bathroomNo,
  }),
  [EXTRA_SERVICES_BOOKING.BONUS_15]: taskFactory({
    service: EXTRA_SERVICES_BOOKING.BONUS_15,
    duration: tasks[EXTRA_SERVICES_BOOKING.BONUS_15].duration,
    selected: tasks[EXTRA_SERVICES_BOOKING.BONUS_15].selected,
    adminOnly: true,
  }),
})();

const initialPreviousEmployee: EmployeeDetails = {
  firstName: "",
  lastName: "",
  profilePictureUrl: null,
  email: null,
  dateOfBirth: null,
  homePhone: null,
  mobilePhone: null,
  address: "",
  zip: "",
  city: "",
  workPermit: "",
  socialNumber: "",
  bankAccount: null,
  contracts: [],
};

export const previousEmployeeFactory = (
  input: EmployeeDetails,
): Record<EmployeeDetails> => Record(initialPreviousEmployee)(input);

const agentFactory = (agent: IAgentRaw): Record<IAgent> =>
  Record({
    firstName: "",
    lastName: "",
    uuid: "",
    isPrevious: false,
    label: "",
  })({
    firstName: agent.firstName,
    lastName: agent.lastName,
    uuid: agent.uuid,
    isPrevious: agent.isPrevious,
    label: `${agent.firstName} ${agent.lastName}`,
  });

export const agentsFactory = (agents: IAgentRaw[]): List<Record<IAgent>> =>
  List(agents.map(agentFactory));

export const bookingFromContractFactory = (
  input: Contract,
  previousTasks: Record<BookingTasks>,
): Partial<BookingReducerState> => {
  const updatedTasks = input.tasks
    // @ToDo: remove this enum value when "Micheal Owen gate" gets fixed
    .map(task => ({
      ...task,
      service:
        //@ts-ignore
        task.service === EXTRA_SERVICES_LEGACY.INSIDE_OWEN
          ? EXTRA_SERVICES_BOOKING.INSIDE_OVEN
          : task.service,
    }))
    .reduce((acc: Record<BookingTasks>, item: Task) => {
      return acc.mergeIn([item.service], {
        duration: item.duration,
        selected: true,
      });
    }, previousTasks);

  const updatedTasksWithHomeCleaning = updatedTasks.mergeIn(
    [EXTRA_SERVICES_BOOKING.HOME_CLEANING],
    {
      bedrooms: input.bedrooms,
      bathrooms: input.bathrooms,
    },
  );

  return {
    ...input,
    preselectedAgentUuid: input.agentUuid,
    tasks: updatedTasksWithHomeCleaning,
    agentPreference: AGENT_PREFERENCE.PRESELECTED,
  };
};
