import { combineReducers } from "redux";
import { Record } from "immutable";

import { ModalReducer } from "@typings/modals";

import {
  LocalisationState,
  useLocalisationReducer,
} from "@hooks/useLocalisation/redux";

import { payslipsReducer } from "@soft/redux/payslips";

import { PayslipReducer } from "./payslips/payslip.typings";
import { localisationReducer, LocalisationReducer } from "./localisation";
import { userReducer, UserReducer } from "./user";
import {
  serviceLocationReducer,
  ServiceLocationReducer,
} from "./serviceLocation";
import { jobsReducer, JobsReducer } from "./jobs";
import { modalsReducer } from "./modals";
import { userOptionsReducer, UserOptionsReducer } from "./userOptions";
import { loadingReducer, LoadingReducer } from "./loading";
import { holidaysReducer, HolidayReducer } from "./holidays";
import { profileReducer, ProfileReducer } from "./profile";
import { oauthReducer, OAuthReducer } from "./oauth";
import { navigationReducer } from "./navigation";
import {
  bookingsToValidateReducer,
  BookingsToValidateReducer,
} from "./bookingsToValidate";
import { locationsReducer, LocationsReducer } from "./locations";
import { paymentReducer, PaymentReducer } from "./payment";
import {
  userNotificationsReducer,
  UserNotificationsReducer,
} from "./userNotifications";
import { salaryReducer, SalaryReducer } from "./salary";
import { contractsReducer, ContractsReducer } from "./contracts";
import { absencesReducer, AbsencesReducer } from "./absences";
import { bookingReducer, BookingReducer } from "./booking";
import {
  declareEmployeeReducer,
  DeclareEmployeeReducer,
} from "./declareEmployee";
import {
  accountCreationReducer,
  AccountCreationReducer,
} from "./accountCreation";
import { ratingReducer, RatingReducer } from "./rating";

type IStoreKeys = keyof IStore;
export type IStoreValues = IStore[IStoreKeys];

export interface IStore extends LocalisationState {
  user: UserReducer;
  serviceLocation: ServiceLocationReducer;
  localisation: LocalisationReducer;
  jobs: Record<JobsReducer>;
  modals: ModalReducer;
  userOptions: UserOptionsReducer;
  loading: LoadingReducer;
  holidays: HolidayReducer;
  profile: Record<ProfileReducer>;
  oauth: OAuthReducer;
  navigation: boolean;
  bookingsToValidate: Record<BookingsToValidateReducer>;
  locations: Record<LocationsReducer>;
  payment: Record<PaymentReducer>;
  userNotifications: UserNotificationsReducer;
  salary: SalaryReducer;
  contracts: Record<ContractsReducer>;
  absences: AbsencesReducer;
  booking: BookingReducer;
  declareEmployee: Record<DeclareEmployeeReducer>;
  accountCreation: Record<AccountCreationReducer>;
  rating: Record<RatingReducer>;
  payslips: PayslipReducer;
}

export const allReducers = {
  ...useLocalisationReducer,
  localisation: localisationReducer,
  user: userReducer,
  serviceLocation: serviceLocationReducer,
  jobs: jobsReducer,
  modals: modalsReducer,
  userOptions: userOptionsReducer,
  loading: loadingReducer,
  holidays: holidaysReducer,
  profile: profileReducer,
  oauth: oauthReducer,
  navigation: navigationReducer,
  bookingsToValidate: bookingsToValidateReducer,
  locations: locationsReducer,
  payment: paymentReducer,
  userNotifications: userNotificationsReducer,
  salary: salaryReducer,
  contracts: contractsReducer,
  absences: absencesReducer,
  booking: bookingReducer,
  declareEmployee: declareEmployeeReducer,
  accountCreation: accountCreationReducer,
  rating: ratingReducer,
  payslips: payslipsReducer,
};

const combinedReducers = combineReducers(allReducers);

export { combinedReducers };
