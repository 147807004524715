import { List, Map, Record, Set } from "immutable";

import { KEY_PICK, EXTRA_SERVICES_LEGACY } from "@typings/booking";
import {
  ACTION_HIGHLIGHT,
  CANCELLATION_FEE,
  CANCELLATION_REASON,
  CONTACT_PERIODICITY,
  EXTRA_SERVICES_ENUM,
  ICancellationData,
  ICancellationInputData,
  IExtraService,
  Job,
  JobAction,
  JobLocation,
  JobLocationInput,
  JOB_ACTION,
  JOB_FETCH_TYPE,
  ListOfJobsByLocation,
  MapOfJobs,
  UI_STATE_ENUM,
  TYPE_ENUM,
  RESCHEDULING_TRIGGERED_BY,
  AGENT_REPLACEMENT_STATUS,
} from "@typings/jobs";
import { AVAILABLE_CURRENCIES, PAYMENT_METHODS } from "@typings/globals";

const extractUuidFromBatchUrl = (url: string | null): string | null => {
  if (url) {
    return url.split("&after=")[1];
  }

  return null;
};

// Job Locations

const SingleJobLocationFactory = (
  input: JobLocationInput,
  fetchState?: JOB_FETCH_TYPE,
  oldJob?: Record<JobLocation>,
): Record<JobLocation> => {
  const initialShape = {
    jobs: List(),
    location: "",
    state: "",
    city: "",
    total: 0,
    uuid: "",
    prevMoreBatch: oldJob ? oldJob.get("prevMoreBatch") : null,
    nextMoreBatch: oldJob ? oldJob.get("nextMoreBatch") : null,
  };

  const result = {
    ...input,
    total: Number(input.total),
    jobs: List(input.jobs),
  };

  if (fetchState === JOB_FETCH_TYPE.PREVIOUS) {
    return Record<JobLocation>({
      ...initialShape,
    })({
      ...result,
      prevMoreBatch: extractUuidFromBatchUrl(input.nextBatchUrl),
    });
  }

  return Record<JobLocation>({
    ...initialShape,
  })({
    ...result,
    nextMoreBatch: extractUuidFromBatchUrl(input.nextBatchUrl),
  });
};

const CollectionJobsLocationFactory = (
  input: JobLocationInput[],
  fetchState?: JOB_FETCH_TYPE,
): ListOfJobsByLocation => {
  return input.reduce(
    (acc: Map<string, Record<JobLocation>>, item: JobLocationInput) =>
      acc.set(item.uuid, SingleJobLocationFactory(item, fetchState)),
    Map(),
  );
};

export const MapJobsLocationFactory = (
  input: JobLocationInput[],
  initialData?: ListOfJobsByLocation,
  fetchState?: JOB_FETCH_TYPE,
): ListOfJobsByLocation => {
  if (Map.isMap(initialData) && initialData.size > 0) {
    return input.reduce((acc: ListOfJobsByLocation, item: JobLocationInput) => {
      const locationIdentifier = item.uuid;
      const currentJobs: List<string> =
        acc.getIn([locationIdentifier, "jobs"]) || List();
      const newJobs: string[] = currentJobs
        .toSet()
        .union(Set(item.jobs))
        .toJS();

      return acc.set(
        locationIdentifier,
        SingleJobLocationFactory(
          {
            ...item,
            jobs: newJobs,
          },
          fetchState,
          initialData.get(locationIdentifier),
        ),
      );
    }, initialData);
  }

  return CollectionJobsLocationFactory(input, fetchState);
};

// Job

const JobActionFactory = (input: JobAction): Record<JobAction> =>
  Record<JobAction>({
    key: JOB_ACTION.DETAILS,
    highlight: ACTION_HIGHLIGHT.DEFAULT,
    translationKey: "key",
    href: "",
  })(input);

const ExtraServicesFactory = (
  input: IExtraService,
): Record<IExtraService> | any => {
  // @TODO this can be removed when Micheal Owen is gone 💀
  if (input.type === EXTRA_SERVICES_LEGACY.INSIDE_OWEN) {
    return Record<IExtraService>({
      duration: 0,
      type: EXTRA_SERVICES_ENUM.HOME_CLEANING,
    })({ ...input, type: EXTRA_SERVICES_ENUM.INSIDE_OVEN });
  }

  return Record<IExtraService>({
    duration: 0,
    type: EXTRA_SERVICES_ENUM.HOME_CLEANING,
  })(input);
};

const SingleJobFactory = (input: Job): Record<Job> =>
  Record<Job>({
    actions: List(),
    agent: "",
    agentPhoneNumber: null,
    agentProfilePictureUrl: null,
    amount: 0,
    bathrooms: 0,
    bedrooms: 0,
    canBeRated: false,
    cancellationState: undefined,
    cancellationProcess: undefined,
    cancellationFeeLevel: CANCELLATION_FEE.NO_CANCELLATION_FEE,
    contractPeriodicity: CONTACT_PERIODICITY.ONCE,
    currency: AVAILABLE_CURRENCIES.CHF,
    duration: 0,
    executionDate: "1970-01-01",
    extraServices: List(),
    floorAndDoor: "",
    rescheduledAutomatically: false,
    hasCancellationFee: false,
    keyPick: KEY_PICK.SOMEONE_AT_HOME,
    location: "",
    locationComment: "",
    locationUuid: "",
    rating: 0,
    ratingComment: "",
    specialInstructions: null,
    uiState: UI_STATE_ENUM.UPCOMING,
    type: TYPE_ENUM.HOME_CLEANING,
    uuid: "0",
    agentUuid: "0",
    warrantyIncluded: false,
    subscriptionUuid: null,
    contractUuid: "",
    hasOnboardingCompleted: false,
    cleaningChecklist: Record({
      canBeFilled: false,
      isCreated: false,
    })(),
    rescheduling: Record({
      rescheduled: false,
      triggeredBy: RESCHEDULING_TRIGGERED_BY.NONE,
      pendingClientPreferredDateRequest: false,
    })(),
    agentAbsenceAutomationProcess: Record({
      status: AGENT_REPLACEMENT_STATUS.NONE,
      rescheduleDetails: {
        triggeredBy: RESCHEDULING_TRIGGERED_BY.NONE,
      },
      optInDetails: {
        absentAgentFullName: null,
      },
    })(),
  })({
    ...input,
    executionDate: input.executionDate.replace(" ", "T"),
    // @ts-ignore
    actions: input.actions.map(JobActionFactory),
    // @ts-ignore
    extraServices: List(input.extraServices.map(ExtraServicesFactory)),
  });

export const MapJobsFactory = (
  input: Job[],
  initialData: MapOfJobs = Map(),
): MapOfJobs => {
  return input.reduce((acc: Map<string, Record<Job>>, item: Job) => {
    return acc.set(item.uuid, SingleJobFactory(item));
  }, initialData);
};

// Cancellation
const ReasonsFactory = (input: any[]): List<CANCELLATION_REASON> =>
  List(
    input.map((item: any) => (typeof item === "string" ? item : item.reason)),
  );

export const CancellationFactory = (
  input: ICancellationInputData,
): Record<ICancellationData> =>
  Record<ICancellationData>({
    cancellationRequestUuid: "",
    currency: "CHF",
    feeAmount: 0,
    feeInHours: 0,
    reasons: List(),
    showIsCancellingContract: false,
    showIsFeeSkippedByAdmin: false,
    showIsCancellationCountingSkippedByAdmin: false,
    isFreeCancellation: false,
    freeCancellationsLeft: 0,
    lateJobCancellationThreshold: null,
    feeLevel: null,
  })({
    ...input,
    reasons: List.isList(input.reasons)
      ? input.reasons
      : ReasonsFactory(input.reasons),
  });

export const CancellationPaymentFactory = (
  input: { method: PAYMENT_METHODS }[],
) => List(input.map((item: { method: PAYMENT_METHODS }) => item.method));
