import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AppConfig } from "@typings/appConfig";
import { AVAILABLE_COUNTRIES } from "@typings/globals";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.US],
  booking: {
    commitments: {
      enabled: false,
    },
  },
  b2b: {},
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
};

export { config as us };
