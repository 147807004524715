/**
 * Service used for modifying urls.
 * Requires calling done() as the last method.
 * Example usage:
 *
 * buildUrl("http://awesome-url.com?queryOne={queryOneValue}&queryTwo={queryTwoValue}")
 *  .setQuery("queryOne", "1")
 *  .setQuery("queryTwo", "2", { optional: true })
 *  .done();
 */

import { extractQueries } from "@services/QueryParamsParser";
import { safelyAddQuery } from "@services/SafelyAddQuery";

type SetPath = <T = string>(key: string, input: T) => PublicApi;
type SetQuery = <T = string>(
  key: string,
  input: T,
  opts?: { optional: boolean },
) => PublicApi;
type AddQuery = <T = string | number | null>(
  key: string,
  input?: T,
  opts?: { encode?: boolean; optional?: boolean },
) => PublicApi;
type Done = () => string;

interface PublicApi {
  setPath: SetPath;
  setQuery: SetQuery;
  addQuery: AddQuery;
  done: Done;
}

interface Options {
  keyType: "bracket" | "colon";
}

export const buildUrl = (url: string, options?: Options): PublicApi => {
  const { keyType = "bracket" } = options ?? {};

  let modifiedUrl = url;

  /**
   * Pubic, chainable method.
   * Safely sets path param value.
   * This method won't add new path, it can only set value of a path that's already been in the url.
   * * Example usage for bracket type:
   * buildUrl("/app/user/{uuid}")
   *   .setPath("uuid", "123")
   *   .done(); // "/app/user/123"
   * * Example usage for colon type:
   * buildUrl("/app/user/:uuid")
   *  .setPath("uuid", "123")
   *  .done(); // "/app/user/123"
   */
  const setPath: SetPath = (key, input) => {
    const value = String(input);
    const brackedKey = `{${key}}`;
    const colonedKey = `:${key}`;
    const splittedUrl = modifiedUrl.split("?");
    const bareUrl = splittedUrl.shift();

    if (keyType === "bracket" && !!bareUrl?.includes(brackedKey)) {
      modifiedUrl = [bareUrl.replace(brackedKey, value), ...splittedUrl].join(
        "?",
      );
    }

    if (keyType === "colon" && !!bareUrl?.includes(colonedKey)) {
      modifiedUrl = [bareUrl.replace(colonedKey, value), ...splittedUrl].join(
        "?",
      );
    }

    return api;
  };

  /**
   * Pubic, chainable method.
   * Safely sets query param value.
   * This method won't add new queries, it can only set value of a query that's already been in the url.
   * When used with opts.optional query will be removed if given non-truthy value.
   */
  const setQuery: SetQuery = (key, input, opts) => {
    const value = String(input);
    const queries = extractQueries(modifiedUrl);
    const bareUrl = modifiedUrl.split("?")[0];

    if (!queries) {
      return api;
    }

    const replacedQueries = Object.keys(queries).reduce(
      (acc: Record<string, string>, item) => {
        acc = {
          ...acc,
          [item]: String(item) === key ? value : queries[item],
        };

        if (
          String(item) === key &&
          opts &&
          opts?.optional &&
          (!value || value === "null" || value === "undefined")
        ) {
          delete acc[item];
        }

        return acc;
      },
      {},
    );

    const newUrl = Object.entries(replacedQueries).reduce(
      (acc: string, item, idx) => {
        const [key, val] = item;
        return acc + (idx > 0 ? "&" : "?") + key + "=" + val;
      },
      bareUrl,
    );

    modifiedUrl = newUrl;

    return api;
  };

  const addQuery: AddQuery = (key, input, opts) => {
    const value = String(input);

    if (
      opts?.optional &&
      (value === "null" || value === "undefined" || value === "")
    ) {
      return api;
    }

    const query = opts?.encode
      ? `${key}=${encodeURIComponent(value)}`
      : `${key}=${value}`;

    const newUrl = safelyAddQuery(modifiedUrl, query);

    modifiedUrl = newUrl;
    return api;
  };

  /**
   * Public, non-chainable method.
   * Use to return final value.
   */
  const done: Done = () => modifiedUrl;

  const api: PublicApi = {
    setPath,
    setQuery,
    addQuery,
    done,
  };

  return api;
};
