import * as React from "react";
import { useTheme } from "styled-components";

import { rgba } from "@ui/helpers";

import { style, Icon } from "./Symbolicons";

const Spinner = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      viewBox="-25 -25 100 100"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <defs>
        <linearGradient id="gr-simple" x1="0" y1="0" x2="100%" y2="100%">
          <stop
            stopColor={
              props.color ? rgba(props.color, 0.1) : "rgba(255,255,255,.2)"
            }
            offset="10%"
          />
          <stop
            stopColor={props.color || "rgba(255,255,255,.7)"}
            offset="90%"
          />
        </linearGradient>
      </defs>
      <circle
        fill="none"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="6px"
        cx="26"
        cy="27"
        r="26"
        stroke="url(#gr-simple)"
      />
      <path
        fill="none"
        stroke={props.color || theme.colors.white}
        strokeWidth="6px"
        d="M25,0A24.92,24.92,0,0,1,42.68,7.32"
        transform="translate(1 2)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          from="0 26 27"
          to="360 26 27"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export { Spinner };
