import { Record as ImmutableRecord } from "immutable";

import { AVAILABLE_LANGS } from "@typings/globals";
import {
  TrustMandate2020Urls,
  trustMandate2020UrlsRaw,
} from "@typings/userOptions";

export const initialTrustMandateUrlsState = Object.values(
  AVAILABLE_LANGS,
).reduce((acc, item) => {
  acc[item] = "";
  return acc;
}, {} as Record<AVAILABLE_LANGS, string>);

export const TrustMandate2020UrlsFactory = (
  input: trustMandate2020UrlsRaw,
): ImmutableRecord<TrustMandate2020Urls> => {
  const transformedInput = input.reduce(
    (acc: { [k: string]: string }, curr) => {
      acc[curr.locale] = curr.url;
      return acc;
    },
    {},
  );

  return ImmutableRecord<TrustMandate2020Urls>(initialTrustMandateUrlsState)(
    transformedInput,
  );
};
