import { Record } from "immutable";

import { IUser, IUserImpersonator } from "@typings/user";

const ImpersonatorFactory = (input: IUserImpersonator | null) => {
  if (input === null) {
    return input;
  }

  return Record<IUserImpersonator>({
    userName: "",
  })(input);
};

export const UserFactory = (input?: IUser): Record<IUser> =>
  Record<IUser>({
    email: "",
    exitUserUrl: "",
    firstName: "",
    impersonator: null,
    lastName: "",
    logoutUrl: "",
    roles: [],
    userName: "",
    preferredZipCode: "",
    feistelHash: null,
    uuid: "",
    hexHumanId: "",
    identifier: "",
    companyName: "",
    defaultCleaningType: null,
    userType: null,
    paymentMethodStatus: null,
    hasOverduePayments: false,
    isInvoiced: false,
  })({
    ...input,
    impersonator: ImpersonatorFactory(
      typeof input !== "undefined" ? input.impersonator : null,
    ),
  });
