import * as React from "react";
import { Field, FieldProps, FormikProps } from "formik";

import { ITheme } from "@ui/themes/ThemeInterface";
import { Input, INPUT_ERROR_CLASS } from "@ui/Atoms/Form";
import { TestWrapper } from "@ui/Atoms";

import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";

import { Markdown } from "@containers/Markdown";

import { useGooglePlaces, getFormattedAddress } from "@hooks/useGooglePlaces";

export interface Props {
  id?: string;
  className?: string;
  theme?: ITheme;
  label?: string;
  name: string;
  defaultValue?: string;
  value?: string;
  isDisabled?: boolean;
  type?: string;
  isHidden?: boolean;
  maxLength?: number;
  placeholder?: string;
  icon?: React.ReactElement;
  isLabelFloating?: boolean;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  refElement?: React.RefObject<any>;
  autoFocus?: boolean;
  testId?: string;
  errorTestId?: string;
  hasSmallFloatLabel?: boolean;
  withPlacesAutocomplete?: boolean;
  optionalLabel?: string;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  gtmActionOnFirstBlur?: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  onPlaceSelected?: (address: string) => void;
}

const FormikInputWithError = (props: Props): React.ReactElement => {
  const { ref } = props.withPlacesAutocomplete
    ? useGooglePlaces({
        options: {
          componentRestrictions: {
            country: [resolveCountryFromUrl()],
          },
        },
        onPlaceSelected: place => {
          props.onPlaceSelected?.(getFormattedAddress(place));
        },
      })
    : { ref: React.createRef() };

  const getErrors = (form: FormikProps<any>) => {
    const result =
      form.errors[props.name] && form.touched[props.name]
        ? form.errors[props.name]
        : undefined;

    return typeof result === "string" ? (
      <TestWrapper testId={props.errorTestId}>
        <div>
          <Markdown>{result}</Markdown>
        </div>
      </TestWrapper>
    ) : undefined;
  };

  return (
    <Field>
      {({ form }: FieldProps) => {
        return (
          <TestWrapper testId={props.testId}>
            <Input
              refElement={props.withPlacesAutocomplete ? ref : props.refElement}
              id={props.id}
              className={`${props.className} ${
                getErrors(form) ? INPUT_ERROR_CLASS : ""
              }`}
              name={props.name}
              label={props.label}
              optionalLabel={props.optionalLabel}
              autoFocus={props.autoFocus}
              isLabelFloating={props.isLabelFloating}
              value={props.value}
              defaultValue={props.defaultValue}
              onBlur={event => {
                form.setFieldTouched(props.name, true);
                props.handleChange(event);
                props.handleBlur && props.handleBlur(event);

                if (props.gtmActionOnFirstBlur && !form.touched[props.name]) {
                  props.gtmActionOnFirstBlur();
                }
              }}
              disabled={props.isDisabled}
              type={props.type}
              isHidden={props.isHidden}
              maxLength={props.maxLength}
              placeholder={
                props.withPlacesAutocomplete ? "" : props.placeholder
              }
              onChange={props.handleChange}
              as={props.as}
              onKeyPress={props.onKeyPress}
              icon={props.icon}
              errors={getErrors(form)}
              hasSmallFloatLabel={props.hasSmallFloatLabel}
            />
          </TestWrapper>
        );
      }}
    </Field>
  );
};

export { FormikInputWithError };

FormikInputWithError.defaultProps = {
  isLabelFloating: true,
};
