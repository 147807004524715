import { AppConfig } from "@typings/appConfig";
import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";
import { EXTRA_SERVICES_B2B, FREQUENCY } from "@typings/b2b";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.LU],
  booking: {
    commitments: {
      enabled: false,
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
        "periodicity_more_often",
      ],
      rows: [
        [false, true, true, false],
        [true, true, true, true],
        [true, true, true, true],
        [false, true, true, true],
        [true, true, true, true],
      ],
    },
  },
  b2b: {
    disabledExtrases: [EXTRA_SERVICES_B2B.LAUNDRY_CLOTHS],
    contactDetails: {
      phone: "+352 28 80 32 70",
      email: "client@batmaid.lu",
    },
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLSc_HlPfvG5BUeSEiA-AHgPv7GGc6U-5neGAHme9hSzwxL9eNA/viewform?usp=sf_link",
      [AVAILABLE_LANGS.FR]:
        "https://docs.google.com/forms/d/e/1FAIpQLSckpBEixstryi-QV3BMzZQrHghZKIVDgIFGhi3oggNI8NapNw/viewform?usp=sf_link",
    },
  },
};

export { config as lu };
