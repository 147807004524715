import { AnyAction } from "redux";
import { Record } from "immutable";

import { AVAILABLE_LANGS, AVAILABLE_COUNTRIES } from "@typings/globals";

import { SET_LANGUAGE, SET_COUNTRY } from "./localisation.actions";
import {
  LocalisationReducer,
  LocalisationReducerState,
} from "./localisation.typings";

const initialState = Record<LocalisationReducerState>({
  language: AVAILABLE_LANGS.EN,
  country: AVAILABLE_COUNTRIES.CH,
});

const localisationReducer = (
  store: LocalisationReducer = initialState(),
  action: AnyAction,
) => {
  const { type } = action;

  switch (type) {
    case SET_LANGUAGE:
      return store.set("language", action.payload);

    case SET_COUNTRY:
      return store.set("country", action.payload);

    default:
      return store;
  }
};

export { localisationReducer };
