import { CURRENCY_PER_COUNTRY } from "@config/consts";

import { AppConfig } from "@typings/appConfig";
import { CREDIT_CARDS_PROVIDERS } from "@typings/payments";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";
import { EXTRA_SERVICES_B2B, FREQUENCY } from "@typings/b2b";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.IT],
  booking: {
    commitments: {
      enabled: false,
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
      ],
      rows: [
        [false, true, true],
        [true, true, true],
        [true, true, true],
        [false, true, true],
        [true, true, true],
      ],
    },
  },
  b2b: {
    disabledExtrases: [EXTRA_SERVICES_B2B.LAUNDRY_CLOTHS],
    contactDetails: {
      phone: "(+39) 02 89601407",
      email: "supporto@batmaid.it",
    },

    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  availableCreditCardsProviders: [
    CREDIT_CARDS_PROVIDERS.VISA,
    CREDIT_CARDS_PROVIDERS.MASTERCARD,
    CREDIT_CARDS_PROVIDERS.AMEX,
    CREDIT_CARDS_PROVIDERS.POSTEPAY,
  ],
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLSfhApR5DIkOztWR60buDOBIyV56PGWG4igx0WW-im1jLpoxLQ/viewform",
      [AVAILABLE_LANGS.IT]:
        "https://docs.google.com/forms/d/e/1FAIpQLScMFzT2xbgOMKMPQSu98R0xFcvoEGTfmiLeXUDwf7aVz0OiPg/viewform",
    },
  },
};

export { config as it };
