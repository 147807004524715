import { AnyAction } from "redux";

import {
  PROFILE_PAYMENT_ALIASES_ENDPOINT,
  BOOKING_PREVIOUS_AGENTS_ENDPOINT,
  LOCATIONS_ENDPOINT,
} from "@config/endpoints";
import {
  ONCE_BOOKING_ADD_JOB_TO_CONTRACT_ENDPOINT,
  ONCE_BOOKING_AVAILABILITY_ENDPOINT,
} from "@config/soft.endpoints";

import { Contract } from "@typings/contracts";
import {
  Task,
  KEY_PICK,
  CLEANING_FREQUENCY,
  AGENTS_FILTER,
  AGENT_PREFERENCE,
  ONCE_BOOKING_TYPE,
} from "@typings/booking";
import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";
import { IStore } from "@soft/redux/reducers";
import {
  deleteLocalStorageHelper,
  setFromLocalStorageHelper,
  setLocalStorageHelper,
} from "@soft/redux/helpers";

import { LOCAL_STORAGE_KEY, BookingAddressForm } from "./booking.typings";
import {
  getAddOnceJobToContractEndpointRequest,
  getAddLocationEndpointRequest,
  getOnceAvailabilityEndpointRequest,
} from "./booking.repository";
import { getContractUuid, getServiceLocationId } from "./booking.selectors";

const action = new ReduxActionNameCreator("booking");

export const BOOKING_ACTIONS = {
  SET_LOCATION_UUID: action.make("set location uuid"),
  SET_SERVICE_LOCATION_ID: action.make("set service location id"),
  SET_TASK: action.make("set task"),
  SET_FREQUENCY: action.make("set frequency"),
  SET_KEY_PICK: action.make("set key pick"),
  SET_LOCATION_COMMENT: action.make("set location comment"),
  SET_PETS: action.make("set pets"),
  SET_SPECIAL_INSTRUCTIONS: action.make("set special instructions"),
  RESET_AGENTS_METADATA: action.make("reset agents metadata"),
  RESET_CHOSEN_AGENT_ID: action.make("reset chosen agent"),
  SET_CHOSEN_AGENT_ID: action.make("set chosen agent"),
  SET_WEEK_START_DATE: action.make("set week start date"),
  SET_EXECUTION_DATETIME: action.make("set execution datetime"),
  SET_QUERY_PARAMS: action.make("set query params"),
  FETCH_PAYMENT_ALIASES: action.make("fetch payment aliases"),
  FETCH_PREVIOUS_AGENTS: action.make("fetch previous agents"),
  ADD_LOCATION: action.make("add location"),
  SET_FORM: action.make("set form"),
  ADD_ONCE_JOB_TO_CONTRACT: action.make("add once job to contract"),
  FETCH_ONCE_BOOKING_AVAILABILITY: action.make(
    "fetch once booking availability",
  ),
  SET_AGENT_PREFERENCE: action.make("set agent preference"),
  SET_PRESELECTED_AGENT_UUID: action.make("set preselected agent uuid"),
  SET_CONTRACT_UUID: action.make("set contract uuid"),
  SET_ONCE_BOOKING_TYPE: action.make("set once booking type"),
  SET_BOOKING_STATE_FROM_CONTRACT: action.make(
    "set booking state from contract",
  ),
  SET_IS_CHOOSING_ANY_EMPLOYEE: action.make("set is choosing any employee"),

  /** LOCAL STORAGE ACTIONS */
  SET_IN_LOCAL_STORAGE: action.make("set in localstorage"),
  SET_FROM_LOCAL_STORAGE: action.make("set from localstorage"),
  DELETE_IN_LOCAL_STORAGE: action.make("delete in localstorage"),
};

export const setServiceLocationId = (payload: number): AnyAction => ({
  type: BOOKING_ACTIONS.SET_SERVICE_LOCATION_ID,
  payload,
});

export const setLocationUuid = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_LOCATION_UUID,
  payload,
});

export const setTask = (payload: Task): AnyAction => ({
  type: BOOKING_ACTIONS.SET_TASK,
  payload,
});

export const setFrequency = (frequency: CLEANING_FREQUENCY): AnyAction => ({
  type: BOOKING_ACTIONS.SET_FREQUENCY,
  payload: {
    frequency,
  },
});

export const setKeyPick = (payload: KEY_PICK): AnyAction => ({
  type: BOOKING_ACTIONS.SET_KEY_PICK,
  payload,
});

export const setLocationComment = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_LOCATION_COMMENT,
  payload,
});

export const setPets = (payload: boolean): AnyAction => ({
  type: BOOKING_ACTIONS.SET_PETS,
  payload,
});

export const setSpecialInstructions = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_SPECIAL_INSTRUCTIONS,
  payload,
});

export const fetchOnceBookingAvailability =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getOnceAvailabilityEndpointRequest(store());

    const action = await asyncActionCreator({
      url: ONCE_BOOKING_AVAILABILITY_ENDPOINT,
      action: BOOKING_ACTIONS.FETCH_ONCE_BOOKING_AVAILABILITY,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const setWeekStartDate = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_WEEK_START_DATE,
  payload,
});

export const setExecutionDateTime = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_EXECUTION_DATETIME,
  payload,
});

export const setQueryParams = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_QUERY_PARAMS,
  payload,
});

export const fetchPaymentAliases =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_PAYMENT_ALIASES_ENDPOINT,
      action: BOOKING_ACTIONS.FETCH_PAYMENT_ALIASES,
    });

    return dispatch(action);
  };

export const fetchPreviousAgents =
  (filter: AGENTS_FILTER) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: BOOKING_PREVIOUS_AGENTS_ENDPOINT.replace(
        "{filterValue}",
        filter,
      ).replace(
        "{serviceLocationIdValue}",
        String(getServiceLocationId(store())),
      ),
      action: BOOKING_ACTIONS.FETCH_PREVIOUS_AGENTS,
    });

    return dispatch(action);
  };

export const addLocation =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getAddLocationEndpointRequest(store());

    const action = await asyncActionCreator({
      url: LOCATIONS_ENDPOINT,
      action: BOOKING_ACTIONS.ADD_LOCATION,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const addOnceJobToContract =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getAddOnceJobToContractEndpointRequest(store());
    const contractUuid = getContractUuid(store());

    const action = await asyncActionCreator({
      url: ONCE_BOOKING_ADD_JOB_TO_CONTRACT_ENDPOINT.replace(
        "{contractUuid}",
        contractUuid,
      ),
      action: BOOKING_ACTIONS.ADD_ONCE_JOB_TO_CONTRACT,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const setForm = (payload: Partial<BookingAddressForm>): AnyAction => ({
  type: BOOKING_ACTIONS.SET_FORM,
  payload,
});

export const setAgentPreference = (
  agentPreference: AGENT_PREFERENCE,
): AnyAction => ({
  type: BOOKING_ACTIONS.SET_AGENT_PREFERENCE,
  payload: {
    agentPreference,
  },
});

export const setContractUuid = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_CONTRACT_UUID,
  payload,
});

export const setOnceBookingType = (payload: ONCE_BOOKING_TYPE): AnyAction => ({
  type: BOOKING_ACTIONS.SET_ONCE_BOOKING_TYPE,
  payload,
});

export const setPreselectedAgentUuid = (
  preselectedAgentUuid: string,
): AnyAction => ({
  type: BOOKING_ACTIONS.SET_PRESELECTED_AGENT_UUID,
  payload: {
    preselectedAgentUuid,
  },
});

export const setBookingStateFromContract = (payload: Contract): AnyAction => ({
  type: BOOKING_ACTIONS.SET_BOOKING_STATE_FROM_CONTRACT,
  payload,
});

export const setIsChoosingAnyEmployee = (payload: boolean): AnyAction => ({
  type: BOOKING_ACTIONS.SET_IS_CHOOSING_ANY_EMPLOYEE,
  payload,
});

export const setLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch, store: () => IStore): void => {
    setLocalStorageHelper(
      dispatch,
      BOOKING_ACTIONS.SET_IN_LOCAL_STORAGE,
      store().booking,
      LOCAL_STORAGE_KEY,
    );
  };

export const setFromLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    setFromLocalStorageHelper(
      dispatch,
      BOOKING_ACTIONS.SET_FROM_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };

export const deleteLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    deleteLocalStorageHelper(
      dispatch,
      BOOKING_ACTIONS.DELETE_IN_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };
