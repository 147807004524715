import { AnyAction } from "redux";

import { SHOW_LOADING, HIDE_LOADING } from "./loading.actions";
import { LoadingReducer } from "./loading.typings";

const initialState: LoadingReducer = false;

const loadingReducer = (
  store: LoadingReducer = initialState,
  action: AnyAction,
) => {
  const { type } = action;

  switch (type) {
    case SHOW_LOADING:
      return true;

    case HIDE_LOADING:
      return false;

    default:
      return store;
  }
};

export { loadingReducer };
