import { AnyAction } from "redux";

import { HIDE, SHOW } from "./navigation.actions";

const navigationReducer = (store = false, action: AnyAction) => {
  const { type } = action;

  switch (type) {
    case SHOW: {
      return true;
    }

    case HIDE: {
      return false;
    }

    default: {
      return store;
    }
  }
};

export { navigationReducer };
