import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

const action = new ReduxActionNameCreator("navigation");

// Actions
export const SHOW = action.make("show menu");
export const HIDE = action.make("hide menu");

// Action creators
export const showMenu = () => ({ type: SHOW });
export const hideMenu = () => ({ type: HIDE });
