import { Record as ImmutableRecord } from "immutable";

export type IUserNotificationParam = Record<USER_NOTIFICATIONS, boolean>;

export enum USER_NOTIFICATIONS {
  PENDING_PAYMENTS_ALERT = "pendingPaymentsAlert",
  REMIND_MISSING_TRUST_MANDATE = "remindMissingTrustMandate",
  SHOW_NEW_BOOKING_REMINDER = "showNewBookingReminder",
  HAS_BOOKINGS_TO_VALIDATE = "hasBookingsToValidate",
  SHOW_HOLIDAY_AUTOMATION_ALERT = "showHolidayAutomationAlert",
  SHOW_MISSING_TRUST_MANDATE_2020 = "showMissingTrustMandate2020",
  TRUST_MANDATE_IS_APPROVED = "trustMandateIsApproved",
  SHOW_END_OF_TENANCY_RESERVATION_WITHOUT_JOBS = "showEndOfTenancyReservationWithoutJobs",
}

export interface IUserNotifications {
  [USER_NOTIFICATIONS.PENDING_PAYMENTS_ALERT]: boolean;
  [USER_NOTIFICATIONS.REMIND_MISSING_TRUST_MANDATE]: boolean;
  [USER_NOTIFICATIONS.SHOW_NEW_BOOKING_REMINDER]: boolean;
  [USER_NOTIFICATIONS.HAS_BOOKINGS_TO_VALIDATE]: boolean;
  [USER_NOTIFICATIONS.SHOW_HOLIDAY_AUTOMATION_ALERT]: boolean;
  [USER_NOTIFICATIONS.SHOW_MISSING_TRUST_MANDATE_2020]: boolean;
  [USER_NOTIFICATIONS.TRUST_MANDATE_IS_APPROVED]: boolean;
  [USER_NOTIFICATIONS.SHOW_END_OF_TENANCY_RESERVATION_WITHOUT_JOBS]: boolean;
}

export type UserNotificationsReducer = ImmutableRecord<IUserNotifications>;
