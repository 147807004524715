/**
 * @author tomekbuszewski
 * @since 2019-3-1
 */

import React, { ReactElement } from "react";
import styled, { css } from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";
import { Spinner } from "@ui/Assets/Symbolicons/Spinner";

import rem from "../../helpers/rem";
import transition from "../../helpers/transition";
import { arrowAnimation } from "../../animations";

export type ButtonType = "button" | "submit" | "reset";
export type IconAnimation = "arrowAnimation";
export type Size =
  | "base"
  | "base_x2"
  | "large"
  | "table"
  | "pill"
  | "icon"
  | "fill"
  | "medium"
  | "small"
  | "fit";
export type Variant =
  | "blue"
  | "green"
  | "grey"
  | "red"
  | "white"
  | "borderBlack"
  | "borderGrey"
  | "borderBlue"
  | "borderGreen"
  | "borderWhite"
  | "borderRed"
  | "textBlue"
  | "textGrey";
export type AlignVariant = "flex";

interface Props {
  title?: string;
  theme?: ITheme;
  children?: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  isLoading?: boolean;
  size?: Size;
  variant?: Variant;
  alignVariant?: AlignVariant; // @TODO: button should be centered by default
  /**
   * Should it match contents instead of
   * having default minimum width
   */
  auto?: boolean;
  href?: string;
  htmlFor?: string;
  target?: string;
  type?: ButtonType;
  style?: { [k: string]: string };
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  id?: string;
  icon?: React.ReactElement;
  iconAnimation?: IconAnimation;
  form?: string;
  className?: string;
}

const getColor = (
  color: string,
  theme: ITheme,
  border?: string,
  textColor?: string,
) => `
  background: ${
    color === "transparent" ? "transparent" : theme.colors[`${color}500`]
  };
  border: ${
    border &&
    `${rem(1)} solid ${theme.colors[`${border}500`] || theme.colors[border]}`
  };
  color: ${textColor && theme.colors[`${textColor}500`]};
  
  ${theme.breakpoints.desktop} {
    &:hover {
      background: ${
        border ? theme.colors[`${border}50`] : theme.colors[`${color}700`]
      };
      color: ${
        textColor ? theme.colors[`${textColor}500`] : theme.colors.white
      };
      border: ${border && `${rem(1)} solid ${theme.colors[`${border}500`]}`};
  
      ${
        color === "transparent" &&
        `
        color: ${theme.colors[`${textColor}700`]};
      `
      }
    }
  }
  
  &:active,
  &:focus {
    border: ${border && `${rem(1)} solid ${theme.colors[`${border}500`]}`};
  }
  
  &:disabled {
    background: ${theme.colors.greyLight};
    color: ${theme.colors.greyDisabledText};
    border: ${border ? `${rem(1)} solid ${theme.colors[`${border}500`]}` : 0};
  }
`;

const getSize = (size: string, theme: ITheme) => `
  width: ${theme.buttons.sizes[size]};
`;

const Button = (props: Props): ReactElement => (
  <ButtonStyled {...props}>
    {props.children}
    {props.icon && !props.isLoading && (
      <ButtonIconWrapper>
        <Icon>{props.icon}</Icon>
      </ButtonIconWrapper>
    )}
    {props.isLoading && (
      <ButtonIconWrapper isLoader>
        <Icon>
          <Spinner size={32} />
        </Icon>
      </ButtonIconWrapper>
    )}
  </ButtonStyled>
);

const ButtonIconWrapper = styled.div<{ isLoader?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  right: ${props =>
    props.isLoader ? props.theme.margins.base : props.theme.margins.base_x2};
  transform: translateY(-50%);
  overflow: hidden;
`;

const Icon = styled.div`
  display: flex;
`;

const ButtonStyled = styled.button<Props>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};

  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  text-align: center;
  line-height: normal;

  border-radius: ${({ theme }) => theme.buttons.borderRadius};
  border: 0;
  min-width: ${props =>
    props.auto || props.size === "icon"
      ? props.theme.buttons.height
      : rem(124)};
  max-width: 100%;
  min-height: ${props =>
    props.theme.buttons[props.size === "table" ? "heightSmall" : "height"]};
  max-height: ${props => props.auto && props.theme.buttons.height};
  padding: ${props => props.theme.buttons.paddings.vertical}
    ${props =>
      props.icon
        ? `calc(${props.theme.buttons.paddings.horizontal} + ${props.theme.margins.base_x4})`
        : props.theme.buttons.paddings.horizontal};

  ${props =>
    props.size === "icon" &&
    css`
      padding: 0;
      width: ${rem(40)};
      min-width: ${rem(40)};
      line-height: ${rem(40)};
      height: ${rem(40)};
      min-height: ${rem(40)};
      position: relative;

      & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `};

  cursor: pointer;
  transition: ${transition(["background", "border", "color"])};

  ${props => props.variant === "blue" && getColor("blue", props.theme)}
  ${props => props.variant === "green" && getColor("green", props.theme)}
  ${props => props.variant === "grey" && getColor("grey", props.theme)}
  ${props => props.variant === "red" && getColor("red", props.theme)}
  ${props =>
    props.variant === "borderGrey" &&
    getColor("white", props.theme, "grey", "grey")}
  ${props =>
    props.variant === "borderBlack" &&
    getColor("white", props.theme, "black", "black")}
  ${props =>
    props.variant === "borderBlue" &&
    getColor("transparent", props.theme, "blue", "blue")}
  ${props =>
    props.variant === "borderGreen" &&
    getColor("white", props.theme, "green", "green")}
  ${props =>
    props.variant === "borderWhite" &&
    getColor("transparent", props.theme, "white", "white")}
  ${props =>
    props.variant === "borderRed" &&
    getColor("transparent", props.theme, "red", "red")}
  ${props =>
    props.variant === "textBlue" &&
    getColor("transparent", props.theme, "none", "blue")}
  ${props =>
    props.variant === "textGrey" &&
    getColor("transparent", props.theme, "none", "grey")}
    ${props =>
    props.variant === "white" &&
    getColor("white", props.theme, "none", "black")}
  
    ${props => props.size === "base_x2" && getSize("base_x2", props.theme)}
    ${props => props.size === "medium" && getSize("medium", props.theme)}
    ${props => props.size === "large" && getSize("large", props.theme)}
    ${props => props.size === "table" && getSize("table", props.theme)}
    ${props => props.size === "pill" && getSize("pill", props.theme)}
    ${props => props.size === "fill" && getSize("fill", props.theme)}
    ${props =>
    props.size === "fit" &&
    css`
      width: auto;
      height: auto;
      padding: 0;
      min-width: 0;
      min-height: 0;
    `}


  &:active,
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}

  ${props =>
    props.iconAnimation === "arrowAnimation" &&
    css`
      &:hover ${Icon} {
        animation-name: ${arrowAnimation};
        animation-duration: 0.4s;
      }
    `}

  ${props =>
    props.alignVariant === "flex" &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  
  svg {
    font-size: ${rem(20)};
  }

  & + button {
    ${props => props.auto && `margin-left: ${props.theme.margins.base}`};
  }

  ${props => props.theme.breakpoints.desktop} {
    padding: ${props =>
        props.size !== "icon" && props.theme.buttons.paddings.vertical}
      ${props =>
        props.size !== "icon" &&
        (props.icon
          ? `calc(${props.theme.buttons.paddings.horizontal} + ${props.theme.margins.base_x4})`
          : props.theme.buttons.paddings.xhorizontal)};
    ${props =>
      props.size === "fit" &&
      css`
        padding: 0;
      `}

    ${props =>
      typeof props.isLoading !== "undefined" &&
      css`
        padding: ${props =>
          `${props.theme.buttons.paddings.vertical} ${props.theme.margins.base_x5}`};
      `}
  }
`;

Button.displayName = "Button";
Button.defaultProps = {
  onClick: () => null,
  size: "base",
  variant: "blue",
};

const IconArea = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.margins.base};
`;

Button.IconArea = IconArea;

export { Props as ButtonProps, Button };
