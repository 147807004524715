import { Record, Map } from "immutable";
import { AnyAction } from "redux";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import {
  UPDATE_PETS,
  FETCH_LOCATIONS,
  SET_FROM_LOCAL_STORAGE,
  FETCH_LOCATION_BY_ID,
} from "./locations.actions";
import { LocationsReducer } from "./locations.typings";
import { locationsFactory } from "./locations.factories";

const initialState = Record<LocationsReducer>({
  defaultLocationId: "",
  invoiceLocationId: "",
  legalLocationId: "",
  locations: Map(),
});

const locationsReducer = (
  store: Record<LocationsReducer> = initialState(),
  action: AnyAction,
): Record<LocationsReducer> => {
  const { payload, type, params } = action;

  switch (type) {
    case SET_FROM_LOCAL_STORAGE: {
      return action.payload || store;
    }
    case `${FETCH_LOCATION_BY_ID}${ACTION_SUCCESS}`: {
      const { data } = payload;
      return store.setIn(["locations", data.uuid], Record(data)());
    }
    case `${FETCH_LOCATIONS}${ACTION_SUCCESS}`: {
      const { data } = payload;
      return store.merge(locationsFactory(data));
    }

    case `${UPDATE_PETS}${ACTION_SUCCESS}`: {
      const { id, hasPets } = params;
      return store.setIn(["locations", id, "hasPets"], hasPets);
    }

    default: {
      return store;
    }
  }
};

export { locationsReducer };
