import { Record, Map } from "immutable";

import { defaultValues } from "@config/soft.defaultValues";

import { CLEANING_FREQUENCY } from "@typings/booking";
import { AVAILABLE_CURRENCIES } from "@typings/globals";
import { BOOKINGS_TO_VALIDATE_JOB_TYPE } from "@typings/jobs";

import { JobsToValidateMap, JobToValidate } from "./bookingsToValidate.typings";

export const legalLocationInitialState = {
  address: "",
  floorAndDoor: "",
  serviceLocationId: defaultValues.bookingsToValidate.serviceLocationId,
  city: "",
  zip: "",
};

export const SingleJobFactory = (input: JobToValidate): Record<JobToValidate> =>
  Record<JobToValidate>({
    firstExecutionDate: "",
    frequency: CLEANING_FREQUENCY.ONCE,
    price: 0,
    currency: AVAILABLE_CURRENCIES.CHF,
    uuid: "",
    jobUuid: "",
    contractUrl: "",
    bookingServiceType: "" as BOOKINGS_TO_VALIDATE_JOB_TYPE,
  })({
    ...input,
  });

export const JobsToValidateFactory = (
  input: JobToValidate[],
): JobsToValidateMap =>
  input.reduce(
    (acc: JobsToValidateMap, item: JobToValidate) =>
      acc.set(item.uuid, SingleJobFactory(item)),
    Map(),
  );
