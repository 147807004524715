import {
  CONTRACT_BY_UUID_ENDPOINT,
  CONTRACTS_ENDPOINT,
  CONTRACT_DOWNLOAD_ENDPOINT,
  AGENT_BY_UUID_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("contracts");

export const FETCH_CONTRACT = action.make("fetch contract");
export const FETCH_CONTRACTS = action.make("fetch contracts");
export const DOWNLOAD_CONTRACT = action.make("download contract");
export const FETCH_EMPLOYEE = action.make("fetch employee");

export const fetchContracts = () => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: CONTRACTS_ENDPOINT,
    action: FETCH_CONTRACTS,
  });

  return dispatch(action);
};

export const fetchContract = (uuid: string) => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: CONTRACT_BY_UUID_ENDPOINT.replace("{uuid}", uuid),
    action: FETCH_CONTRACT,
  });

  return dispatch(action);
};

export const fetchEmployee = (uuid: string) => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: AGENT_BY_UUID_ENDPOINT.replace("{uuid}", uuid),
    action: FETCH_EMPLOYEE,
    params: {
      uuid,
    },
  });

  return dispatch(action);
};

export const downloadContract = (uuid: string) => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: CONTRACT_DOWNLOAD_ENDPOINT.replace("{uuid}", uuid),
    action: DOWNLOAD_CONTRACT,
  });

  return dispatch(action);
};
