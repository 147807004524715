import { API_ENDPOINT, IS_BROWSER, IS_PROD } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

const getApiUrl = (
  langOrScope = "en",
  version = "v1",
  fallbackUrl = API_ENDPOINT,
): string => {
  if (!IS_BROWSER) {
    return fallbackUrl;
  }

  const host = location.host;
  const isLocalhost = location.host.startsWith("localhost");
  const isLocalIp =
    location.host.startsWith("192") || location.host.startsWith("10");

  if (IS_PROD || isLocalhost || isLocalIp) {
    return fallbackUrl;
  }

  const apiEnv = process.env.API_ENV;
  const app = process.env.APP as ORIGIN;
  const domain = getStagingDomainByApp(app);
  const tld = getStagingTldByHost(host);

  return `https://${apiEnv}.${domain}.${tld}/${langOrScope}/api/${version}`;
};

const getBatmaidProdDomainByCountry = (
  country: AVAILABLE_COUNTRIES,
): string | null => {
  switch (country) {
    case AVAILABLE_COUNTRIES.LU:
      return "batmaid.lu";
    case AVAILABLE_COUNTRIES.CH:
      return "batmaid.ch";
    case AVAILABLE_COUNTRIES.FR:
      return "batmaid.fr";
    case AVAILABLE_COUNTRIES.PL:
      return "batmaid.pl";
    case AVAILABLE_COUNTRIES.IT:
      return "batmaid.it";
    case AVAILABLE_COUNTRIES.DE:
      return "batmaid.de";
    case AVAILABLE_COUNTRIES.AT:
      return "batmaid.at";
    case AVAILABLE_COUNTRIES.BE:
      return "batmaid.be";
    case AVAILABLE_COUNTRIES.NL:
      return "batmaid.nl";
    default:
      return null;
  }
};

const getBatmaidStagingDomainByCountry = (
  country: AVAILABLE_COUNTRIES,
): string | null => {
  switch (country) {
    case AVAILABLE_COUNTRIES.LU:
      return "elpster.xyz";
    case AVAILABLE_COUNTRIES.CH:
      return "elpster.club";
    case AVAILABLE_COUNTRIES.FR:
      return "elpster.pro";
    case AVAILABLE_COUNTRIES.PL:
      return "elpster.world";
    case AVAILABLE_COUNTRIES.IT:
      return "elpster.life";
    case AVAILABLE_COUNTRIES.DE:
      return "elpster.space";
    case AVAILABLE_COUNTRIES.AT:
      return "elpster.art";
    case AVAILABLE_COUNTRIES.BE:
      return "elpster.casa";
    case AVAILABLE_COUNTRIES.NL:
      return "elpster.work";
    default:
      return null;
  }
};

function getStagingDomainByApp(app?: ORIGIN) {
  switch (app) {
    case "batmaid":
      return "elpster";
    case "batsoft":
      return "elpsoft";
    case "batwork":
      return "elpwork";
    default:
      console.error("Unknown app; Add APP variable to .env");
      return "elpster";
  }
}

function getStagingTldByHost(host: string) {
  // TODO: extract mapping to const and reuse in `ResolveCountryFromUrl`
  const map = {
    "local.batmaid.ch": "club",
    "local.batmaid.pl": "world",
    "local.batmaid.fr": "pro",
    "local.batmaid.lu": "xyz",
    "local.batmaid.de": "space",
    "local.batmaid.it": "life",
    "local.batmaid.at": "art",
    "local.batmaid.be": "casa",
    "local.batmaid.nl": "work",
    "local.batmaid.uk": "in",
  };

  let tld;

  Object.entries(map).forEach(([originPrefix, target]) => {
    if (host.startsWith(originPrefix)) {
      tld = target;
    }
  });

  return tld || "club";
}

export {
  getApiUrl,
  getBatmaidStagingDomainByCountry,
  getBatmaidProdDomainByCountry,
};
