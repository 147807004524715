import { IS_BROWSER } from "@config/consts";

import { sessionStorageFacade } from "@services/SessionStorageFacade";

const checkAndInitHeadlessMode = () => {
  const isHeadlessSession = isHeadlessMode();

  if (isHeadlessSession) {
    sessionStorage.setItem("headless", "true");
    document.body.classList.add("headless");
  }
};

const isHeadlessMode = () => {
  if (!IS_BROWSER) return false;

  const query = new URLSearchParams(location.search);
  const sessionStorage = sessionStorageFacade();
  const isHeadlessSession =
    query.has("headless") || sessionStorage.getItem("headless") === "true";

  return isHeadlessSession;
};

export { checkAndInitHeadlessMode, isHeadlessMode };
