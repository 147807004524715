import { AnyAction } from "redux";

import {
  CLIENT_REGISTRATION_ENDPOINT,
  LOCATIONS_ENDPOINT,
  PROFILE_EMPLOYER_INFO_ENDPOINT,
} from "@config/endpoints";
import { LEGAL_LOCATION_ENDPOINT } from "@config/soft.endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";

import { IStore } from "@soft/redux/reducers";

import { EmployerAccount, PersonalDetails } from "./accountCreation.typings";
import {
  getClientRegistrationEndpointRequest,
  getAddLegalLocationEndpointRequest,
  getPutEmployerInfoEndpointRequest,
} from "./accountCreation.repository";

const action = new ReduxActionNameCreator("account creation");

export const SET_PERSONAL_DETAILS = action.make("set personal details");

export const SET_MAIN_LOCATION_ADDRESS = action.make(
  "set main location address",
);
export const SET_MAIN_LOCATION_CITY = action.make("set main location city");
export const SET_MAIN_LOCATION_ZIP = action.make("set main location zip");
export const SET_MAIN_LOCATION_SERVICE_LOCATION_ID = action.make(
  "set main location service location id",
);

export const SET_LEGAL_ADDRESS = action.make("set legal address");
export const SET_LEGAL_CITY = action.make("set legal city");
export const SET_LEGAL_ZIP = action.make("set zip");
export const SET_LEGAL_SERVICE_LOCATION_ID = action.make(
  "set legal service location id",
);
export const SET_IS_LEGAL_LOCATION_THE_SAME = action.make(
  "set is legal location the same",
);
export const SET_EMPLOYER_ACCOUNT = action.make("set employer account");
export const SET_HAS_EMPLOYER_ACCOUNT = action.make("set has employer account");
export const REGISTER_CLIENT = action.make("register client");
export const ADD_LOCATION = action.make("add location");
export const PUT_LEGAL_LOCATION = action.make("put legal location");
export const PUT_EMPLOYER_INFO = action.make("put employer info");

export const setPersonalDetails = (payload: PersonalDetails): AnyAction => ({
  type: SET_PERSONAL_DETAILS,
  payload,
});

/**
 *
 * main location actions
 */
export const setMainLocationAddress = (payload: string): AnyAction => ({
  type: SET_MAIN_LOCATION_ADDRESS,
  payload,
});

export const setMainLocationCity = (payload: string): AnyAction => ({
  type: SET_MAIN_LOCATION_CITY,
  payload,
});

export const setMainLocationZip = (payload: string): AnyAction => ({
  type: SET_MAIN_LOCATION_ZIP,
  payload,
});

export const setMainLocationServiceLocationId = (
  payload: number,
): AnyAction => ({
  type: SET_MAIN_LOCATION_SERVICE_LOCATION_ID,
  payload,
});

/**
 * legal address actions
 */
export const setLegalAddress = (payload: string): AnyAction => ({
  type: SET_LEGAL_ADDRESS,
  payload,
});

export const setLegalCity = (payload: string): AnyAction => ({
  type: SET_LEGAL_CITY,
  payload,
});

export const setLegalZip = (payload: string): AnyAction => ({
  type: SET_LEGAL_ZIP,
  payload,
});

export const setLegalServiceLocationId = (payload: number): AnyAction => ({
  type: SET_LEGAL_SERVICE_LOCATION_ID,
  payload,
});

export const setIsLegalLocationTheSame = (payload: boolean): AnyAction => ({
  type: SET_IS_LEGAL_LOCATION_THE_SAME,
  payload,
});

/**
 * employer account section
 */

export const setEmployerAccount = (payload: EmployerAccount): AnyAction => ({
  type: SET_EMPLOYER_ACCOUNT,
  payload,
});

export const setHasEmployerAccount = (payload: boolean): AnyAction => ({
  type: SET_HAS_EMPLOYER_ACCOUNT,
  payload,
});

export const registerClient = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getClientRegistrationEndpointRequest(store());

  const action = await asyncActionCreator({
    url: CLIENT_REGISTRATION_ENDPOINT,
    action: REGISTER_CLIENT,
    method: ASYNC_ACTION_TYPES.POST,
    body,
  });

  return dispatch(action);
};

export const addLegalLocation = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getAddLegalLocationEndpointRequest(store());

  const action = await asyncActionCreator({
    url: LOCATIONS_ENDPOINT,
    action: ADD_LOCATION,
    method: ASYNC_ACTION_TYPES.POST,
    body,
  });

  return dispatch(action);
};

export const putLegalLocation = (locationId: string) => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: LEGAL_LOCATION_ENDPOINT,
    action: PUT_LEGAL_LOCATION,
    method: ASYNC_ACTION_TYPES.PUT,
    body: { locationId },
  });

  return dispatch(action);
};

export const putEmployerInfo = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getPutEmployerInfoEndpointRequest(store());

  const action = await asyncActionCreator({
    url: PROFILE_EMPLOYER_INFO_ENDPOINT,
    action: PUT_EMPLOYER_INFO,
    method: ASYNC_ACTION_TYPES.PUT,
    body,
  });

  return dispatch(action);
};
