const palette = {
  black: "#000000",
  white: "#FFFFFF",

  // blue
  blue20: "#F0F8FA",
  blue50: "#E4F4F9",
  blue100: "#BCE4F0",
  blue200: "#7ACAE2",
  blue300: "#58BBDA",
  blue400: "#20B1DF",
  blue500: "#1EA6D1",
  blue600: "#008BBE",
  blue700: "#0079AC",
  blue800: "#006899",
  blue900: "#004A7A",

  // green
  green50: "#E2F5D6",
  green100: "#D4F0C2",
  green300: "#97DC6E",
  green500: "#62CC1D",
  green700: "#38A703",
  green900: "#007100",

  // grey
  grey20: "#F7F8F8",
  grey50: "#F2F2F2",
  grey100: "#D9D9D9",
  grey200: "#CCCCCC",
  grey300: "#ADADAD",
  grey400: "#999999",
  grey500: "#888888",
  grey600: "#606060",
  grey700: "#444444",
  grey800: "#1F1F1F",
  grey900: "#050505",

  // orange
  orange50: "#FFE5CC",
  orange300: "#FFB366",
  orange500: "#FF9933",
  orange700: "#FF8000",
  orange900: "#A35200",

  // red
  red50: "#F7D4D7",
  red300: "#D62848",
  red500: "#B00020",
  red700: "#990000",
  red900: "#700000",
};

type DesignSystemPalette = typeof palette;

export { palette, DesignSystemPalette };
