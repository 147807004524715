import { RepositoryConfig } from "gatsby-plugin-prismic-previews/src/types";

import { linkResolver } from "./linkResolver";

export const repositoryConfigs: RepositoryConfig[] = [
  {
    repositoryName: process.env.PRISMIC_REPO_NAME || "",
    linkResolver,
    // componentResolver: {
    //   page: React.lazy(() => import("../templates/page.js")),
    // },
  },
];
