/**
 * @author tomekbuszewski
 * @since 2019-6-25
 */

import * as React from "react";
import styled from "styled-components";

import { fadeIn, fadeOut } from "@ui/animations";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { INPUT_ERROR_CLASS } from "../Form.types";

interface Props {
  children?: React.ReactNode;
  isCentered?: boolean;
  testId?: string;
  className?: string;
}

const FormError = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <FormErrorWrapper
      className={[props.className, INPUT_ERROR_CLASS].join(" ")}
      isCentered={props.isCentered}
    >
      {props.children}
    </FormErrorWrapper>
  </TestWrapper>
);

const FormErrorWrapper = styled.div<Props>`
  animation: ${props => (props.hidden ? fadeOut : fadeIn)}
    ${props => props.theme.animations.duration}
    ${props => props.theme.animations.easing} forwards;
  width: 100%;
  position: relative;
  color: ${props => props.theme.colors.red700};
  font-size: ${props => props.theme.fonts.sizes.caption};
  margin-top: ${props => props.theme.margins.base};
  text-align: ${props => (props.isCentered ? "center" : "left")};
`;

export { FormError };

FormError.displayName = "FormError";
