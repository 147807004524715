import { SyntheticEvent } from "react";
import * as React from "react";
import ReactTooltip from "react-tooltip";

import { ModalView, Padding, Size } from "@ui/Molecules/Modal";

interface Props {
  className?: string;
  onRequestClose?: (
    reason: ModalCloseReason,
    event: SyntheticEvent | KeyboardEvent,
  ) => void;
  children?: React.ReactNode | null;

  cancel?: (e?: any) => void;
  cancelText: string;
  isCancelBtnLoading?: boolean;
  centeredContent?: boolean;
  isSubmitBtnDisabled?: boolean;
  isSubmitBtnLoading?: boolean;
  padding?: Padding;
  size?: Size;
  onSubmit?: (event: SyntheticEvent) => void;
  submitText: string;
  testId?: string;
  title?: string | React.ReactElement;
  withContainedHeight?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
  withCancelBtn?: boolean;
  withCloseBtn?: boolean;
  withMobileHeaderGutter?: boolean;
  submitBtnColor?: "green" | "blue" | "red";
  isBottomVariant?: boolean;
  customHeader?: React.ReactNode;
  isMobileFullScreen?: boolean;
  hideWithCss?: boolean; // Use only for SEO
  preventOverflow?: boolean;
  closeButtonId?: string;
  withOldCloseIcon?: boolean;
  withBorder?: boolean;
}

export enum ModalCloseReason {
  CLOSE_BUTTON,
  ESCAPE_PRESS,
}

const Modal = ({
  onRequestClose,
  onSubmit,
  children,
  ...props
}: Props): React.ReactElement => {
  const onKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onRequestClose?.(ModalCloseReason.ESCAPE_PRESS, e);
      }
    },
    [onRequestClose],
  );

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyPress, false);

    return () => {
      document.removeEventListener("keydown", onKeyPress, false);
    };
  }, [onKeyPress]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <ModalView
      {...props}
      padding={props.padding || ("normal" as Padding)}
      close={e => onRequestClose?.(ModalCloseReason.CLOSE_BUTTON, e)}
      visible
      submit={onSubmit}
    >
      {children}
    </ModalView>
  );
};

export { Modal };
