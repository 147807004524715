import { ORIGIN } from "@typings/globals";

export const getGtmId = (appName: ORIGIN): string | undefined => {
  switch (appName) {
    case ORIGIN.BATMAID:
      return process.env.GTM_ID_BATMAID;
    case ORIGIN.BATWORK:
      return process.env.GTM_ID_BATWORK;
    case ORIGIN.BATSOFT:
      return process.env.GTM_ID_BATSOFT;
    default:
      return process.env.GTM_ID_BATMAID;
  }
};

export const initGTM = (origin: ORIGIN) => {
  if (typeof window === "undefined") return;

  (function (w: any, d: any, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.defer = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", getGtmId(origin));
};

export const initGTMAsString = (origin: ORIGIN) =>
  initGTM.toString().replace("getGtmId(origin)", `"${getGtmId(origin)}"` || "");
