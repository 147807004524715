import { AnyAction } from "redux";
import { Record } from "immutable";

import { defaultValues } from "@config/soft.defaultValues";

import { SalaryDefaults, BookingSalary } from "@typings/salary";

import { getQueryValue } from "@services/QueryParamsParser";
import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";
import { stringifyFalsies } from "@services/StringifyFalsies";

import { SalaryReducer, SalaryReducerState } from "./salary.typings";
import { SALARY_ACTIONS } from "./salary.actions";
import {
  sicknessInsuranceCostsFactory,
  secondPillarRetirementCostsFactory,
  salaryDefaultsFactory,
  salaryFactory,
} from "./salary.factories";

export const getServiceLocationIdFromUrl = (url: string): number =>
  !isNaN(Number(getQueryValue(url, "serviceLocationId")))
    ? Number(getQueryValue(url, "serviceLocationId"))
    : defaultValues.salary.serviceLocationId;

const initialState = Record<SalaryReducerState>({
  serviceLocationId: defaultValues.salary.serviceLocationId,
  chosenAgentId: defaultValues.salary.chosenAgentId,
  prevSicknessInsurance: defaultValues.salary.sicknessInsurance,
  sicknessInsurance: defaultValues.salary.sicknessInsurance,
  sicknessInsuranceCosts: Record(defaultValues.salary.sicknessInsuranceCosts)(),
  professionalAccidentInsurance:
    defaultValues.salary.professionalAccidentInsurance,
  agreeToProfessionalAccidentInsurance:
    defaultValues.salary.agreeToProfessionalAccidentInsurance,
  insurancePolicyNumber: defaultValues.salary.insurancePolicyNumber,
  insurancePolicyWillBeProvidedLater:
    defaultValues.salary.insurancePolicyWillBeProvidedLater,
  prevSecondPillarRetirement: defaultValues.salary.secondPillarRetirement,
  secondPillarRetirement: defaultValues.salary.secondPillarRetirement,
  secondPillarRetirementCosts: Record(
    defaultValues.salary.secondPillarRetirementCosts,
  )(),
  salaryDefaults: Record<SalaryDefaults>(defaultValues.salary.salaryDefaults)(),
  salaryDefaultsFetched: defaultValues.salary.salaryDefaultsFetched,
  salary: Record<BookingSalary>(defaultValues.salary.salary)(),
  sliderSuperGrossSalary: defaultValues.salary.salarySlider.superGrossSalary,
  sliderChangeCount: defaultValues.salary.sliderChangeCount,
  cachePreviousData: defaultValues.salary.cachePreviousData,
  cachedData: defaultValues.salary.cachedData,
});

const salaryReducer = (
  store: SalaryReducer = initialState(),
  action: AnyAction,
): SalaryReducer => {
  const { payload, type } = action;

  switch (type) {
    case SALARY_ACTIONS.SET_PROFESSIONAL_ACCIDENT_INSURANCE: {
      return store.set("professionalAccidentInsurance", payload);
    }
    case SALARY_ACTIONS.SET_AGREE_TO_PROFESSIONAL_ACCIDENT_INSURANCE: {
      return store.set("agreeToProfessionalAccidentInsurance", payload);
    }
    case SALARY_ACTIONS.SET_INSURANCE_POLICY_NUMBER: {
      return store.set("insurancePolicyNumber", payload);
    }
    case SALARY_ACTIONS.SET_INSURANCE_POLICY_WILL_BE_PROVIDED_LATER: {
      return store.set("insurancePolicyWillBeProvidedLater", payload);
    }
    case SALARY_ACTIONS.SET_SICKNESS_INSURANCE: {
      return store
        .set("prevSicknessInsurance", payload.prevValue)
        .set("sicknessInsurance", payload.newValue);
    }
    case SALARY_ACTIONS.SET_SECOND_PILLAR_RETIREMENT: {
      return store
        .set("prevSecondPillarRetirement", payload.prevValue)
        .set("secondPillarRetirement", payload.newValue);
    }
    case `${SALARY_ACTIONS.FETCH_SICKNESS_INSURANCE_COSTS}${ACTION_SUCCESS}`: {
      return store.set(
        "sicknessInsuranceCosts",
        sicknessInsuranceCostsFactory(payload.data),
      );
    }
    case `${SALARY_ACTIONS.FETCH_SECOND_PILLAR_RETIREMENT_COSTS}${ACTION_SUCCESS}`: {
      return store.set(
        "secondPillarRetirementCosts",
        secondPillarRetirementCostsFactory(payload.data),
      );
    }
    case `${SALARY_ACTIONS.FETCH_SALARY_DEFAULTS}${ACTION_SUCCESS}`: {
      return store
        .set("salaryDefaults", salaryDefaultsFactory(payload.data))
        .set("salaryDefaultsFetched", true)
        .set("sliderSuperGrossSalary", payload.data.defaultSuperGrossSalary)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1);
    }
    case `${SALARY_ACTIONS.FETCH_SALARY}${ACTION_SUCCESS}`: {
      return store
        .set("salary", salaryFactory(payload.data))
        .set("sliderSuperGrossSalary", payload.data.superGrossSalary);
    }
    case SALARY_ACTIONS.SET_SLIDER_SUPERGROSS_SALARY: {
      return store
        .set("sliderSuperGrossSalary", payload)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1);
    }
    case `${SALARY_ACTIONS.FETCH_SUPERGROSS_SALARY}${ACTION_SUCCESS}`: {
      return store
        .set("sliderSuperGrossSalary", payload.data.amount)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1);
    }
    case `${SALARY_ACTIONS.SET_CHOSEN_AGENT_ID}`: {
      return store.set("chosenAgentId", payload);
    }
    case `${SALARY_ACTIONS.SET_SERVICE_LOCATION_ID}`: {
      return store.set("serviceLocationId", payload);
    }
    case SALARY_ACTIONS.SET_SALARY_PROPOSAL_PARAMS: {
      return store
        .set("sliderSuperGrossSalary", Number(payload.grossSalary))
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1)
        .set("secondPillarRetirement", payload.secondPillarRetirement)
        .set("sicknessInsurance", payload.sicknessInsurance)
        .set("professionalAccidentInsurance", !!payload.insurancePolicy)
        .set(
          "insurancePolicyNumber",
          stringifyFalsies(payload.insurancePolicyNumber),
        )
        .set(
          "insurancePolicyWillBeProvidedLater",
          !!payload.insurancePolicyWillBeProvidedLater,
        )
        .set("cachePreviousData", true);
    }
    case SALARY_ACTIONS.CACHE_SALARY_DATA: {
      return store.set("cachedData", payload).set("cachePreviousData", false);
    }
    case SALARY_ACTIONS.RESET_STATE: {
      return initialState();
    }
    case SALARY_ACTIONS.SET_FROM_LOCAL_STORAGE: {
      return action.payload || store;
    }
    default:
      break;
  }

  return store;
};

export { salaryReducer };
