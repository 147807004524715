import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AppConfig } from "@typings/appConfig";
import { FREQUENCY } from "@typings/b2b";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.FR],
  booking: {
    defaultDuration: 2,
    commitments: {
      enabled: false,
    },
    extraBookingSummary: {
      caption: "batmaid_pwa_booking.booking_sidebar_disclaimer_md",
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
      ],
      rows: [
        [false, true, true],
        [true, true, true],
        [true, true, true],
        [false, true, true],
        [true, true, true],
      ],
    },
    hasUrssaf: true,
  },
  b2b: {
    contactDetails: {
      phone: "(+33) 1 81 72 25 51",
      email: "client@batmaid.fr",
    },
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLSfXyeGzJq4_BagcSsxmMlWCbOfH81qg8p51WASbdcdT96sVBg/viewform",
      [AVAILABLE_LANGS.FR]:
        "https://docs.google.com/forms/d/e/1FAIpQLScEtjKyknB7n6m8PdNhgjRVvK7SSqgfb-srP_zWVJhwGPB6Iw/viewform",
    },
  },
};

export { config as fr };
