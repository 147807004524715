import * as React from "react";

import { IS_BROWSER, IS_TEST } from "@config/consts";

import { mediaQueries } from "@ui/themes/default";

interface BreakpointState {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  desktopLarge: boolean;
}

interface OptsState {
  isBreakpointResolved: boolean;
}

const defaultValue: BreakpointState = {
  mobile: true,
  tablet: false,
  desktop: false,
  desktopLarge: false,
};

const defaultOpts: OptsState = {
  isBreakpointResolved: false,
};

const BreakpointContext = React.createContext<BreakpointState & OptsState>({
  ...defaultValue,
  ...defaultOpts,
});

interface Props {
  children: React.ReactNode;
}

const BreakpointProvider = (props: Props): React.ReactElement => {
  const [queryMatch, setQueryMatch] = React.useState({ ...defaultValue });
  const [opts, setOpts] = React.useState({ ...defaultOpts });

  React.useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    const keys = Object.keys(mediaQueries) as Array<keyof BreakpointState>;
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {} as Record<keyof BreakpointState, boolean>);
      setQueryMatch(updatedMatches);
      setOpts({
        ...opts,
        isBreakpointResolved: true,
      });
    };

    if (IS_BROWSER && !IS_TEST && window.matchMedia) {
      const matches: Partial<BreakpointState> = {};
      keys.forEach(media => {
        if (typeof mediaQueries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(mediaQueries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches as BreakpointState);
      setOpts({
        ...opts,
        isBreakpointResolved: true,
      });
      isAttached = true;

      keys.forEach(media => {
        if (typeof mediaQueries[media] === "string") {
          try {
            mediaQueryLists[media].addEventListener(
              "change",
              handleQueryListener,
            );
          } catch {
            // old versions of Safari
            mediaQueryLists[media].addListener(handleQueryListener);
          }
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach(media => {
          if (typeof mediaQueries[media] === "string") {
            const fn =
              mediaQueryLists[media].removeEventListener ||
              mediaQueryLists[media].removeListener;
            fn("change", handleQueryListener);
          }
        });
      }
    };
  }, [mediaQueries]);

  return (
    <BreakpointContext.Provider value={{ ...queryMatch, ...opts }}>
      {props.children}
    </BreakpointContext.Provider>
  );
};

interface TestProviderProps {
  children: React.ReactNode;
  breakpoint: keyof BreakpointState;
}

const BreakpointTestProvider = (props: TestProviderProps) => {
  return (
    <BreakpointContext.Provider
      value={{
        mobile: props.breakpoint === "mobile",
        tablet: props.breakpoint === "tablet",
        desktop: props.breakpoint === "desktop",
        desktopLarge: props.breakpoint === "desktopLarge",
        isBreakpointResolved: true,
      }}
    >
      {props.children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoint(): BreakpointState & OptsState {
  const context = React.useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }

  return context;
}

export { useBreakpoint, BreakpointProvider, BreakpointTestProvider };
