import React from "react";
import { Event } from "@batmaid/telemetry";

import { BatmaidTelemetry } from "@services/Telemetry";
import { generatePageId } from "@services/GeneratePageId";

interface Context {
  isEnabled: boolean;
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const TelemetryContext = React.createContext<Context>({
  isEnabled: false,
  setIsEnabled: () => undefined,
});

interface Props {
  children: React.ReactNode;
}

const TelemetryProvider = (props: Props): React.ReactElement => {
  const [isEnabled, setIsEnabled] = React.useState(false);

  return (
    <TelemetryContext.Provider value={{ isEnabled, setIsEnabled }}>
      {props.children}
    </TelemetryContext.Provider>
  );
};

interface ElementClickProps {
  context?: Record<string, any>;
  buttonId?: string;
  pageId?: string;
  withAutoPageId?: boolean;
}
interface PageViewProps {
  pageId?: string;
  context?: Record<string, any>;
}
interface SendEventProps {
  buttonLabel?: string;
  context?: Record<string, any>;
  pageId?: string;
}

const useTelemetry = () => {
  const context = React.useContext(TelemetryContext);
  const isTelemetryEnabled = context.isEnabled;

  const enable = () => {
    context.setIsEnabled(true);
  };

  const setFeatureFlags = (featureFlags: Record<string, boolean>) => {
    BatmaidTelemetry.setFeatureFlags(featureFlags);
  };

  const elementClick = (
    buttonLabel?: string,
    props: ElementClickProps = {},
  ) => {
    if (!isTelemetryEnabled) return;

    if (props.withAutoPageId) {
      return BatmaidTelemetry.elementClick(buttonLabel, {
        ...props,
        pageId: generatePageId(),
      });
    }

    return BatmaidTelemetry.elementClick(buttonLabel, props);
  };

  const pageView = (props?: PageViewProps) => {
    if (!isTelemetryEnabled) return;

    return BatmaidTelemetry.pageView(props);
  };

  const sendEvent = (event: Event, props?: SendEventProps) => {
    if (!isTelemetryEnabled) return;

    return BatmaidTelemetry.sendEvent(event, props);
  };

  return React.useMemo(() => {
    return {
      setFeatureFlags,
      elementClick,
      pageView,
      sendEvent,
      enable,
      isEnabled: isTelemetryEnabled,
    };
  }, [isTelemetryEnabled]);
};

export {
  useTelemetry,
  TelemetryProvider,
  ElementClickProps,
  PageViewProps,
  SendEventProps,
};
