import { Record, List } from "immutable";
import { AnyAction } from "redux";

import { PaymentAlias } from "@typings/payments";
import { Referral, BasicInfo } from "@typings/profile";

import {
  ACTION_NO_DATA,
  ACTION_SUCCESS,
} from "@services/AsyncActionCreatorFactory";

import {
  FETCH_ACCOUNT_BALANCE,
  FETCH_BASIC_INFO,
  SET_BASIC_INFO,
  FETCH_PAYMENT_ALIASES,
  DELETE_PAYMENT_ALIAS,
  SET_DEFAULT_PAYMENT_ALIAS,
} from "./profile.actions";
import { ProfileReducer, BatsoftAccountBalance } from "./profile.typings";
import {
  basicInfoInitialState,
  basicInfoFactory,
  paymentAliasesFactory,
  deletePaymentAliasFactory,
  defaultPaymentAliasFactory,
  referralInitialState,
  accountBalanceInitialState,
  accountBalanceFactory,
} from "./profile.factories";

const initialState = Record<ProfileReducer>({
  referral: Record<Referral>(referralInitialState)(),
  accountBalance: Record<BatsoftAccountBalance>(accountBalanceInitialState)(),
  basicInfo: Record<BasicInfo>(basicInfoInitialState)(),
  paymentAliases: List<Record<PaymentAlias>>(),
});

const profileReducer = (
  store: Record<ProfileReducer> = initialState(),
  action: AnyAction,
): Record<ProfileReducer> => {
  const { type, payload, params } = action;

  switch (type) {
    case `${FETCH_ACCOUNT_BALANCE}${ACTION_SUCCESS}`: {
      return store.set("accountBalance", accountBalanceFactory(payload.data));
    }
    case `${FETCH_BASIC_INFO}${ACTION_SUCCESS}`: {
      return store.set("basicInfo", basicInfoFactory(payload.data));
    }
    case `${SET_BASIC_INFO}${ACTION_SUCCESS}`: {
      return store.set("basicInfo", basicInfoFactory(params));
    }
    case `${FETCH_PAYMENT_ALIASES}${ACTION_SUCCESS}`: {
      return store.set("paymentAliases", paymentAliasesFactory(payload.data));
    }
    case `${DELETE_PAYMENT_ALIAS}${ACTION_NO_DATA}`: {
      return store.set(
        "paymentAliases",
        deletePaymentAliasFactory(
          store.get("paymentAliases"),
          params.paymentAliasUuid,
        ),
      );
    }
    case `${SET_DEFAULT_PAYMENT_ALIAS}${ACTION_SUCCESS}`: {
      return store.set(
        "paymentAliases",
        defaultPaymentAliasFactory(
          store.get("paymentAliases"),
          params.paymentAliasUuid,
        ),
      );
    }
    default: {
      return store;
    }
  }
};

export { profileReducer };
