import { formatISO, roundToNearestMinutes } from "date-fns";
import { List, Map } from "immutable";

import {
  EXTRA_SERVICES_BOOKING,
  KEY_PICK,
  CLEANING_FREQUENCY,
  PLAN,
} from "@typings/booking";
import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";

import { IS_PROD } from "@config/consts";

export const defaultValues = {
  misc: {
    minPasswordLength: 6,
  },
  booking: {
    isCheckoutDone: false,
    hasSomePaymentAliases: false,
    plan: PLAN.RELAX,
    bedroomNo: 1,
    bathroomNo: 1,
    keyPick: KEY_PICK.SOMEONE_AT_HOME,
    locationComment: null,
    specialInstructions: null,
    tasks: {
      [EXTRA_SERVICES_BOOKING.HOME_CLEANING]: {
        selected: true,
        duration: 2,
      },
      [EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.IRONING]: {
        selected: false,
        duration: 0.5,
        maxDuration: 11,
      },
      [EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY]: {
        selected: false,
        duration: 1,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_OVEN]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_CABINETS]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.BONUS_15]: {
        selected: false,
        duration: 0.25,
      },
    },
    homeCleaningTime: {
      max: 15,
      min: 2,
      step: 0.5,
    },
    homeCleaningTimeAdmin: {
      min: 0.5,
    },
    cleaningFrequency: CLEANING_FREQUENCY.WEEKLY,
    pets: {
      selectedOptionValue: false,
    },
    executionDateTime: formatISO(
      roundToNearestMinutes(new Date(0), { nearestTo: 30 }),
    ),
    reservationUuid: null,
    serviceLocationId: IS_PROD ? 0 : 1,
    isExecutionDateTimeValidated: false,
    chosenAgentIds: List(),
    salarySlider: {
      step: 0.25,
      superGrossSalary: 0,
    },
    chosenAgents: Map(),
    chosenAgentId: null,
    sicknessInsurance: SICKNESS_INSURANCE.FIFTY_FIFTY,
    sicknessInsuranceCosts: {
      fiftyFifty: 0,
      fullCoverageByEmployer: 0,
    },
    professionalAccidentInsurance: false,
    agreeToProfessionalAccidentInsurance: true,
    insurancePolicyNumber: "",
    insurancePolicyWillBeProvidedLater: false,
    secondPillarRetirement: SECOND_PILLAR_RETIREMENT.NONE,
    secondPillarRetirementCosts: {
      none: 0,
      fiftyFifty: 0,
      fullCoverageByEmployer: 0,
    },
    queryParams: "",
    locationUuid: "",
    mainLocationUuid: "",
    salaryDefaultsFetched: false,
    sliderChangeCount: 0,
    salaryDefaults: {
      minimumSuperGrossSalary: 0,
      minimumNetSalary: 0,
      defaultSuperGrossSalary: 0,
      maximumSuperGrossSalary: 50,
      secondPillarRetirementNone: 0,
      secondPillarRetirementFiftyFifty: 0,
      secondPillarRetirementFullCoverageByEmployer: 0,
      sicknessInsuranceFiftyFifty: 0,
      sicknessInsuranceFullCoverageByEmployer: 0,
      relaxPlanHourlyFee: 0,
      takeAndLeaveFee: 0,
    },
    salary: {
      superGrossSalary: 0,
      grossSalaryIncludingVacation: 0,
      netSalary: 0,
      holidayAllowance: 0,
      holidayAllowancePercent: 0,
      employerContributions: List(),
      employerContributionsTotal: 0,
      employeeContributions: List(),
      employeeContributionsTotal: 0,
    },
    previousEmployee: null,
    hasPreviousActiveContract: false,
    relaxPlanRedirectUrl: null,
    isRelaxPlanCompleted: false,
  },
  payment: {
    status: null,
    publicMessage: null,
    referenceNumber: "",
    originRoute: "",
    targetRoute: "",
  },
};
