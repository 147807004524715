import { USER_NOTIFICATIONS_ENDPOINT } from "@config/endpoints";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { IUserNotificationParam } from "./userNotifications.typings";

const action = new ReduxActionNameCreator("user notifications");

// Actions
export const FETCH_USER_NOTIFICATIONS = action.make("fetch user notifications");
export const SET_USER_NOTIFICATIONS = action.make("set user notifications");

// Actions creators
export const fetchUserNotifications = () => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: USER_NOTIFICATIONS_ENDPOINT,
    action: FETCH_USER_NOTIFICATIONS,
  });

  return dispatch(action);
};

export const setUserNotifications = (
  userNotifications: IUserNotificationParam,
) => async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: USER_NOTIFICATIONS_ENDPOINT,
    action: SET_USER_NOTIFICATIONS,
    method: ASYNC_ACTION_TYPES.PATCH,
    body: userNotifications,
    params: userNotifications,
  });

  return dispatch(action);
};
