import { AnyAction } from "redux";

import { PAYMENT_STATUS_ENDPOINT } from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { IStore } from "@soft/redux/reducers";
import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("payment");

export const FETCH_PAYMENT_STATUS = action.make("fetch payment status");
export const SET_REFERENCE_NUMBER = action.make("set reference number");
export const SET_ORIGIN_ROUTE = action.make("set origin route");
export const SET_TARGET_ROUTE = action.make("set target route");

export const fetchPaymentStatus =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const referenceNumber = store().payment.get("referenceNumber");

    const action = await asyncActionCreator({
      url: PAYMENT_STATUS_ENDPOINT.replace(
        "{referenceNumber}",
        referenceNumber,
      ),
      action: FETCH_PAYMENT_STATUS,
    });

    return dispatch(action);
  };

export const setReferenceNumber = (referenceNumber: string): AnyAction => ({
  type: SET_REFERENCE_NUMBER,
  payload: referenceNumber,
});

export const setOriginRoute = (
  originRoute: string,
  serviceLocationId?: string,
): AnyAction => ({
  type: SET_ORIGIN_ROUTE,
  payload: { originRoute, serviceLocationId },
});

export const setTargetRoute = (targetRoute: string): AnyAction => ({
  type: SET_TARGET_ROUTE,
  payload: targetRoute,
});
