import { AnyAction } from "redux";

import { BATSOFT_HOST } from "@config/consts";
import {
  BOOKING_SALARY_INSURANCES_LPP_ENDPOINT,
  BOOKING_SALARY_INSURANCES_APG_ENDPOINT,
  BOOKING_SALARY_DEFAULTS_ENDPOINT,
  BOOKING_SALARY_ENDPOINT,
  BOOKING_SALARY_SUPERGROSS_ENDPOINT,
} from "@config/soft.endpoints";

import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";
import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { stringifyObjectValues } from "@services/StringifyObjectValues";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { buildUrl } from "@services/BuildUrl";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";
import { IStore } from "@soft/redux/reducers";
import {
  deleteLocalStorageHelper,
  setFromLocalStorageHelper,
  setLocalStorageHelper,
} from "@soft/redux/helpers";

import {
  getSicknessInsuranceEndpointRequest,
  getSalaryDefaultsEndpointRequest,
  getSalaryEndpointRequest,
  getSecondPillarRetirementEndpointRequest,
  getSupergrossSalaryEndpointRequest,
} from "./salary.repository";
import {
  CachedSalaryData,
  SalaryProposalParams,
  LOCAL_STORAGE_KEY,
} from "./salary.typings";

const action = new ReduxActionNameCreator("salary");

export const SALARY_ACTIONS = {
  SET_EXECUTION_DATETIME: action.make("set execution datetime"),
  SET_PROFESSIONAL_ACCIDENT_INSURANCE: action.make(
    "set professional accident insurance",
  ),
  SET_AGREE_TO_PROFESSIONAL_ACCIDENT_INSURANCE: action.make(
    "set agree to professional accident insurance",
  ),
  SET_INSURANCE_POLICY_NUMBER: action.make("set insurance policy number"),
  SET_INSURANCE_POLICY_WILL_BE_PROVIDED_LATER: action.make(
    "set insurance policy will be provided later",
  ),
  SET_SICKNESS_INSURANCE: action.make("set sickness insurance"),
  FETCH_SICKNESS_INSURANCE_COSTS: action.make("fetch sickness insurance costs"),
  SET_SECOND_PILLAR_RETIREMENT: action.make("set second pillar retirement"),
  FETCH_SECOND_PILLAR_RETIREMENT_COSTS: action.make(
    "fetch second pillar retirement costs",
  ),
  FETCH_SALARY_DEFAULTS: action.make("fetch salary defaults"),
  FETCH_SALARY: action.make("fetch salary"),
  SET_SLIDER_SUPERGROSS_SALARY: action.make("set slider supergross salary"),
  FETCH_SUPERGROSS_SALARY: action.make("fetch supergross salary"),
  SET_CHOSEN_AGENT_ID: action.make("set chosen agent uuid"),
  SET_SERVICE_LOCATION_ID: action.make("set service location id"),
  SET_SALARY_PROPOSAL_PARAMS: action.make("set salary proposal params"),
  CACHE_SALARY_DATA: action.make("cache salary data"),
  RESET_STATE: action.make("reset state"),

  SET_IN_LOCAL_STORAGE: action.make("set in local storage"),
  SET_FROM_LOCAL_STORAGE: action.make("set from local storage"),
  DELETE_LOCAL_STORAGE: action.make("delete local storage"),
};

export const setProfessionalAccidentInsurance = (
  payload: boolean,
): AnyAction => ({
  type: SALARY_ACTIONS.SET_PROFESSIONAL_ACCIDENT_INSURANCE,
  payload,
});

export const setAgreeToProfessionalAccidentInsurance = (
  payload: boolean,
): AnyAction => ({
  type: SALARY_ACTIONS.SET_AGREE_TO_PROFESSIONAL_ACCIDENT_INSURANCE,
  payload,
});

export const setInsurancePolicyNumber = (payload: string): AnyAction => ({
  type: SALARY_ACTIONS.SET_INSURANCE_POLICY_NUMBER,
  payload,
});

export const setInsurancePolicyWillBeProvidedLater = (
  payload: boolean,
): AnyAction => ({
  type: SALARY_ACTIONS.SET_INSURANCE_POLICY_WILL_BE_PROVIDED_LATER,
  payload,
});

export const setSicknessInsurance =
  (payload: SICKNESS_INSURANCE) =>
  (dispatch: ThunkActionDispatch, store: () => IStore): AnyAction =>
    dispatch({
      type: SALARY_ACTIONS.SET_SICKNESS_INSURANCE,
      payload: {
        prevValue: store().salary.get("sicknessInsurance"),
        newValue: payload,
      },
    });

export const setSecondPillarRetirement =
  (payload: SECOND_PILLAR_RETIREMENT) =>
  (dispatch: ThunkActionDispatch, store: () => IStore): AnyAction =>
    dispatch({
      type: SALARY_ACTIONS.SET_SECOND_PILLAR_RETIREMENT,
      payload: {
        prevValue: store().salary.get("secondPillarRetirement"),
        newValue: payload,
      },
    });

export const fetchSicknessInsuranceCosts =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSicknessInsuranceEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_INSURANCES_APG_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{superGrossSalaryValue}",
            stringifiedParams.superGrossSalary,
          )
          .replace(
            "{secondPillarRetirementValue}",
            stringifiedParams.secondPillarRetirement,
          )
          .replace(
            "{sicknessInsuranceValue}",
            stringifiedParams.sicknessInsurance,
          ),
        action: SALARY_ACTIONS.FETCH_SICKNESS_INSURANCE_COSTS,
        method: ASYNC_ACTION_TYPES.GET,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const fetchSecondPillarRetirementCosts =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSecondPillarRetirementEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_INSURANCES_LPP_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{superGrossSalaryValue}",
            stringifiedParams.superGrossSalary,
          )
          .replace(
            "{secondPillarRetirementValue}",
            stringifiedParams.secondPillarRetirement,
          )
          .replace(
            "{sicknessInsuranceValue}",
            stringifiedParams.sicknessInsurance,
          ),
        action: SALARY_ACTIONS.FETCH_SECOND_PILLAR_RETIREMENT_COSTS,
        method: ASYNC_ACTION_TYPES.GET,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const setExecutionDateTime = (payload: string): AnyAction => ({
  type: SALARY_ACTIONS.SET_EXECUTION_DATETIME,
  payload,
});

interface FetchSalaryDefaultsOptionalParam {
  serviceLocationId: number;
  chosenAgentId: string;
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT;
}

export const fetchSalaryDefaults =
  (queryParams?: FetchSalaryDefaultsOptionalParam | undefined) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = queryParams ?? getSalaryDefaultsEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: buildUrl(BOOKING_SALARY_DEFAULTS_ENDPOINT)
          .setQuery("serviceLocationId", stringifiedParams.serviceLocationId)
          .setQuery(
            "secondPillarRetirement",
            stringifiedParams.secondPillarRetirement,
          )
          .setQuery("agentUuid", stringifiedParams.chosenAgentId, {
            optional: true,
          })
          .done(),
        action: SALARY_ACTIONS.FETCH_SALARY_DEFAULTS,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

interface FetchSalaryOptionalParam {
  serviceLocationId: number;
  superGrossSalary: number;
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  sicknessInsurance: SICKNESS_INSURANCE;
  agreeToProfessionalAccidentInsurance: boolean;
}

export const fetchSalary =
  (queryParams?: FetchSalaryOptionalParam | undefined) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = queryParams ?? getSalaryEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{superGrossSalaryValue}",
            stringifiedParams.superGrossSalary,
          )
          .replace(
            "{secondPillarRetirementValue}",
            stringifiedParams.secondPillarRetirement,
          )
          .replace(
            "{sicknessInsuranceValue}",
            stringifiedParams.sicknessInsurance,
          )
          .replace(
            "{agreeToProfessionalAccidentInsuranceValue}",
            stringifiedParams.agreeToProfessionalAccidentInsurance,
          ),
        action: SALARY_ACTIONS.FETCH_SALARY,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const setSliderSupergrossSalary = (payload: number): AnyAction => ({
  type: SALARY_ACTIONS.SET_SLIDER_SUPERGROSS_SALARY,
  payload,
});

export const fetchSupergrossSalary =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSupergrossSalaryEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_SUPERGROSS_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{grossSalaryIncludingVacationValue}",
            stringifiedParams.grossSalaryIncludingVacation,
          )
          .replace(
            "{currentSecondPillarRetirementValue}",
            stringifiedParams.currentSecondPillarRetirement,
          )
          .replace(
            "{newSecondPillarRetirementValue}",
            stringifiedParams.newSecondPillarRetirement,
          )
          .replace(
            "{currentSicknessInsuranceValue}",
            stringifiedParams.currentSicknessInsurance,
          )
          .replace(
            "{newSicknessInsuranceValue}",
            stringifiedParams.newSicknessInsurance,
          ),
        action: SALARY_ACTIONS.FETCH_SUPERGROSS_SALARY,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const setChosenAgentId = (payload: string): AnyAction => ({
  type: SALARY_ACTIONS.SET_CHOSEN_AGENT_ID,
  payload,
});

export const setServiceLocationId = (payload: number): AnyAction => ({
  type: SALARY_ACTIONS.SET_SERVICE_LOCATION_ID,
  payload,
});

export const setSalaryProposalParams = (
  payload: SalaryProposalParams,
): AnyAction => ({
  type: SALARY_ACTIONS.SET_SALARY_PROPOSAL_PARAMS,
  payload,
});

export const cachePreviousData = (payload: CachedSalaryData): AnyAction => ({
  type: SALARY_ACTIONS.CACHE_SALARY_DATA,
  payload,
});

export const resetState = (): AnyAction => ({
  type: SALARY_ACTIONS.RESET_STATE,
});

export const setLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch, store: () => IStore): void => {
    setLocalStorageHelper(
      dispatch,
      SALARY_ACTIONS.SET_IN_LOCAL_STORAGE,
      store().salary,
      LOCAL_STORAGE_KEY,
    );
  };

export const setFromLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    setFromLocalStorageHelper(
      dispatch,
      SALARY_ACTIONS.SET_FROM_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };

export const deleteLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    deleteLocalStorageHelper(
      dispatch,
      SALARY_ACTIONS.DELETE_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };
