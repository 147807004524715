import { AnyAction } from "redux";

import {
  MY_CLEANINGS_ENDPOINT,
  TRUST_MANDATE_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

import { SaveOption } from "./userOptions.typings";

const actionCreator = new ReduxActionNameCreator("user options");

// Actions
export const FETCH_OPTIONS: string = actionCreator.make("fetch options");
export const SAVE_OPTION: string = actionCreator.make("save option");
export const FETCH_TRUST_MANDATE_2020_URLS = actionCreator.make(
  "fetch trust mandate 2020 urls",
);
export const SET_HAS_SIGNED_TRUST_MANDATE_IN_DOCUSIGN = actionCreator.make(
  "set has signed trust mandate in docusign",
);

// Action creators
export const fetchOptions =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const url = `${MY_CLEANINGS_ENDPOINT}/options`;
    const action = await asyncActionCreator({
      url,
      action: FETCH_OPTIONS,
    });

    return dispatch(action);
  };

export const saveOption =
  (payload: SaveOption) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const url = `${MY_CLEANINGS_ENDPOINT}/options`;

    const action = await asyncActionCreator({
      action: SAVE_OPTION,
      body: {
        key: payload.option,
        value: payload.value,
      },
      params: {
        ...payload,
      },
      method: ASYNC_ACTION_TYPES.POST,
      url,
    });

    return dispatch(action);
  };

export const fetchTrustMandate2020Urls =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      action: FETCH_TRUST_MANDATE_2020_URLS,
      url: TRUST_MANDATE_ENDPOINT,
    });

    return dispatch(action);
  };

export const setHasSignedTrustMandateInDocusign = (
  payload: boolean,
): AnyAction => ({
  type: SET_HAS_SIGNED_TRUST_MANDATE_IN_DOCUSIGN,
  payload,
});
