import { IS_DEV } from "@config/consts";
import { MULTIPART_FORM_DATA } from "@config/contentTypes";

import { SimpleObject } from "@typings/globals";

export enum ASYNC_ACTION_TYPES {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}

export interface FetchFacadeReturnType<
  ResponseType = SimpleObject | SimpleObject[],
> {
  status: number;
  data: ResponseType;
  headers: Record<string, string>;
  ok: boolean;
}

export const defaultOptions = {
  credentials: "include" as RequestInit["credentials"],
  retry: 0,
  headers: {
    Authorization: `Basic ${btoa(
      `${process.env.AUTH_USER}:${process.env.AUTH_PASS}`,
    )}`,
    "X-Version": process.env.VERSION!,
    "Content-Type": "application/json",
  },
};

const getHeaders = (headers: SimpleObject): Record<string, string> => {
  let headerObj = {};
  headers.forEach((val: string, key: string) => {
    headerObj = {
      ...headerObj,
      [key]: val,
    };
  });
  return headerObj;
};

export const fetchFacade = async <ResponseType = any>(
  url: string,
  options: { [k: string]: any } = {},
): Promise<FetchFacadeReturnType<ResponseType>> => {
  const { headers, ...otherOptions } = options;

  const allOptions = {
    ...defaultOptions,
    ...otherOptions,
    headers: {
      ...defaultOptions.headers,
      ...headers,
    },
  };

  if (allOptions.headers["Content-Type"] === MULTIPART_FORM_DATA) {
    delete allOptions.headers["Content-Type"];
  }

  const destinationUrl = IS_DEV ? await getMockedUrl(url, options.method) : url;

  const result = await fetch(destinationUrl, allOptions);
  const resultHeaders = getHeaders(result.headers);

  try {
    return {
      status: result.status,
      headers: resultHeaders,
      data: (await result) && (await result.json()),
      ok: result.ok,
    };
  } catch {
    return {
      status: result.status,
      headers: resultHeaders,
      data: {} as any,
      ok: false,
    };
  }
};
interface MockConfig {
  [key: string]: { method?: string };
}

const getMockedUrl = async (url: string, method: ASYNC_ACTION_TYPES) => {
  const urlObject = new URL(url, location.href);
  const relativePath = urlObject.pathname.split("/v1")[1];

  const config: MockConfig = (await import("../../endpoint_mocks.json"))
    .default;

  if (config[relativePath]) {
    if (
      !config[relativePath]?.method ||
      config[relativePath].method === method
    ) {
      return process.env.MOCK_SERVER + url.split("/v1")[1];
    }
  }

  return url;
};
