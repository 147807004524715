import { AnyAction } from "redux";

import {
  BOOKINGS_TO_VALIDATE_ENDPOINT,
  BOOKINGS_TO_VALIDATE_PAYMENT_METHODS_ENDPOINT,
  LOCATIONS_ENDPOINT,
} from "@config/endpoints";
import {
  CONTRACT_VOUCHER_ENDPOINT,
  LEGAL_LOCATION_ENDPOINT,
} from "@config/soft.endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";
import { PAYMENT_METHODS } from "@typings/globals";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import {
  setLocalStorageHelper,
  setFromLocalStorageHelper,
  deleteLocalStorageHelper,
} from "@soft/redux/helpers";
import { IStore } from "@soft/redux/reducers";
import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

import { getAddLegalLocationEndpointRequest } from "./bookingsToValidate.repository";
import { LOCAL_STORAGE_KEY } from "./bookingsToValidate.typings";

const action = new ReduxActionNameCreator("bookings to validate");

export const FETCH_JOBS_TO_VALIDATE = action.make("fetch jobs to validate");
export const FETCH_PAYMENT_METHODS = action.make("fetch payment methods");
export const SUBMIT_PAYMENT = action.make("submit payment");
export const ADD_LEGAL_LOCATION = action.make("add legal location");
export const PUT_LEGAL_LOCATION = action.make("put legal location");
export const SET_PAYMENT_METHOD = action.make("set payment method");
export const SET_LEGAL_SERVICE_LOCATION_ID = action.make(
  "set service location id",
);
export const SET_LEGAL_ADDRESS = action.make("set legal address");
export const SET_LEGAL_FLOOR_AND_DOOR = action.make("set legal floor and door");
export const SET_LEGAL_CITY = action.make("set legal city");
export const SET_LEGAL_ZIP = action.make("set zip");
export const SET_IN_LOCAL_STORAGE = action.make("set in local storage");
export const SET_FROM_LOCAL_STORAGE = action.make("set from local storage");
export const DELETE_LOCAL_STORAGE = action.make("delete local storage");
export const FETCH_VOUCHER = action.make("fetch voucher");
export const RESET_VOUCHER = action.make("reset voucher");
export const SET_IS_LEGAL_LOCATION_THE_SAME = action.make(
  "set is legal location the same",
);

export const fetchJobsToValidate =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: BOOKINGS_TO_VALIDATE_ENDPOINT,
      action: FETCH_JOBS_TO_VALIDATE,
    });

    return dispatch(action);
  };

export const submitPayment =
  (contractUuid: string, returnUrl?: string) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const bookingsToValidateStore = store().bookingsToValidate;
    const method = bookingsToValidateStore.get("selectedPaymentMethod");

    const action = await asyncActionCreator({
      url: BOOKINGS_TO_VALIDATE_PAYMENT_METHODS_ENDPOINT.replace(
        "{uuid}",
        contractUuid,
      ),
      action: SUBMIT_PAYMENT,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        method,
        ...(returnUrl && { returnUrl }),
      },
    });

    return dispatch(action);
  };

export const fetchPaymentMethods =
  (uuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: BOOKINGS_TO_VALIDATE_PAYMENT_METHODS_ENDPOINT.replace(
        "{uuid}",
        uuid,
      ),
      action: FETCH_PAYMENT_METHODS,
    });

    return dispatch(action);
  };

export const setPaymentMethod = (
  payload: PAYMENT_METHODS | null,
): AnyAction => ({
  type: SET_PAYMENT_METHOD,
  payload,
});

export const setLegalAddress = (payload: string): AnyAction => ({
  type: SET_LEGAL_ADDRESS,
  payload,
});

export const setLegalFloorAndDoor = (payload: string): AnyAction => ({
  type: SET_LEGAL_FLOOR_AND_DOOR,
  payload,
});

export const setLegalCity = (payload: string): AnyAction => ({
  type: SET_LEGAL_CITY,
  payload,
});

export const setLegalZip = (payload: string): AnyAction => ({
  type: SET_LEGAL_ZIP,
  payload,
});

export const setServiceLocationId = (payload: number): AnyAction => ({
  type: SET_LEGAL_SERVICE_LOCATION_ID,
  payload,
});

export const setIsLegalLocationTheSame = (payload: boolean): AnyAction => ({
  type: SET_IS_LEGAL_LOCATION_THE_SAME,
  payload,
});

export const addLegalLocation =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getAddLegalLocationEndpointRequest(store());

    const action = await asyncActionCreator({
      url: LOCATIONS_ENDPOINT,
      action: ADD_LEGAL_LOCATION,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const putLegalLocation =
  (locationId: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: LEGAL_LOCATION_ENDPOINT,
      action: PUT_LEGAL_LOCATION,
      method: ASYNC_ACTION_TYPES.PUT,
      body: { locationId },
    });

    return dispatch(action);
  };

// *** LOCAL STORAGE ***
export const setLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch, store: () => IStore): void => {
    setLocalStorageHelper(
      dispatch,
      SET_IN_LOCAL_STORAGE,
      store().bookingsToValidate,
      LOCAL_STORAGE_KEY,
    );
  };

export const setFromLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    setFromLocalStorageHelper(
      dispatch,
      SET_FROM_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };

export const deleteLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    deleteLocalStorageHelper(dispatch, DELETE_LOCAL_STORAGE, LOCAL_STORAGE_KEY);
  };

// *** VOUCHER ***
export const fetchVoucher =
  (contractUuid: string, voucherToken: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: CONTRACT_VOUCHER_ENDPOINT.replace("{contractUuid}", contractUuid),
      action: FETCH_VOUCHER,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        voucherToken,
      },
      params: {
        voucherToken,
      },
    });

    return dispatch(action);
  };

export const resetVoucher = (): AnyAction => ({
  type: RESET_VOUCHER,
});
