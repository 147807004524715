import * as HTTP_CODE from "@config/httpStatuses";
import {
  LOGIN_ENDPOINT,
  USER_RECOVER_PASSWORD_ENDPOINT,
  PROFILE_PASSWORD_ENDPOINT,
  USER_DISCONNECT_OAUTH_ENDPOINT,
  CLIENT_REGISTRATION_ENDPOINT,
  APPLY_ENDPOINT,
} from "@config/endpoints";
import { IS_TEST } from "@config/consts";
import { SENTRY_TAG_VALUE_MAX_LENGTH } from "@config/sentry";

import { SimpleObject } from "@typings/globals";

import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import {
  ASYNC_ACTION_TYPES,
  FetchFacadeReturnType,
} from "@services/FetchFacade";

export const ACTION_PENDING = "_PENDING";
export const ACTION_FAIL = "_FAIL";
export const ACTION_SUCCESS = "_SUCCESS";
export const ACTION_NO_DATA = "_NO_DATA";
export const ACTION_CANCELED = "_CANCELED";

export const ERROR_ABORT = "ABORT_ERROR";
export const ERROR_NETWORK = "NETWORK_ERROR";

export interface IReduxFetchAction {
  action: string;
  extra?: { [k: string]: string | boolean | number };
  body?: any;
  formData?: FormData;
  method?: ASYNC_ACTION_TYPES;
  url: string;
  meta?: {
    previousAction: {
      payload: any;
    };
  };
  params?: SimpleObject;
  auth?: {
    username: string;
    password: string;
  };
  retryConnection?: boolean;
  headers?: {
    [k: string]: string;
  };
}

// Custom Sentry API ERROR data
export interface SentryExtraData {
  [k: string]: string | number | boolean | null | undefined;
}

export interface IConfig {
  host?: string;
  apiEndpoint?: string;
  headers?: {
    [k: string]: string;
  };
  signal?: AbortSignal;
}

export interface AsyncActionResult<ResponseT = any> {
  params: SimpleObject | undefined;
  status: number | null;
  ok: boolean; // Boolean stating whether the response was successful (status in the range 200-299) or not
  type: string;
  error: string | null;
  errors: { [k: string]: string }[];
  message: null | string;
  retry: boolean | null;
  translatedErrorMessage: string | null;
  extra: SimpleObject | undefined;
  meta: SimpleObject;
  payload: {
    data?: ResponseT;
  };
  aborted?: boolean;
  mapApiErrorsToForm: (
    setFormErrors: (input: { [k: string]: string }) => void,
    opts?: {
      preventGlobalError: boolean;
    },
  ) => AsyncActionResult;
  onError: (
    callback: (errors: UnifiedError) => void,
    opts?: {
      matchCode?: number;
    },
  ) => AsyncActionResult;
  onSuccess: (
    callback: (payload: { data: ResponseT }) => void,
  ) => AsyncActionResult;
  setResult: (newResult: AsyncActionResult) => AsyncActionResult;
}

export interface AsyncActionInstanceOpts {
  onFetchErrorNotification: (...args: any[]) => void;
  invalidValueText: string;
  middleware?: { (input: FetchFacadeReturnType): FetchFacadeReturnType }[];
}

export type AsyncActionReturnType = Promise<AsyncActionResult>;

export interface UnifiedError {
  message: string;
  field: string | null;
  enum: string | null;
}

export const getUnifiedErrorResponse = (
  result: AsyncActionResult,
): UnifiedError => {
  // Swagger error schema #1: FormErrorCollection
  if (result.errors.length) {
    return {
      message: result.errors[0].message,
      field: result.errors[0].field || null,
      enum: result.errors[0].error || null,
    };
  }

  // Swagger error schema #2: translatedErrorMessage collection
  if (result.error && result.translatedErrorMessage) {
    return {
      message: result.translatedErrorMessage,
      field: null,
      enum: result.error,
    };
  }

  // Swagger error schema #3: error without translatedErrorMessage
  if (result.error && !result.translatedErrorMessage) {
    return {
      message: "",
      field: null,
      enum: result.error,
    };
  }

  // Swagger error schema #4: translatedErrorMessage only
  if (!result.error && result.translatedErrorMessage) {
    return {
      message: result.translatedErrorMessage,
      field: null,
      enum: null,
    };
  }

  // Swagger error schema #5: message only
  if (result.message) {
    return {
      message: result.message,
      field: null,
      enum: null,
    };
  }

  return {
    message: "",
    field: null,
    enum: null,
  };
};

export const isEndpointDataSafeForTracking = (url: string): boolean => {
  const blacklist = [
    LOGIN_ENDPOINT,
    CLIENT_REGISTRATION_ENDPOINT,
    USER_DISCONNECT_OAUTH_ENDPOINT,
    USER_RECOVER_PASSWORD_ENDPOINT,
    PROFILE_PASSWORD_ENDPOINT,
    APPLY_ENDPOINT,
  ]
    //remove trailing slashes
    .map(path => path.replace(/\/$/, ""));

  return !blacklist.includes(url);
};

export const isResponseAnError = (status: number): boolean =>
  status === HTTP_CODE.BAD_REQUEST ||
  status === HTTP_CODE.METHOD_NOT_ALLOWED ||
  status === HTTP_CODE.NOT_FOUND ||
  status === HTTP_CODE.INTERNAL_SERVER_ERROR ||
  status === HTTP_CODE.NOT_IMPLEMENTED ||
  status === HTTP_CODE.BAD_GATEWAY ||
  status === HTTP_CODE.SERVICE_UNAVAILABLE ||
  status === HTTP_CODE.GATEWAY_TIMEOUT ||
  status === HTTP_CODE.NETWORK_AUTH_FAILED;

export const resolveApiEndpoint = (endpoint?: string): string =>
  endpoint || getApiUrl();

export const replaceLang = (apiEndpoint: string, language: string): string =>
  IS_TEST ? apiEndpoint : apiEndpoint.replace("/en/", `/${language}/`);

export const replaceHost = (endpoint: string, host?: string): string =>
  host ? endpoint.replace(new URL(endpoint).hostname, host) : endpoint;

// this makes sure that a relative endpoint (e.g. /en/api/v1)
// can also be used to make requests
export const constructAbsoluteEndpoint = (endpoint: string): string => {
  try {
    return new URL(endpoint).href;
  } catch (error) {
    return `${window.origin}${endpoint}`;
  }
};

export const extractEndpointPathFromFullPath = (url: string) => {
  switch (url) {
    case url.match("^/client/b2b-booking/reservation/[a-z|0-9|-]{36}/?$")
      ?.input: {
      return "/client/b2b-booking/reservation/{reservationUuid}";
    }
    case url.match("^/client/booking/reservation/[a-z|0-9|-]{36}/?$")?.input: {
      return "/client/booking/reservation/{reservationUuid}";
    }
    case url.match("^/client/cancellation/?\\?")?.input: {
      return "/client/cancellation";
    }
    case url.match("^/client/booking/voucher")?.input: {
      return "/client/booking/voucher";
    }
    case url.match("^/apply/files/[a-z|0-9|-]{36}/?$")?.input: {
      return "/apply/files/{fileId}";
    }
    case url.match("^/client/agents/[a-z|0-9|-]{36}/?$")?.input: {
      return "/client/agents/{uuid}";
    }
    case url.match("^/client/booking/reservation/[a-z|0-9|-]{36}/checkout/?$")
      ?.input: {
      return "/client/booking/reservation/{reservationUuid}/checkout";
    }
  }

  // remove query params
  const path = url.split("?")[0];

  // remove trailing slash
  return path === "/" ? "/" : path.replace(/\/$/, "");
};

export const defaultMeta = {
  previousAction: {
    payload: {},
  },
};

export const prepareSentryTagValue = (input: any): string => {
  const stringifiedInput = JSON.stringify(input);

  if (typeof stringifiedInput === "string") {
    return stringifiedInput.substring(0, SENTRY_TAG_VALUE_MAX_LENGTH);
  }

  return "";
};

export const prepareSentryExtraData = (input: SentryExtraData = {}) => {
  const data: SentryExtraData = {};

  Object.keys(input).forEach(key => {
    const value = input[key];

    if (typeof value === "string") {
      data[key] = prepareSentryTagValue(value);
    } else {
      data[key] = value;
    }
  });

  return data;
};
