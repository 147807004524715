import { List, Record } from "immutable";

import { TITLE_OPTS } from "@typings/globals";
import { IAgent } from "@typings/booking";
import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";
import { PROMO_CODE_NOTIFICATION } from "@typings/globals";
import { EMPLOYEE_VACATIONS } from "@typings/declareEmployee";

export enum HAS_CHILDREN {
  YES = "YES",
  NO = "NO",
}

export interface DeclareEmployeeReducer {
  agentUuid: string;
  agentData: Record<AgentData>;
  employeeContactDetails: Record<EmployeeContactDetails>;
  employeeJobAddress: Record<EmployeeJobAddress>;
  employeePersonalInformation: Record<EmployeePersonalInformation>;
  emergencyContact: Record<EmergencyContact>;
  jobDuration: number;
  employeeHasChildren: HAS_CHILDREN | null;
  employeeChildrenNumber: number | null;
  vacations: EMPLOYEE_VACATIONS | undefined;
  jobFrequency: Record<JobFrequency>;
  employeeBankAccount: Record<EmployeeBankAccount>;
  contractTerm: Record<ContractTerm>;
  service: DECLARE_EMPLOYEE_SERVICE;
  previousAgents: List<Record<IAgent>>;
  declarationUuid: string;
  voucherToken: string;
  voucherStatus: PROMO_CODE_NOTIFICATION;
  voucherMessage: string | null | undefined;
  paymentAliasId: string;
  isSalaryChosen: boolean;
}

export enum DECLARE_EMPLOYEE_SERVICE {
  HOME_CLEANING = "HOME_CLEANING",
}
export interface EmergencyContact {
  name: string;
  phoneNumber: string;
}

export interface AgentData {
  displayName: string;
  phoneNumber: string | undefined;
}

export interface EmployeeContactDetails {
  title: TITLE_OPTS;
  firstName: string;
  lastName: string;
  streetAddress: string;
  zipCode: string;
  mobilePhone: string;
  email: string;
  city: string;
  serviceLocationId: number;
}

export interface EmployeeJobAddress {
  address: string;
  city: string;
  zip: string;
  serviceLocationId: number;
}

export enum WORK_PERMIT {
  PERMIT_B = "PERMIT_B",
  PERMIT_B_REFUGEE = "PERMIT_B_REFUGEE",
  PERMIT_C = "PERMIT_C",
  PERMIT_L = "PERMIT_L",
  PERMIT_F = "PERMIT_F",
  LEGITIMATION_CARD_CI = "LEGITIMATION_CARD_CI",
  IS_SWISS = "IS_SWISS",
  NO_PERMIT = "NO_PERMIT",
  UNKNOWN = "UNKNOWN",
}

export enum CIVIL_STATUS {
  UNKNOWN = "UNKNOWN",
  SINGLE = "SINGLE",
  MARRIED_OR_SEPARATED = "MARRIED_OR_SEPARATED",
  DIVORCED = "DIVORCED",
  WIDOWED = "WIDOWED",
  REGISTERED_CIVIL_PARTNERSHIP = "REGISTERED_CIVIL_PARTNERSHIP",
  PARTNERSHIP_DISSOLVED = "PARTNERSHIP_DISSOLVED",
}

export interface EmployeePersonalInformation {
  avsNumber: string;
  workPermit: WORK_PERMIT;
  civilStatus: CIVIL_STATUS;
  dateOfBirth: string;
}

export enum JOB_FREQUENCY {
  ONCE = "ONCE",
  WEEKLY = "WEEKLY",
  EVERY_2_WEEKS = "EVERY_2_WEEKS",
  EVERY_4_WEEKS = "EVERY_4_WEEKS",
  OTHER = "OTHER",
}

export interface JobFrequency {
  frequency: JOB_FREQUENCY;
  otherFrequencyDetails: string;
}

export interface EmployeeBankAccount {
  bankAccount: string;
  bankAccountOwner: string;
  bankAccountStreetAddress: string;
}

export enum CONTRACT_TERM {
  FIXED = "fixed",
  NON_FIXED = "nonFixed",
}

export interface ContractTerm {
  contractTerm: CONTRACT_TERM;
  contractTermStartDate: string;
  contractTermStartTime: string;
  contractTermEndDate: string;
}

export interface HRDeclareYourEmployeeBody {
  clientId: string;
  title: TITLE_OPTS;
  firstName: string;
  lastName: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  birthDate: string;
  maritalStatus: CIVIL_STATUS;
  workPermit: WORK_PERMIT;
  socialNumber: string;
  bankAccountOwnerName: string;
  bankAccountOwnerAddress: string;
  bankAccountOwnerZipCode: string;
  iban: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  hasChildren: HAS_CHILDREN | null;
  howManyChildren: number | null;
}

export interface ClientDeclareYourEmployeeBody {
  service: DECLARE_EMPLOYEE_SERVICE;
  duration: number;
  frequency: JOB_FREQUENCY;
  otherFrequencyComment: string;
  startDateTime: string;
  endDate?: string;
  vacation: EMPLOYEE_VACATIONS | undefined;
  jobLocationUuid: string;
  agentUuid: string;
}
export interface PutSalaryPayload {
  superGrossSalary: number;
  insurancePolicyNumber?: string;
  insurancePolicyWillBeProvidedLater: boolean;
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  sicknessInsurance: SICKNESS_INSURANCE;
  agreeToProfessionalAccidentInsurance: boolean;
}

export const LOCAL_STORAGE_KEY = "store:declare-employee";
