import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AppConfig } from "@typings/appConfig";
import { FREQUENCY } from "@typings/b2b";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.DE],
  booking: {
    commitments: {
      enabled: false,
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
      ],
      rows: [
        [false, true, true],
        [true, true, true],
        [true, true, true],
        [false, true, true],
        [true, true, true],
      ],
    },
  },
  b2b: {
    contactDetails: {
      phone: "+49 69 299 570 870",
      email: "kunde@batmaid.de",
    },
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLSca2Xhq-vs4MzaMQ0xGCI1Tbgc-aX9urnIln0V7fqILKzQ7Xw/viewform",
      [AVAILABLE_LANGS.DE]:
        "https://docs.google.com/forms/d/e/1FAIpQLSeoXK4Q8C5kmXyNFCMOuUnr9GNPuscZLosAKdinNGjsH3n5gg/viewform",
    },
  },
};

export { config as de };
