import { Record, List } from "immutable";
import { AnyAction } from "redux";

import { Payslip } from "@typings/payslip";

import { PayslipReducer } from "@soft/redux/payslips/payslip.typings";

const initialState = {
  // TODO: remove mock
  results: List<Record<Payslip>>([Record({ uuid: "1", date: new Date() })()]),
};

const payslipsReducer = (
  store = initialState,
  action: AnyAction,
): PayslipReducer => {
  const { type, payload, params } = action;

  switch (type) {
    default: {
      return store;
    }
  }
};

export { payslipsReducer };
