import { Record } from "immutable";
import { AnyAction } from "redux";

import { defaultValues } from "@config/soft.defaultValues";

import { LOADING_STATE } from "@typings/reduxThunkActions";
import { IssuesMap, PicturesMap } from "@typings/rating";
import { SERVICE_TYPE } from "@typings/globals";

import {
  ACTION_FAIL,
  ACTION_PENDING,
  ACTION_SUCCESS,
  ACTION_NO_DATA,
} from "@services/AsyncActionCreatorFactory";

import { RatingReducer } from "./rating.typings";
import {
  FETCH_RATING,
  SET_NEGATIVE_ISSUE,
  SET_NEGATIVE_SUBISSUE,
  SET_SHARE_WITH_AGENT,
  SET_RATING,
  LEAVE_PROCESS,
  SET_POSITIVE_ISSUE,
  SET_COMMENT,
  ADD_PICTURE,
  DELETE_PICTURE,
} from "./rating.actions";
import { ratingFactory, unselectedSubIssuesFactory } from "./rating.factories";

const initialState = Record<RatingReducer>({
  job: {
    uuid: defaultValues.rating.job.uuid,
    startDate: defaultValues.rating.job.startDate,
    endDate: defaultValues.rating.job.endDate,
    mainServiceType: defaultValues.rating.job.mainServiceType as SERVICE_TYPE,
  },
  rating: defaultValues.rating.rating,
  negativeRatings: defaultValues.rating.negativeRatings,
  positiveRatings: defaultValues.rating.positiveRatings,
  negativeIssues: defaultValues.rating.negativeIssues as IssuesMap,
  positiveIssues: defaultValues.rating.positiveIssues as IssuesMap,
  comment: defaultValues.rating.comment,
  pictures: defaultValues.rating.pictures as PicturesMap,
  shareWithAgent: defaultValues.rating.shareWithAgent,
  loadingState: defaultValues.rating.loadingState,
  showReferral: defaultValues.rating.showReferral,
  allowPictures: defaultValues.rating.allowPictures,
  editable: defaultValues.rating.editable,
  maxPictures: defaultValues.rating.maxPictures,
  pictureErrorMessage: defaultValues.rating.pictureErrorMessage,
});

const ratingReducer = (
  store: Record<RatingReducer> = initialState(),
  action: AnyAction,
): Record<RatingReducer> => {
  const { type, payload, params, errors } = action;

  switch (type) {
    case `${FETCH_RATING}${ACTION_PENDING}`: {
      return store.set("loadingState", LOADING_STATE.LOADING);
    }

    case `${FETCH_RATING}${ACTION_SUCCESS}`: {
      return store.mergeDeep(ratingFactory(payload.data, store));
    }

    case `${FETCH_RATING}${ACTION_FAIL}`: {
      return store.set("loadingState", LOADING_STATE.FAILED);
    }

    case SET_NEGATIVE_ISSUE: {
      const issuePath = ["negativeIssues", payload.key];

      if (!payload.selected) {
        return store
          .setIn([...issuePath, "selected"], false)
          .setIn(
            [...issuePath, "subIssues"],
            unselectedSubIssuesFactory(
              store.getIn([...issuePath, "subIssues"]),
            ),
          );
      }
      return store.setIn(["negativeIssues", payload.key, "selected"], true);
    }

    case SET_NEGATIVE_SUBISSUE: {
      return store.setIn(
        [
          "negativeIssues",
          payload.issueKey,
          "subIssues",
          payload.subIssue.key,
          "selected",
        ],
        payload.subIssue.selected,
      );
    }

    case SET_POSITIVE_ISSUE: {
      return store.setIn(
        ["positiveIssues", payload.key, "selected"],
        payload.selected,
      );
    }

    case SET_SHARE_WITH_AGENT: {
      return store.set("shareWithAgent", payload);
    }

    case SET_RATING: {
      return store.set("rating", payload);
    }

    case SET_COMMENT: {
      return store.set("comment", payload);
    }

    case `${ADD_PICTURE}${ACTION_SUCCESS}`: {
      return store.setIn(
        ["pictures", payload.data.thumbnailUrl],
        Record({ ...payload.data, loadingUuid: params.loadingUuid })(),
      );
    }

    case `${ADD_PICTURE}${ACTION_FAIL}`: {
      return store.set("pictureErrorMessage", errors[0]?.message);
    }

    case `${DELETE_PICTURE}${ACTION_NO_DATA}`: {
      const filtered = store
        .get("pictures")
        .filter(picture => picture.get("id") !== params.pictureUuid);

      return store.set("pictures", filtered);
    }

    case LEAVE_PROCESS: {
      return initialState();
    }

    default: {
      return store;
    }
  }
};

export { ratingReducer, initialState };
