export enum PaymentAliasType {
  VIS = "VIS",
  ECA = "ECA",
  AMX = "AMX",
  TWI = "TWI",
}

export enum CREDIT_CARDS_PROVIDERS {
  VISA = "VISA",
  AMEX = "AMEX",
  MASTERCARD = "MASTERCARD",
  POSTEPAY = "POSTEPAY",
}

export interface PaymentAlias {
  type: PaymentAliasType;
  maskedNumber: string;
  expirationDate: string;
  uuid: string;
  default: boolean;
}

export enum PAYMENT_STATUS {
  PAID = "paid",
  CANCELLED = "cancelled",
  REFUSED = "refused",
  FAILED = "failed",
  AUTHORIZED = "authorized",
  PENDING = "pending",
}
