import i18next from "@config/i18next";

import { GenericErrorToastFactory } from "@containers/Toastify";

export const showGenericErrorToast = (customMessage?: string | null) => {
  const toast = GenericErrorToastFactory(
    i18next.t(customMessage || `batsoft_pwa_generic.something_went_wrong`),
    "batsoft-generic-error",
  );

  toast(customMessage);
};
