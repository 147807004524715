import { Record, List } from "immutable";
import { format } from "date-fns";

import { defaultValues } from "@config/soft.defaultValues";

import { PaymentAlias } from "@typings/payments";
import { Referral, BasicInfo, MoneyBalance } from "@typings/profile";

import { DATE_FORMAT } from "@services/DateFormatter";
import { stringifyFalsies } from "@services/StringifyFalsies";

import { BatsoftAccountBalance } from "./profile.typings";

export const referralInitialState = {
  referralCode: defaultValues.profile.referral.referralCode,
  referralUrl: defaultValues.profile.referral.referralUrl,
  shareFacebookUrl: defaultValues.profile.referral.shareFacebookUrl,
  shareTwitterUrl: defaultValues.profile.referral.shareTwitterUrl,
};

export const referralFactory = (input: Referral): Record<Referral> =>
  Record<Referral>(referralInitialState)(input);

const moneyBalanceInitialState = {
  balance: defaultValues.profile.accountBalance.moneyBalance.balance,
  currency: defaultValues.profile.accountBalance.moneyBalance.currency,
};

export const accountBalanceInitialState = {
  moneyBalance: Record<MoneyBalance>(moneyBalanceInitialState)(),
  durationBalance: defaultValues.profile.accountBalance.durationBalance,
};

export const accountBalanceFactory = (
  input: BatsoftAccountBalance,
): Record<BatsoftAccountBalance> => {
  const inputWithMoneyBalanceAsRecord = {
    durationBalance: input.durationBalance,
    moneyBalance: Record<MoneyBalance>(moneyBalanceInitialState)(
      input.moneyBalance,
    ),
  };
  return Record<BatsoftAccountBalance>(accountBalanceInitialState)(
    inputWithMoneyBalanceAsRecord,
  );
};

export const basicInfoInitialState = {
  firstName: defaultValues.profile.basicInfo.firstName,
  lastName: defaultValues.profile.basicInfo.lastName,
  email: defaultValues.profile.basicInfo.email,
  dateOfBirth: defaultValues.profile.basicInfo.dateOfBirth,
  homePhone: defaultValues.profile.basicInfo.homePhone,
  mobilePhone: defaultValues.profile.basicInfo.mobilePhone,
  oAuthProvider: defaultValues.profile.basicInfo.oAuthProvider,
  serviceVoucher: defaultValues.profile.basicInfo.serviceVoucher,
};

export const basicInfoFactory = (input: BasicInfo): Record<BasicInfo> =>
  Record<BasicInfo>(basicInfoInitialState)({
    ...input,
    dateOfBirth: input.dateOfBirth
      ? format(
          new Date(stringifyFalsies(input.dateOfBirth)),
          DATE_FORMAT.DAY_PICKER_INPUT,
        )
      : null,
  });

export const paymentAliasInitialState = {
  type: defaultValues.profile.paymentAliases.type,
  maskedNumber: defaultValues.profile.paymentAliases.maskedNumber,
  expirationDate: defaultValues.profile.paymentAliases.expirationDate,
  uuid: defaultValues.profile.paymentAliases.uuid,
  default: defaultValues.profile.paymentAliases.default,
};

const paymentAliasFactory = (
  paymentAlias: PaymentAlias,
): Record<PaymentAlias> =>
  Record(paymentAliasInitialState)({
    type: paymentAlias.type,
    maskedNumber: paymentAlias.maskedNumber,
    expirationDate: paymentAlias.expirationDate,
    uuid: paymentAlias.uuid,
    default: paymentAlias.default,
  });

export const paymentAliasesFactory = (
  paymentAliases: PaymentAlias[],
): List<Record<PaymentAlias>> => List(paymentAliases.map(paymentAliasFactory));

export const deletePaymentAliasFactory = (
  paymentAliases: List<Record<PaymentAlias>>,
  paymentAliasUuid: string,
): List<Record<PaymentAlias>> =>
  paymentAliases.filter(
    paymentAlias => paymentAlias.get("uuid") !== paymentAliasUuid,
  );

export const defaultPaymentAliasFactory = (
  paymentAliases: List<Record<PaymentAlias>>,
  paymentAliasUuid: string,
): List<Record<PaymentAlias>> =>
  paymentAliases.map(paymentAlias => {
    if (
      !paymentAlias.get("default") &&
      paymentAlias.get("uuid") === paymentAliasUuid
    ) {
      return paymentAlias.set("default", true);
    }
    return paymentAlias.set("default", false);
  });
