import { List, Record } from "immutable";

import { TITLE_OPTS } from "@typings/globals";
import { IAgent, IAgentRaw } from "@typings/booking";

import { defaultValues } from "@config/soft.defaultValues";

import {
  EmployeeBankAccount,
  EmergencyContact,
  EmployeeContactDetails,
  EmployeeJobAddress,
  EmployeePersonalInformation,
  JobFrequency,
  JOB_FREQUENCY,
  ContractTerm,
  CONTRACT_TERM,
  WORK_PERMIT,
  CIVIL_STATUS,
  AgentData,
} from "./declareEmployee.typings";

export const employeeContactDetailsFactory = (
  employeeContactDetails: EmployeeContactDetails,
): Record<EmployeeContactDetails> =>
  Record(employeeContactDetailsInitialState)({
    title: employeeContactDetails.title,
    firstName: employeeContactDetails.firstName,
    lastName: employeeContactDetails.lastName,
    streetAddress: employeeContactDetails.streetAddress,
    zipCode: employeeContactDetails.zipCode,
    mobilePhone: employeeContactDetails.mobilePhone,
    email: employeeContactDetails.email,
    city: employeeContactDetails.city,
    serviceLocationId: employeeContactDetails.serviceLocationId,
  });

export const employeeContactDetailsInitialState = {
  title: TITLE_OPTS.MS,
  firstName: "",
  lastName: "",
  streetAddress: "",
  zipCode: "",
  mobilePhone: "",
  email: "",
  city: "",
  serviceLocationId: 0,
};

export const employeeJobAddressFactory = (
  employeeJobAddress: EmployeeJobAddress,
): Record<EmployeeJobAddress> =>
  Record(employeeJobAddressInitialState)({
    address: employeeJobAddress.address,
    city: employeeJobAddress.city,
    zip: employeeJobAddress.zip,
    serviceLocationId: employeeJobAddress.serviceLocationId,
  });

export const employeeJobAddressInitialState = {
  address: "",
  city: "",
  zip: "",
  serviceLocationId: 0,
};

export const agentDataInitialState = defaultValues.declareEmployee.agentData;

export const agentDataFactory = (agentData: AgentData): Record<AgentData> =>
  Record(agentDataInitialState)({
    displayName: agentData.displayName,
    phoneNumber: agentData.phoneNumber,
  });

export const employeePersonalInformationFactory = (
  employeePersonalInformation: EmployeePersonalInformation,
): Record<EmployeePersonalInformation> =>
  Record(employeePersonalInformationInitialState)({
    dateOfBirth: employeePersonalInformation.dateOfBirth,
    workPermit: employeePersonalInformation.workPermit,
    avsNumber: employeePersonalInformation.avsNumber,
    civilStatus: employeePersonalInformation.civilStatus,
  });

export const employeePersonalInformationInitialState = {
  dateOfBirth: "",
  workPermit: WORK_PERMIT.NO_PERMIT,
  avsNumber: "",
  civilStatus: CIVIL_STATUS.SINGLE,
};

export const employeeBankAccountFactory = (
  employeeBankAccount: EmployeeBankAccount,
): Record<EmployeeBankAccount> =>
  Record(employeeBankAccountInitialState)({
    bankAccount: employeeBankAccount.bankAccount,
    bankAccountOwner: employeeBankAccount.bankAccountOwner,
    bankAccountStreetAddress: employeeBankAccount.bankAccountStreetAddress,
  });

export const employeeBankAccountInitialState = {
  bankAccount: "",
  bankAccountOwner: "",
  bankAccountStreetAddress: "",
};

export const emergencyContactFactory = (
  emergencyContact: EmergencyContact,
): Record<EmergencyContact> =>
  Record(emergencyContactInitialState)({
    name: emergencyContact.name,
    phoneNumber: emergencyContact.phoneNumber,
  });

export const emergencyContactInitialState = {
  name: "",
  phoneNumber: "",
};

export const jobFrequencyFactory = (
  jobFrequency: JobFrequency,
): Record<JobFrequency> =>
  Record(jobFrequencyInitialState)({
    frequency: jobFrequency.frequency,
    otherFrequencyDetails: jobFrequency.otherFrequencyDetails,
  });

export const jobFrequencyInitialState = {
  frequency: JOB_FREQUENCY.WEEKLY,
  otherFrequencyDetails: "",
};

export const contractTermFactory = (
  contractTerm: ContractTerm,
): Record<ContractTerm> =>
  Record(contractTermInitialState)({
    contractTerm: contractTerm.contractTerm,
    contractTermStartDate: contractTerm.contractTermStartDate,
    contractTermEndDate: contractTerm.contractTermEndDate,
    contractTermStartTime: contractTerm.contractTermStartTime,
  });

export const contractTermInitialState = {
  contractTerm: CONTRACT_TERM.FIXED,
  contractTermStartDate: "",
  contractTermEndDate: "",
  contractTermStartTime: "",
};

const agentFactory = (agent: IAgentRaw): Record<IAgent> =>
  Record({
    firstName: "",
    lastName: "",
    uuid: "",
    isPrevious: false,
    label: "",
    profilePictureUrl: "",
    phoneNumber: "",
  })({
    firstName: agent.firstName,
    lastName: agent.lastName,
    uuid: agent.uuid,
    isPrevious: agent.isPrevious,
    label: `${agent.firstName} ${agent.lastName}`,
    profilePictureUrl: agent.profilePictureUrl,
    phoneNumber: agent.phoneNumber,
  });

export const agentsFactory = (agents: IAgentRaw[]): List<Record<IAgent>> =>
  List(agents.map(agentFactory));
