import React, { ReactElement } from "react";
import styled from "styled-components";
import {
  ToastContainer as ReactToastifyToastContainer,
  ToastContainerProps,
  ToastOptions,
  TypeOptions as TypeOptionsToastify,
  toast,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  DESTROY_NOTIFICATIONS_AFTER_LONG,
  MOBILE_TOAST_OFFSET,
} from "@config/consts";

import theme from "@ui/themes/default";
import { TestWrapper } from "@ui/Atoms";

import { useScrollPosition } from "@hooks/useScrollPosition";
import { useBreakpoint } from "@hooks/useBreakpoint";

export type TypeOptions = TypeOptionsToastify;

export const showToast = (
  content: string | React.ReactElement,
  opts: ToastOptions,
): void => {
  if (opts.toastId) {
    toast(
      <TestWrapper testId={String(opts.toastId)}>
        <span>{content}</span>
      </TestWrapper>,
      opts,
    );
    return;
  }

  toast(content, opts);
};

export const hideToast = (id?: string): void => {
  toast.dismiss(id);
};

export const GenericErrorToastFactory =
  (fallbackMessage: string, toastId?: string) =>
  (customMessage?: string | null): void => {
    const message = customMessage || fallbackMessage;

    showToast(message, {
      type: "error",
      toastId,
    });
  };

export const ToastContainer = (props: ToastContainerProps): ReactElement => {
  const { mobile } = useBreakpoint();
  const { currPos } = useScrollPosition();

  return (
    <StyledContainer stickToTop={Math.abs(currPos.y) >= MOBILE_TOAST_OFFSET}>
      <ReactToastifyToastContainer
        position={mobile ? "top-center" : "top-right"}
        hideProgressBar
        autoClose={DESTROY_NOTIFICATIONS_AFTER_LONG}
        {...props}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ stickToTop: boolean }>`
  .Toastify__toast-container {
    transition: all 0.2s;
    top: ${props => (props.stickToTop ? 0 : theme.margins.base_x7)};

    ${props => props.theme.breakpoints.tablet} {
      top: ${theme.margins.base_x10};
    }
  }

  .Toastify__toast {
    padding: ${theme.margins.base_x2};
    border-radius: ${theme.buttons.borderRadius};
  }

  .Toastify__toast--dark {
    background: ${theme.colors.black500};
  }

  .Toastify__toast--error {
    background: ${theme.colors.red700};
  }

  .Toastify__close-button {
    margin-left: ${theme.margins.base};
  }
`;
