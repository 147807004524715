import { Map, Record } from "immutable";
import { AnyAction } from "redux";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import {
  FETCH_CONTRACT,
  FETCH_CONTRACTS,
  FETCH_EMPLOYEE,
} from "./contracts.actions";
import { ContractsReducer } from "./contracts.typings";
import {
  ContractsFactory,
  EmployeeFactory,
  EmployeesFactory,
  EmployeeDetailsFactory,
} from "./contracts.factories";

const initialState = Record<ContractsReducer>({
  contracts: Map(),
  employees: Map(),
});

const contractsReducer = (
  store: Record<ContractsReducer> = initialState(),
  action: AnyAction,
): Record<ContractsReducer> => {
  const { type, payload, params } = action;

  switch (type) {
    case `${FETCH_CONTRACTS}${ACTION_SUCCESS}`: {
      const { data } = payload;

      return store
        .set("contracts", ContractsFactory(data.contracts))
        .set("employees", EmployeesFactory(data.contracts));
    }
    case `${FETCH_CONTRACT}${ACTION_SUCCESS}`: {
      const { data } = payload;

      const currentContracts =
        store.get("contracts").size > 0
          ? store
              .get("contracts")
              .valueSeq()
              .map(contract => contract.toJS())
              .toArray()
          : [data];

      return store
        .setIn(["contracts", data.contractUuid], Record(data)())
        .setIn(
          ["employees", data.agentUuid],
          EmployeeFactory(data, currentContracts),
        );
    }
    case `${FETCH_EMPLOYEE}${ACTION_SUCCESS}`: {
      const { data } = payload;

      return store.setIn(
        ["employees", params.uuid, "employeeDetails"],
        EmployeeDetailsFactory(data),
      );
    }
    default: {
      return store;
    }
  }
};

export { contractsReducer };
