import { format, parse } from "date-fns";

import {
  PROFILE_PAYMENT_ALIASES_ENDPOINT,
  PROFILE_ACCOUNT_BALANCE_ENDPOINT,
  PROFILE_PASSWORD_ENDPOINT,
  REFERRAL_ENDPOINT,
  PROFILE_DELETE_PAYMENT_ALIAS_ENDPOINT,
  PROFILE_DEFAULT_PAYMENT_ALIAS_ENDPOINT,
  PROFILE_BASIC_INFO_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";
import { BasicInfo } from "@typings/profile";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { nullifyString } from "@services/NullifyString";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { DATE_FORMAT } from "@services/DateFormatter";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("profile");

export const FETCH_REFERRAL = action.make("fetch referral");
export const FETCH_ACCOUNT_BALANCE = action.make("fetch account balance");
export const FETCH_BASIC_INFO = action.make("fetch basic info");
export const SET_BASIC_INFO = action.make("set basic info");
export const FETCH_PAYMENT_ALIASES = action.make("fetch payment aliases");
export const DELETE_PAYMENT_ALIAS = action.make("delete payment alias");
export const ADD_PAYMENT_ALIAS = action.make("add payment alias");
export const SET_NEW_PASSWORD = action.make("set new password");
export const SET_DEFAULT_PAYMENT_ALIAS = action.make(
  "set default payment alias",
);

export const fetchAccountBalance =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_ACCOUNT_BALANCE_ENDPOINT,
      action: FETCH_ACCOUNT_BALANCE,
    });

    return dispatch(action);
  };

export const fetchReferral =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: REFERRAL_ENDPOINT,
      action: FETCH_REFERRAL,
    });

    return dispatch(action);
  };

export const fetchPaymentAliases =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_PAYMENT_ALIASES_ENDPOINT,
      action: FETCH_PAYMENT_ALIASES,
    });

    return dispatch(action);
  };

export const deletePaymentAlias =
  (paymentAliasUuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_DELETE_PAYMENT_ALIAS_ENDPOINT.replace(
        "{uuid}",
        paymentAliasUuid,
      ),
      action: DELETE_PAYMENT_ALIAS,
      method: ASYNC_ACTION_TYPES.DELETE,
      params: { paymentAliasUuid },
    });

    return dispatch(action);
  };

export const addPaymentAlias =
  (payload?: { returnUrl?: string }) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_PAYMENT_ALIASES_ENDPOINT,
      action: ADD_PAYMENT_ALIAS,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        ...(payload?.returnUrl && { returnUrl: payload.returnUrl }),
      },
    });

    return dispatch(action);
  };

export const setDefaultPaymentAlias =
  (paymentAliasUuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_DEFAULT_PAYMENT_ALIAS_ENDPOINT,
      action: SET_DEFAULT_PAYMENT_ALIAS,
      method: ASYNC_ACTION_TYPES.PUT,
      body: { paymentAliasUuid },
      params: { paymentAliasUuid },
    });

    return dispatch(action);
  };

export const fetchBasicInfo =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_BASIC_INFO_ENDPOINT,
      action: FETCH_BASIC_INFO,
    });

    return dispatch(action);
  };

export const setBasicInfo =
  (payload: Omit<BasicInfo, "oAuthProvider" | "serviceVoucher">) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const dateOfBirth = payload.dateOfBirth
      ? format(
          parse(payload.dateOfBirth, DATE_FORMAT.DAY_PICKER_INPUT, new Date()),
          DATE_FORMAT.YEAR_MONTH_DAY,
        )
      : null;

    const data = {
      ...payload,
      dateOfBirth,
      mobilePhone: nullifyString(payload.mobilePhone),
      homePhone: nullifyString(payload.homePhone),
    };

    const action = await asyncActionCreator({
      url: PROFILE_BASIC_INFO_ENDPOINT,
      action: SET_BASIC_INFO,
      method: ASYNC_ACTION_TYPES.PUT,
      body: data,
      params: data,
    });

    return dispatch(action);
  };

export const setNewPassword =
  (currentPassword: string, newPassword: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_PASSWORD_ENDPOINT,
      action: SET_NEW_PASSWORD,
      method: ASYNC_ACTION_TYPES.PUT,
      body: { currentPassword, newPassword },
    });

    return dispatch(action);
  };
