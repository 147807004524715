/**
 * Relative paths for react-router or hash navigation
 */
enum ROUTES {
  // Generic
  BASE = "/",
  NOT_FOUND = "/not-found",
  REDIRECTION = "/redirection",
  PRIVACY_POLICY = "/batmaid-privacy-policy",
  PRIVACY_POLICY_W_COUNTRY_SUFFIX = "/privacy-policy-{countrySuffix}",
  TERMS_OF_USE = "/batmaid-terms-of-use",
  TRUST_MANDATE = "/downloads/mandate_en.pdf",
  PAYMENT_PROCESSED = "/payment-is-being-processed",

  // Client dashboard
  CLIENT_DASHBOARD = "/client",
  CLIENT_DASHBOARD_MY_CLEANINGS = "/my-cleanings",
  CLIENT_DASHBOARD_BOOKINGS_TO_VALIDATE = "/bookings-to-validate",
  CLIENT_DASHBOARD_PROFILE = "/profile",
  CLIENT_DASHBOARD_OPTIONS = "/options",

  // Client dashboard - Rating
  CLIENT_DASHBOARD_MY_CLEANINGS_RATING = "/my-cleanings/rating",

  // Job cancellation
  JOB_CANCELLATION = "/:cancellationRoot/job-cancellation/:jobUuid",

  // Contract cancellation
  CONTRACT_CANCELLATION = "/contract/:contractUuid/cancel/:status?",

  AUTOMATIC_RESCHEDULE = "/automatic-reschedule/:jobUuid/:status?",

  AGENT_AUTO_REPLACED = "/agent-replacement/:jobUuid/:status?",

  // Client dashboard - Options
  CLIENT_DASHBOARD_OPTIONS_NOTIFICATIONS = "/notifications",
  CLIENT_DASHBOARD_OPTIONS_PETS = "/pets",
  CLIENT_DASHBOARD_OPTIONS_REPLACEMENT = "/replacement",
  CLIENT_DASHBOARD_OPTIONS_HOLIDAYS = "/holidays",
  CLIENT_DASHBOARD_OPTIONS_CLEANING_SUMMARY = "/cleaning-summary",

  // Client checklist
  CLIENT_CHECKLIST = "/checklist/:contractUuid/:variant",
  CLIENT_CHECKLIST_ONBOARDING = "/onboarding",
  CLIENT_CHECKLIST_LOADING = "/loading",
  CLIENT_CHECKLIST_FORM = "/form",
  CLIENT_CHECKLIST_THANK_YOU = "/thank-you",

  // Booking
  BOOKING = "/booking",
  BOOKING_CHECKOUT = "/booking/checkout",
  BOOKING_THANK_YOU = "/booking/thank-you",

  // End of Tenancy
  END_OF_TENANCY = "/end-of-tenancy",
  END_OF_TENANCY_CHECKOUT = "/end-of-tenancy/checkout",

  // B2B
  B2B = "/b2b",
  B2B_CHECKOUT = "/b2b/checkout",
  B2B_GET_A_QUOTE = "/b2b/get-a-quote",
  B2B_THANK_YOU = "/b2b/thank-you",

  // HR Application
  APPLY = "/apply",
  APPLY_PERSONAL_INFO = "/apply/personal-information",
  APPLY_EXPERIENCE_INFO = "/apply/experience-information",
  THANK_YOU_PAGE = "/apply/thank-you",

  // Mobile only
  SUPPORT = "/support",

  // Agent dashboard
  AGENT_DASHBOARD = "/agent",
  AGENT_SCHEDULE = "/agent/schedule",
  AGENT_PROFILE = "/agent/profile",
  AGENT_AVAILABILITIES = "/agent/availabilities",
  AGENT_MY_CLEANINGS = "/agent/my-cleanings",
  AGENT_NEW_CLEANINGS = "/agent/new-cleanings",
  AGENT_CHECKIN = "/checkin",
  AGENT_DECLINE_JOB = "/agent/decline-job/:jobUuid",

  // URSSAF
  URSSAF_REGISTRATION = "/urssaf-registration",

  DELETE_ACCOUNT = "/delete-account",
}

/**
 * Absolute paths of app deployment. Use when building hrefs
 */
enum PATHS {
  CLIENT_APP = "/client",
  AGENT_APP = "/agent",
}

// redirecting to proper app store
const APP_SELECTOR_ROUTE = "/mobile-app";

const pageViewBlacklistedRoutes: string[] = [
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_DASHBOARD_MY_CLEANINGS_RATING}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.JOB_CANCELLATION}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CONTRACT_CANCELLATION}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.AUTOMATIC_RESCHEDULE}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.AGENT_AUTO_REPLACED}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_DASHBOARD_MY_CLEANINGS}`,
];

const whiteBackgroundWhitelistedRoutes: string[] = [];

const focusModeWhitelistedRoutes: string[] = [
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_CHECKLIST}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_CHECKLIST}${ROUTES.CLIENT_CHECKLIST_ONBOARDING}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_CHECKLIST}${ROUTES.CLIENT_CHECKLIST_LOADING}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_CHECKLIST}${ROUTES.CLIENT_CHECKLIST_FORM}`,
  `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_CHECKLIST}${ROUTES.CLIENT_CHECKLIST_THANK_YOU}`,
];

export {
  ROUTES,
  PATHS,
  APP_SELECTOR_ROUTE,
  pageViewBlacklistedRoutes,
  whiteBackgroundWhitelistedRoutes,
  focusModeWhitelistedRoutes,
};
