import { IS_DEV } from "@config/consts";
import { ADMIN_DASHBOARD_LINK, AGENT_SCHEDULE_LINK } from "@config/links";
import { ROUTES } from "@config/app.routing";

import { MODALS } from "@typings/modals";
import { IOAuthState, OAUTH_PROVIDERS } from "@typings/oauth";
import { USER_TYPES } from "@typings/user";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { langFromPathname } from "@services/LangFromPathname";

import { useModalManager } from "@hooks/modal";
import { useAuthDispatch, useAuthSelector } from "@hooks/useAuth/redux";
import {
  connectUser,
  loginUserByAccessToken,
  setUserData,
} from "@hooks/useAuth/redux/oauth/oauth.actions";
import {
  disconnectOAuth as disconnectOAuthAction,
  fetchUserData,
  loginUser,
  logoutUser,
  recoverPassword,
} from "@hooks/useAuth/redux/user/user.actions";
import {
  allowTemporaryLogin as allowTemporaryLoginAction,
  disallowTemporaryLogin as disallowTemporaryLoginAction,
} from "@hooks/useAuth/redux/login/login.actions";
import { getIsTemporaryLoginAllowed } from "@hooks/useAuth/redux/login/login.selectors";

let onSuccessCallback: () => void = () => null;

interface SignInOptions {
  username: string;
  password: string;
  rememberMe?: boolean;

  /** use when only selected type of clients should be allowed to authenticate **/
  desiredUserType?: USER_TYPES;
}

const useAuthManager = () => {
  const language = langFromPathname();
  const dispatch = useAuthDispatch();
  const { showModal, hideModal, isModalActive } = useModalManager();
  const isTemporaryLoginAllowed = useAuthSelector(getIsTemporaryLoginAllowed);

  const runSuccessCallbackOnce = () => {
    onSuccessCallback();
    onSuccessCallback = () => null;
  };

  const signIn = async (payload: SignInOptions): Promise<SignInResult> => {
    const { username, password, rememberMe, desiredUserType } = payload;

    const result = await dispatch(
      loginUser({
        userType: desiredUserType,
        username,
        password,
        rememberMe,
      }),
    );

    if (desiredUserType && result.ok) {
      const actualUserType = result.payload.data.userType;

      if (actualUserType !== desiredUserType) {
        await dispatch(logoutUser());

        const errorKey = "incorrect_account_type_error";

        return {
          error: errorKey,
          ok: false,
          isErrorTranslated: false,
          apiResponseData: null,
          onError: (callback: (error: string) => void) => {
            callback(errorKey);
          },
          onSuccess: () => undefined,
        };
      }
    }

    await dispatch(fetchUserData());

    runSuccessCallbackOnce();

    if (result.ok) {
      resolvePostLoginRedirect(result.payload.data.userType);
    }

    return {
      error: result.errors?.[0]?.message || null,
      isErrorTranslated: true,
      ok: result.ok,
      apiResponseData: result.payload.data,
      onError: (callback: (error: string | null) => void) => {
        !result.ok && callback(result.errors?.[0]?.message || null);
      },
      onSuccess: (callback: () => void) => {
        result.ok && callback();
      },
    };
  };

  const signInByAccessToken = async (
    provider: OAUTH_PROVIDERS,
    accessToken: string,
    profile: { firstName: string; lastName: string; email: string },
  ): Promise<AsyncActionResult> => {
    const resp = await dispatch(loginUserByAccessToken(provider, accessToken));

    resp.onSuccess(async () => {
      setOauthUserData({
        provider,
        accessToken,
        ...profile,
      });

      await dispatch(fetchUserData());
      runSuccessCallbackOnce();
      resolvePostLoginRedirect(resp.payload.data.userType);
    });

    await dispatch(disallowTemporaryLoginAction());

    return resp;
  };

  const resolvePostLoginRedirect = (userType: USER_TYPES) => {
    const isClientApp = window.location.pathname.includes("/client");
    const isAgentApp = window.location.pathname.includes("/agent");

    /**
     * Do nothing in local dev environment, or when user logged in inside one of our apps.
     */
    if (IS_DEV || isClientApp || isAgentApp) return;

    /**
     * Admin logs in on home page or other static page.
     * Redirect to admin dashboard.
     */
    if (userType === USER_TYPES.ADMIN) {
      window.location.href = `/${language}${ADMIN_DASHBOARD_LINK}`;
      return;
    }

    /**
     * Client logs in on home page or other static page.
     * Redirect to client dashboard.
     */
    if (userType === USER_TYPES.CLIENT) {
      window.location.href = `/${language}${ROUTES.CLIENT_DASHBOARD}`;
      return;
    }

    /**
     * Agent logs in on home page or other static page.
     * Redirect to agent app in legacy frontend.
     */
    if (userType === USER_TYPES.AGENT) {
      window.location.href = `/${language}${AGENT_SCHEDULE_LINK}`;
      return;
    }
  };

  const restorePassword = (email: string): Promise<AsyncActionResult> => {
    return dispatch(recoverPassword(email));
  };

  const openLoginModal = () => {
    showModal(MODALS.NAVBAR_LOGIN);
  };

  const closeLoginModal = () => {
    hideModal(MODALS.NAVBAR_LOGIN);
  };

  const setOauthUserData = (payload: IOAuthState) => {
    dispatch(setUserData(payload));
  };

  const isLoginModalOpen = () => {
    return isModalActive(MODALS.NAVBAR_LOGIN);
  };

  const logout = () => {
    return dispatch(logoutUser());
  };

  const allowTemporaryLogin = () => {
    return dispatch(allowTemporaryLoginAction());
  };

  const disallowTemporaryLogin = () => {
    return dispatch(disallowTemporaryLoginAction());
  };

  const disconnectOAuth = (password: string) => {
    return dispatch(disconnectOAuthAction(password));
  };

  const connectOAuthUser = (
    provider: OAUTH_PROVIDERS,
    access_token: string,
  ) => {
    return dispatch(connectUser(provider, access_token));
  };

  const setOnSuccessCallback = (callback: () => void) => {
    onSuccessCallback = callback;
  };

  const reloadUserData = () => {
    return dispatch(fetchUserData());
  };

  return {
    signIn,
    signInByAccessToken,
    isLoginModalOpen,
    openLoginModal,
    closeLoginModal,
    restorePassword,
    setOauthUserData,
    logout,
    isTemporaryLoginAllowed,
    allowTemporaryLogin,
    disallowTemporaryLogin,
    setOnSuccessCallback,
    disconnectOAuth,
    reloadUserData,
    connectOAuthUser,
  };
};

interface SignInResult {
  ok: boolean;
  error: string | null;
  isErrorTranslated: boolean;
  onSuccess: (callback: () => void) => void;
  onError: (callback: (error: string | null) => void) => void;
  apiResponseData: any | null;
}

export { useAuthManager };
