import { AnyAction } from "redux";

import {
  AGENTS_LIST_ENDPOINT,
  CLIENT_DECLARE_YOUR_EMPLOYEE_ENDPOINT,
  HR_DECLARE_YOUR_EMPLOYEE_ENDPOINT,
  DECLARE_EMPLOYEE_SALARY_ENDPOINT,
  DECLARE_EMPLOYEE_WORK_PERMIT_ENDPOINT,
  DECLARE_EMPLOYEE_PAYMENT_ALIAS_ENDPOINT,
} from "@config/soft.endpoints";
import { VOUCHER_ENDPOINT } from "@config/endpoints";
import { MULTIPART_FORM_DATA } from "@config/contentTypes";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";
import { AGENTS_FILTER } from "@typings/booking";
import { EMPLOYEE_VACATIONS } from "@typings/declareEmployee";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";
import {
  setLocalStorageHelper,
  setFromLocalStorageHelper,
  deleteLocalStorageHelper,
} from "@soft/redux/helpers";

import { IStore } from "../reducers";

import {
  EmployeeContactDetails,
  EmployeeJobAddress,
  EmployeePersonalInformation,
  EmployeeBankAccount,
  EmergencyContact,
  JobFrequency,
  ContractTerm,
  PutSalaryPayload,
  HAS_CHILDREN,
  LOCAL_STORAGE_KEY,
  AgentData,
} from "./declareEmployee.typings";
import { getServiceLocationId } from "./declareEmployee.selectors";
import {
  getAsyncActionHRConfig,
  getDeclarationProcessEndpointRequest,
  getHRDeclareEmployeeEndpointRequest,
} from "./declareEmployee.repository";

const action = new ReduxActionNameCreator("declare employee");

export const SET_AGENT_UUID = action.make("set agent uuid");
export const SET_AGENT_DATA = action.make("set agent data");

export const SET_EMPLOYEE_CONTACT_DETAILS = action.make(
  "set employee contact details",
);
export const SET_EMPLOYEE_PERSONAL_INFORMATION = action.make(
  "set employee personal information",
);
export const SET_EMERGENCY_CONTACT = action.make("set emergency contact");
export const SET_JOB_DURATION = action.make("set job duration");
export const SET_EMPLOYEE_HAS_CHILDREN = action.make(
  "set employee has children",
);
export const SET_EMPLOYEE_CHILDREN_NUMBER = action.make(
  "set employee children number",
);
export const SET_EMPLOYEE_VACATIONS = action.make("set employee vacations");
export const SET_JOB_FREQUENCY = action.make("set job frequency");
export const SET_EMPLOYEE_BANK_ACCOUNT = action.make(
  "set employee bank account",
);

export const SET_EMPLOYEE_JOB_ADDRESS = action.make("set employee job address");
export const SET_EMPLOYEE_CONTACT_DETAILS_JOB_CITY = action.make(
  "set employee contact details job city",
);
export const SET_EMPLOYEE_CONTACT_DETAILS_JOB_SERVICE_LOCATION_ID = action.make(
  "set employee contact details job service location id",
);
export const SET_EMPLOYEE_JOB_CITY = action.make("set employee job city");
export const SET_JOB_SERVICE_LOCATION_ID = action.make(
  "set employee job service location id",
);
export const SET_CONTRACT_TERM = action.make("set contract term");

export const SUBMIT_HR_AGENT_DETAILS = action.make("fetch hr declare employee");
export const FETCH_CLIENT_DECLARE_EMPLOYEE = action.make(
  "fetch client declare employee",
);
export const PUT_SALARY = action.make("put salary");

export const FETCH_PREVIOUS_AGENTS = action.make("fetch previous agents");

export const UPLOAD_WORK_PERMIT = action.make("upload work permit");
export const ADD_VOUCHER = action.make("add voucher");
export const RESET_VOUCHER = action.make("reset voucher");

export const SET_PAYMENT_ALIAS_ID = action.make("set payment alias id");
export const PUT_PAYMENT_ALIAS = action.make("put payment alias");

export const RESET_STATE = action.make("reset state");

export const SET_IN_LOCAL_STORAGE = action.make("set in local storage");
export const SET_FROM_LOCAL_STORAGE = action.make("set from local storage");
export const DELETE_LOCAL_STORAGE = action.make("delete local storage");

export const setEmployeeContactDetails = (
  payload: EmployeeContactDetails,
): AnyAction => ({
  type: SET_EMPLOYEE_CONTACT_DETAILS,
  payload,
});

export const setEmployeeJobAddress = (
  payload: EmployeeJobAddress,
): AnyAction => ({
  type: SET_EMPLOYEE_JOB_ADDRESS,
  payload,
});

export const setEmployeePersonalInformation = (
  payload: EmployeePersonalInformation,
): AnyAction => ({
  type: SET_EMPLOYEE_PERSONAL_INFORMATION,
  payload,
});

export const setEmployeeContactDetailsJobCity = (
  payload: string,
): AnyAction => ({
  type: SET_EMPLOYEE_CONTACT_DETAILS_JOB_CITY,
  payload,
});

export const setContactDetailsServiceLocationId = (
  payload: number,
): AnyAction => ({
  type: SET_EMPLOYEE_CONTACT_DETAILS_JOB_SERVICE_LOCATION_ID,
  payload,
});

export const setEmployeeJobCity = (payload: string): AnyAction => ({
  type: SET_EMPLOYEE_JOB_CITY,
  payload,
});

export const setServiceLocationId = (payload: number): AnyAction => ({
  type: SET_JOB_SERVICE_LOCATION_ID,
  payload,
});

export const setEmergencyContact = (payload: EmergencyContact): AnyAction => ({
  type: SET_EMERGENCY_CONTACT,
  payload,
});

export const setJobDuration = (payload: number): AnyAction => ({
  type: SET_JOB_DURATION,
  payload,
});

export const setEmployeeHasChildren = (
  payload: HAS_CHILDREN | null,
): AnyAction => ({
  type: SET_EMPLOYEE_HAS_CHILDREN,
  payload,
});

export const setEmployeeChildrenNumber = (
  payload: number | null,
): AnyAction => ({
  type: SET_EMPLOYEE_CHILDREN_NUMBER,
  payload,
});

export const setEmployeeVacations = (
  payload: EMPLOYEE_VACATIONS,
): AnyAction => ({
  type: SET_EMPLOYEE_VACATIONS,
  payload,
});

export const setJobFrequency = (payload: JobFrequency): AnyAction => ({
  type: SET_JOB_FREQUENCY,
  payload,
});

export const setEmployeeBankAccount = (
  payload: EmployeeBankAccount,
): AnyAction => ({
  type: SET_EMPLOYEE_BANK_ACCOUNT,
  payload,
});

export const setContractTerm = (payload: ContractTerm): AnyAction => ({
  type: SET_CONTRACT_TERM,
  payload,
});

export const submitHRAgentDetails = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getHRDeclareEmployeeEndpointRequest(store);

  const action = await asyncActionCreator(
    {
      url: HR_DECLARE_YOUR_EMPLOYEE_ENDPOINT,
      action: SUBMIT_HR_AGENT_DETAILS,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    },
    getAsyncActionHRConfig(),
  );

  return dispatch(action);
};

export const createDeclarationProcess = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getDeclarationProcessEndpointRequest(store);

  const action = await asyncActionCreator({
    url: CLIENT_DECLARE_YOUR_EMPLOYEE_ENDPOINT,
    action: FETCH_CLIENT_DECLARE_EMPLOYEE,
    method: ASYNC_ACTION_TYPES.POST,
    body,
  });

  return dispatch(action);
};

export const fetchPreviousAgents = (filter: AGENTS_FILTER) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: AGENTS_LIST_ENDPOINT.replace("{filterValue}", filter).replace(
      "{serviceLocationIdValue}",
      String(getServiceLocationId(store())),
    ),
    action: FETCH_PREVIOUS_AGENTS,
  });

  return dispatch(action);
};

export const putSalary = (payload: PutSalaryPayload) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const declarationUuid = store().declareEmployee.get("declarationUuid");

  const action = await asyncActionCreator({
    url: DECLARE_EMPLOYEE_SALARY_ENDPOINT.replace(
      "{declarationUuidValue}",
      declarationUuid,
    ),
    action: PUT_SALARY,
    method: ASYNC_ACTION_TYPES.PUT,
    body: payload,
  });

  return dispatch(action);
};

// *** VOUCHER ***
export const addVoucher = (token: string) => async (
  dispatch: ThunkActionDispatch,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator({
    url: VOUCHER_ENDPOINT,
    action: ADD_VOUCHER,
    method: ASYNC_ACTION_TYPES.POST,
    body: {
      token,
    },
    params: {
      token,
    },
  });

  return dispatch(action);
};

export const resetVoucher = (): AnyAction => ({
  type: RESET_VOUCHER,
});

export const uploadWorkPermit = (
  file: File,
  declarationUuidFromQuery: string,
) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const declarationUuid =
    store().declareEmployee.get("declarationUuid") || declarationUuidFromQuery;

  const formData = new FormData();
  formData.append("file", file);

  const action = await asyncActionCreator(
    {
      url: DECLARE_EMPLOYEE_WORK_PERMIT_ENDPOINT.replace(
        "{declarationUuidValue}",
        declarationUuid,
      ),
      action: UPLOAD_WORK_PERMIT,
      method: ASYNC_ACTION_TYPES.POST,
      formData,
    },
    {
      headers: {
        "Content-Type": MULTIPART_FORM_DATA,
      },
    },
  );

  return dispatch(action);
};

export const setAgentUuid = (payload: string): AnyAction => ({
  type: SET_AGENT_UUID,
  payload,
});

export const setAgentData = (payload: AgentData): AnyAction => ({
  type: SET_AGENT_DATA,
  payload,
});

export const putPaymentAlias = (paymentAliasId: string) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const declarationUuid = store().declareEmployee.get("declarationUuid");

  const action = await asyncActionCreator({
    url: DECLARE_EMPLOYEE_PAYMENT_ALIAS_ENDPOINT.replace(
      "{declarationUuidValue}",
      declarationUuid,
    ),
    action: PUT_PAYMENT_ALIAS,
    method: ASYNC_ACTION_TYPES.PUT,
    body: {
      paymentAliasId,
    },
  });

  return dispatch(action);
};

export const setPaymentAliasId = (payload: string): AnyAction => ({
  type: SET_PAYMENT_ALIAS_ID,
  payload,
});

export const resetState = (): AnyAction => ({
  type: RESET_STATE,
});

export const setLocalStorage = () => (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): void => {
  setLocalStorageHelper(
    dispatch,
    SET_IN_LOCAL_STORAGE,
    store().declareEmployee,
    LOCAL_STORAGE_KEY,
  );
};

export const setFromLocalStorage = () => (
  dispatch: ThunkActionDispatch,
): void => {
  setFromLocalStorageHelper(
    dispatch,
    SET_FROM_LOCAL_STORAGE,
    LOCAL_STORAGE_KEY,
  );
};

export const deleteLocalStorage = () => (
  dispatch: ThunkActionDispatch,
): void => {
  deleteLocalStorageHelper(dispatch, DELETE_LOCAL_STORAGE, LOCAL_STORAGE_KEY);
};
