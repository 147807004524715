import * as React from "react";

import * as HTTP_CODE from "@config/httpStatuses";
import {
  defaultFeatureTogglesState,
  FeatureToggleState,
  FeatureTogglesSaved,
} from "@config/featureToggles";
import { IS_DEV, FEATURE_TOGGLES_LOCAL_STORAGE_KEY } from "@config/consts";
import { FEATURE_TOGGLES_ENDPOINT } from "@config/endpoints";

import { LOADING_STATE } from "@typings/reduxThunkActions";

import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import { fetchFacade } from "@services/FetchFacade";
import { localStorageFacade } from "@services/LocalStorageFacade";

import { useTelemetry } from "@hooks/useTelemetry";

enum FEATURE_TOGGLE_ORIGIN {
  DEFAULT = "DEFAULT",
  BUILT = "BUILT",
  STORAGE = "STORAGE",
  API = "API",
}

interface ToggleContext {
  config: FeatureToggleState | null;
  origin: FEATURE_TOGGLE_ORIGIN;
  status: LOADING_STATE;
}

const FeatureToggleContext = React.createContext<ToggleContext>({
  origin: FEATURE_TOGGLE_ORIGIN.DEFAULT,
  status: LOADING_STATE.IDLE,
  config: null,
});

interface Props {
  children: React.ReactNode;
  builtConfig?: FeatureTogglesSaved; // fetched FT during a build process
  disableLocalStorageData?: boolean;
}

const FETCH_URL = `${getApiUrl()}${FEATURE_TOGGLES_ENDPOINT}`;

const FeatureToggleProvider = (props: Props): React.ReactElement => {
  const localStorage = localStorageFacade();
  const storagedConfig = React.useMemo<FeatureTogglesSaved | null>(() => {
    if (props.disableLocalStorageData) return null;

    const data = localStorage.getItem(FEATURE_TOGGLES_LOCAL_STORAGE_KEY);
    if (data) {
      return JSON.parse(data);
    }

    return null;
  }, []);

  const originAndConfig = React.useMemo(() => {
    if (
      storagedConfig &&
      !!Object.keys(storagedConfig.data).length &&
      (!props.builtConfig ||
        !Object.keys(props.builtConfig.data).length ||
        storagedConfig.updated > props.builtConfig.updated)
    ) {
      return {
        origin: FEATURE_TOGGLE_ORIGIN.STORAGE,
        config: storagedConfig.data as FeatureToggleState,
      };
    }

    if (props.builtConfig && !!Object.keys(props.builtConfig.data).length) {
      return {
        origin: FEATURE_TOGGLE_ORIGIN.BUILT,
        config: props.builtConfig.data as FeatureToggleState,
      };
    }

    return {
      origin: FEATURE_TOGGLE_ORIGIN.DEFAULT,
      config: defaultFeatureTogglesState,
    };
  }, [storagedConfig, props.builtConfig]);

  const [origin, setOrigin] = React.useState<FEATURE_TOGGLE_ORIGIN>(
    () => originAndConfig.origin,
  );
  const [status, setStatus] = React.useState<LOADING_STATE>(LOADING_STATE.IDLE);
  const [config, setConfig] = React.useState<FeatureToggleState>(() => ({
    ...defaultFeatureTogglesState,
    ...originAndConfig.config,
  }));
  const { setFeatureFlags, enable } = useTelemetry();

  React.useEffect(() => {
    (async () => {
      try {
        setStatus(LOADING_STATE.LOADING);

        const response = await fetchFacade(FETCH_URL);

        if (response.status === HTTP_CODE.OK) {
          const newState = response?.data as FeatureToggleState;
          const newStorageState: FeatureTogglesSaved = {
            data: newState,
            updated: ((): number => {
              const now = new Date();

              // Brackets are important in this calculation
              // prettier-ignore
              return now.getTime() - (now.getTimezoneOffset() * 60 * 1000);
            })(),
          };

          localStorage.setItem(
            FEATURE_TOGGLES_LOCAL_STORAGE_KEY,
            JSON.stringify(newStorageState),
          );

          setOrigin(FEATURE_TOGGLE_ORIGIN.API);
          setStatus(LOADING_STATE.SUCCEEDED);
          setConfig(newState);
          setFeatureFlags(newState);
          newState.telemetry && enable();
        }
      } catch {
        setStatus(LOADING_STATE.FAILED);

        if (IS_DEV) {
          console.error(
            `Can't fetch feature toggles. Initializing with fallback values.`,
          );
        }
      }
    })();
  }, []);

  return (
    <FeatureToggleContext.Provider value={{ config, origin, status }}>
      {props.children}
    </FeatureToggleContext.Provider>
  );
};

interface TestProps {
  value?: Partial<FeatureToggleState>;
  children: React.ReactElement;
}

/**
 * Use `FeatureToggleTestProvider` for mocking FeatureToggleProvider.
 * You can mock particular feature toggle state using `value` property,
 * e.g. `value={myFeature: true}` to set `myFeature` to true
 */
const FeatureToggleTestProvider = (props: TestProps): React.ReactElement => (
  <FeatureToggleContext.Provider
    value={{
      config: (props.value || {}) as FeatureToggleState,
      origin: FEATURE_TOGGLE_ORIGIN.API,
      status: LOADING_STATE.SUCCEEDED,
    }}
  >
    {props.children}
  </FeatureToggleContext.Provider>
);

function useFeatureToggle(name: keyof FeatureToggleState): boolean {
  const context = React.useContext(FeatureToggleContext);

  if (context.config === null) {
    throw new Error(
      "useFeatureToggle must be used within FeatureToggleProvider",
    );
  }

  if (!(name in context.config)) {
    return defaultFeatureTogglesState[name];
  }

  return context.config[name];
}

function useFeatureToggleManager(): Omit<ToggleContext, "config"> {
  const { config, ...context } = React.useContext(FeatureToggleContext);

  if (config === null) {
    throw new Error(
      "useFeatureToggleManager must be used within FeatureToggleProvider",
    );
  }

  return {
    ...context,
  };
}

export {
  FEATURE_TOGGLE_ORIGIN,
  FeatureToggleProvider,
  FeatureToggleTestProvider,
  useFeatureToggle,
  useFeatureToggleManager,
};
