import { List, Record } from "immutable";
import { AnyAction } from "redux";

import { defaultValues } from "@config/soft.defaultValues";

import { PROMO_CODE_NOTIFICATION } from "@typings/globals";

import {
  ACTION_FAIL,
  ACTION_SUCCESS,
} from "@services/AsyncActionCreatorFactory";

import {
  DeclareEmployeeReducer,
  DECLARE_EMPLOYEE_SERVICE,
} from "./declareEmployee.typings";
import {
  employeeContactDetailsFactory,
  employeeJobAddressFactory,
  employeeContactDetailsInitialState,
  emergencyContactInitialState,
  emergencyContactFactory,
  employeePersonalInformationInitialState,
  employeePersonalInformationFactory,
  jobFrequencyFactory,
  jobFrequencyInitialState,
  employeeBankAccountFactory,
  employeeBankAccountInitialState,
  employeeJobAddressInitialState,
  contractTermInitialState,
  contractTermFactory,
  agentsFactory,
  agentDataInitialState,
  agentDataFactory,
} from "./declareEmployee.factories";
import {
  SET_EMPLOYEE_BANK_ACCOUNT,
  SET_EMERGENCY_CONTACT,
  SET_EMPLOYEE_CHILDREN_NUMBER,
  SET_EMPLOYEE_HAS_CHILDREN,
  SET_EMPLOYEE_CONTACT_DETAILS,
  SET_EMPLOYEE_PERSONAL_INFORMATION,
  SET_JOB_DURATION,
  SET_EMPLOYEE_VACATIONS,
  SET_JOB_FREQUENCY,
  SET_EMPLOYEE_JOB_ADDRESS,
  SET_EMPLOYEE_JOB_CITY,
  SET_JOB_SERVICE_LOCATION_ID,
  FETCH_PREVIOUS_AGENTS,
  SET_CONTRACT_TERM,
  UPLOAD_WORK_PERMIT,
  SET_EMPLOYEE_CONTACT_DETAILS_JOB_CITY,
  SET_EMPLOYEE_CONTACT_DETAILS_JOB_SERVICE_LOCATION_ID,
  ADD_VOUCHER,
  RESET_VOUCHER,
  FETCH_CLIENT_DECLARE_EMPLOYEE,
  SET_AGENT_UUID,
  SUBMIT_HR_AGENT_DETAILS,
  SET_PAYMENT_ALIAS_ID,
  SET_FROM_LOCAL_STORAGE,
  RESET_STATE,
  SET_AGENT_DATA,
  PUT_SALARY,
} from "./declareEmployee.actions";

const initialState = Record<DeclareEmployeeReducer>({
  agentUuid: defaultValues.declareEmployee.agentUuid,
  agentData: Record(agentDataInitialState)(),
  employeeJobAddress: Record(employeeJobAddressInitialState)(),
  employeeContactDetails: Record(employeeContactDetailsInitialState)(),
  employeePersonalInformation: Record(
    employeePersonalInformationInitialState,
  )(),
  emergencyContact: Record(emergencyContactInitialState)(),
  jobDuration: defaultValues.declareEmployee.jobTime.min,
  employeeHasChildren: null,
  employeeChildrenNumber: null,
  vacations: defaultValues.declareEmployee.vacations,
  jobFrequency: Record(jobFrequencyInitialState)(),
  employeeBankAccount: Record(employeeBankAccountInitialState)(),
  contractTerm: Record(contractTermInitialState)(),
  service: DECLARE_EMPLOYEE_SERVICE.HOME_CLEANING,
  previousAgents: List(),
  declarationUuid: defaultValues.declareEmployee.declarationUuid,
  voucherToken: defaultValues.declareEmployee.voucherToken,
  voucherStatus: defaultValues.declareEmployee.voucherStatus,
  voucherMessage: defaultValues.declareEmployee.voucherMessage,
  paymentAliasId: defaultValues.declareEmployee.paymentAliasId,
  isSalaryChosen: defaultValues.declareEmployee.isSalaryChosen,
});

const declareEmployeeReducer = (
  store: Record<DeclareEmployeeReducer> = initialState(),
  action: AnyAction,
): Record<DeclareEmployeeReducer> => {
  const { type, payload, params } = action;

  switch (type) {
    case SET_EMPLOYEE_CONTACT_DETAILS: {
      return store.set(
        "employeeContactDetails",
        employeeContactDetailsFactory(payload),
      );
    }
    case SET_EMPLOYEE_JOB_ADDRESS: {
      return store.set(
        "employeeJobAddress",
        employeeJobAddressFactory(payload),
      );
    }
    case SET_EMPLOYEE_CONTACT_DETAILS_JOB_CITY: {
      return store.setIn(["employeeContactDetails", "city"], payload);
    }
    case SET_EMPLOYEE_CONTACT_DETAILS_JOB_SERVICE_LOCATION_ID: {
      return store.setIn(
        ["employeeContactDetails", "serviceLocationId"],
        payload,
      );
    }
    case SET_EMPLOYEE_JOB_CITY: {
      return store.setIn(["employeeJobAddress", "city"], payload);
    }
    case SET_JOB_SERVICE_LOCATION_ID: {
      return store.setIn(["employeeJobAddress", "serviceLocationId"], payload);
    }
    case SET_EMPLOYEE_PERSONAL_INFORMATION: {
      return store.set(
        "employeePersonalInformation",
        employeePersonalInformationFactory(payload),
      );
    }
    case SET_EMERGENCY_CONTACT: {
      return store.set("emergencyContact", emergencyContactFactory(payload));
    }
    case SET_JOB_DURATION: {
      return store.set("jobDuration", payload);
    }
    case SET_EMPLOYEE_CHILDREN_NUMBER: {
      return store.set("employeeChildrenNumber", payload);
    }
    case SET_EMPLOYEE_HAS_CHILDREN: {
      return store.set("employeeHasChildren", payload);
    }
    case SET_EMPLOYEE_VACATIONS: {
      return store.set("vacations", payload);
    }
    case SET_JOB_FREQUENCY: {
      return store.set("jobFrequency", jobFrequencyFactory(payload));
    }
    case SET_EMPLOYEE_BANK_ACCOUNT: {
      return store.set(
        "employeeBankAccount",
        employeeBankAccountFactory(payload),
      );
    }
    case `${FETCH_PREVIOUS_AGENTS}${ACTION_SUCCESS}`: {
      return store.set("previousAgents", agentsFactory(payload.data));
    }
    case SET_CONTRACT_TERM: {
      return store.set("contractTerm", contractTermFactory(payload));
    }
    case `${UPLOAD_WORK_PERMIT}${ACTION_SUCCESS}`: {
      return store;
    }
    case `${FETCH_CLIENT_DECLARE_EMPLOYEE}${ACTION_SUCCESS}`: {
      return store.set("declarationUuid", payload.data.declarationUuid);
    }
    case `${ADD_VOUCHER}${ACTION_SUCCESS}`: {
      return store
        .set("voucherToken", params.token)
        .set("voucherMessage", payload.data.translatedMessage)
        .set("voucherStatus", PROMO_CODE_NOTIFICATION.SUCCESS);
    }
    case `${ADD_VOUCHER}${ACTION_FAIL}`: {
      return store
        .set("voucherToken", defaultValues.declareEmployee.voucherToken)
        .set("voucherMessage", action.translatedErrorMessage)
        .set("voucherStatus", PROMO_CODE_NOTIFICATION.FAILURE);
    }
    case RESET_VOUCHER: {
      return store
        .set("voucherToken", defaultValues.declareEmployee.voucherToken)
        .set("voucherMessage", defaultValues.declareEmployee.voucherMessage)
        .set("voucherStatus", defaultValues.declareEmployee.voucherStatus);
    }
    case SET_AGENT_UUID: {
      return store.set("agentUuid", payload);
    }
    case SET_AGENT_DATA: {
      return store.set("agentData", agentDataFactory(payload));
    }
    case `${SUBMIT_HR_AGENT_DETAILS}${ACTION_SUCCESS}`: {
      return store.set("agentUuid", payload.data.uuid);
    }
    case SET_PAYMENT_ALIAS_ID: {
      return store.set("paymentAliasId", payload);
    }
    case `${PUT_SALARY}${ACTION_SUCCESS}`: {
      return store.set("isSalaryChosen", true);
    }
    case RESET_STATE: {
      return initialState();
    }
    case SET_FROM_LOCAL_STORAGE: {
      return action.payload || store;
    }
    default: {
      return store;
    }
  }
};

export { declareEmployeeReducer };
