import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Eye = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        viewBox="0 0 24 18"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          ...style(props.size, props.height),
          ...props.style,
          verticalAlign: "initial",
        }}
        className={props.className}
      >
        <g
          stroke={props.color || theme.colors.grey700}
          strokeWidth={2}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12.0003 1c-6.074 0-11 6.667-11 8 0 1.333 4.926 8 11 8s11-6.667 11-8c0-1.333-4.926-8-11-8z" />
          <path d="M12.0003 12c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
        </g>
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        d="M12.001.001c-6.627 0-12 7.5-12 9s5.373 9 12 9 12-7.5 12-9-5.373-9-12-9zm0 13c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"
        fillRule="nonzero"
        fill={props.color || theme.colors.grey700}
      />
    </svg>
  );
};
