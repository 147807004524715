import { Map, Record } from "immutable";
import { AnyAction } from "redux";

import {
  ACTION_FAIL,
  ACTION_SUCCESS,
} from "@services/AsyncActionCreatorFactory";

import {
  AGENT_COMPENSATION_TYPE,
  Holiday,
  HolidayReducer,
} from "./holidays.typings";
import {
  DELETE_HOLIDAY,
  FETCH_HOLIDAYS,
  INIT_PROCESSED_HOLIDAY,
  UPDATE_PROCESSED_HOLIDAY,
  CLEAR_PROCESSED_HOLIDAY,
} from "./holidays.actions";

import { defaultValues } from "@config/soft.defaultValues";

export const initialHoliday = Record<Holiday>({
  id: "",
  startDate: "",
  endDate: "",
  skipCleanings: true,
  compensateAgent: false,
  agentCompensationAmount: 0,
  agentCompensationType: AGENT_COMPENSATION_TYPE.FULL,
  visible: true,
  calendarStartDate: "",
});

const initialState = Record({
  holidays: Map<string, Record<Holiday>>(),
  processedHoliday: null,
});

const holidaysReducer = (
  store: HolidayReducer = initialState(),
  action: AnyAction,
): HolidayReducer => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_HOLIDAYS}${ACTION_SUCCESS}`: {
      const {
        data: { holidays },
      } = payload;

      const holidaysMap = holidays.reduce(
        (acc: Map<string, Record<Holiday>>, holiday: Holiday) =>
          acc.set(
            holiday.id,
            initialHoliday({
              ...holiday,
              startDate: holiday.startDate.replace(" ", "T"),
              endDate: holiday.endDate.replace(" ", "T"),
            }),
          ),
        Map(),
      );

      return store.set("holidays", holidaysMap);
    }

    case `${FETCH_HOLIDAYS}${ACTION_FAIL}`: {
      return store;
    }

    case `${DELETE_HOLIDAY}${ACTION_SUCCESS}`: {
      const { id } = action.params;

      if (id) {
        return store.set(
          "holidays",
          store
            .get("holidays")
            .filter((item: Record<Holiday>) => item.get("id") !== id),
        );
      }

      return store;
    }

    case INIT_PROCESSED_HOLIDAY: {
      return store.set("processedHoliday", initialHoliday());
    }

    case UPDATE_PROCESSED_HOLIDAY: {
      return store.mergeIn(["processedHoliday"], payload);
    }

    case CLEAR_PROCESSED_HOLIDAY: {
      return store.set("processedHoliday", null);
    }

    default: {
      return store;
    }
  }
};

export { holidaysReducer };
