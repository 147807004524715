import { List, Record } from "immutable";

export enum SECOND_PILLAR_RETIREMENT {
  NONE = "NONE",
  FIFTY_FIFTY = "FIFTY_FIFTY",
  ONE_HUNDRED_PERCENT_EMPLOYER = "100_PERCENT_EMPLOYER",
}

export enum SICKNESS_INSURANCE {
  FIFTY_FIFTY = "FIFTY_FIFTY",
  ONE_HUNDRED_PERCENT_EMPLOYER = "100_PERCENT_EMPLOYER",
}

export interface SecondPillarRetirementCosts {
  none: number;
  fiftyFifty: number;
  fullCoverageByEmployer: number;
}

export interface SicknessInsuranceCosts {
  fiftyFifty: number;
  fullCoverageByEmployer: number;
}

export interface SalaryDefaults {
  minimumSuperGrossSalary: number;
  minimumNetSalary: number;
  defaultSuperGrossSalary: number;
  maximumSuperGrossSalary: number;
  secondPillarRetirementNone: number;
  secondPillarRetirementFiftyFifty: number;
  secondPillarRetirementFullCoverageByEmployer: number;
  sicknessInsuranceFiftyFifty: number;
  sicknessInsuranceFullCoverageByEmployer: number;
  relaxPlanHourlyFee: number;
  takeAndLeaveFee: number;
}

export interface ContributionsItemRaw {
  shortName: string;
  value: number;
  longDescription?: string;
}

export interface ContributionsItem {
  shortName: string;
  value: number;
  longDescription?: string;
}

export interface BookingSalaryRaw {
  superGrossSalary: number;
  grossSalaryIncludingVacation: number;
  netSalary: number;
  holidayAllowance: number;
  holidayAllowancePercent: number;
  employerContributions: ContributionsItemRaw[];
  employeeContributions: ContributionsItemRaw[];
}

export interface BookingSalary {
  superGrossSalary: number;
  grossSalaryIncludingVacation: number;
  netSalary: number;
  holidayAllowance: number;
  holidayAllowancePercent: number;
  employerContributions: List<Record<ContributionsItem>>;
  employerContributionsTotal: number;
  employeeContributions: List<Record<ContributionsItem>>;
  employeeContributionsTotal: number;
}
