import { Record, List, Map } from "immutable";
import { AnyAction } from "redux";

import { defaultValues } from "@config/soft.defaultValues";

import { PROMO_CODE_NOTIFICATION, PAYMENT_METHODS } from "@typings/globals";

import {
  ACTION_SUCCESS,
  ACTION_FAIL,
} from "@services/AsyncActionCreatorFactory";

import {
  SET_PAYMENT_METHOD,
  FETCH_PAYMENT_METHODS,
  SET_LEGAL_ADDRESS,
  SET_LEGAL_FLOOR_AND_DOOR,
  SET_LEGAL_CITY,
  SET_LEGAL_ZIP,
  SET_LEGAL_SERVICE_LOCATION_ID,
  SET_FROM_LOCAL_STORAGE,
  FETCH_VOUCHER,
  RESET_VOUCHER,
  FETCH_JOBS_TO_VALIDATE,
  PUT_LEGAL_LOCATION,
  SET_IS_LEGAL_LOCATION_THE_SAME,
} from "./bookingsToValidate.actions";
import {
  BookingsToValidateReducer,
  LegalLocation,
} from "./bookingsToValidate.typings";
import {
  JobsToValidateFactory,
  legalLocationInitialState,
} from "./bookingsToValidate.factories";

const initialState = Record<BookingsToValidateReducer>({
  availablePaymentMethods: List(),
  selectedPaymentMethod: defaultValues.bookingsToValidate.selectedPaymentMethod,
  isLegalLocationTheSame:
    defaultValues.bookingsToValidate.isLegalLocationTheSame,
  legalLocation: Record<LegalLocation>(legalLocationInitialState)(),
  isLegalLocationPut: defaultValues.bookingsToValidate.isLegalLocationPut,
  voucherToken: defaultValues.bookingsToValidate.voucherToken,
  voucherStatus: defaultValues.bookingsToValidate.voucherStatus,
  voucherMessage: defaultValues.bookingsToValidate.voucherMessage,
  jobs: Map(),
});

const bookingsToValidateReducer = (
  store: Record<BookingsToValidateReducer> = initialState(),
  action: AnyAction,
): Record<BookingsToValidateReducer> => {
  const { type, payload, params } = action;

  switch (type) {
    case `${FETCH_JOBS_TO_VALIDATE}${ACTION_SUCCESS}`: {
      return store.set("jobs", JobsToValidateFactory(payload.data));
    }
    case SET_FROM_LOCAL_STORAGE: {
      return action.payload || store;
    }
    case `${FETCH_PAYMENT_METHODS}${ACTION_SUCCESS}`: {
      return store.set(
        "availablePaymentMethods",
        List<PAYMENT_METHODS>(payload.data),
      );
    }
    case SET_PAYMENT_METHOD: {
      return store.set("selectedPaymentMethod", payload);
    }
    case SET_IS_LEGAL_LOCATION_THE_SAME: {
      return store.set("isLegalLocationTheSame", payload);
    }
    case SET_LEGAL_ADDRESS: {
      return store.setIn(["legalLocation", "address"], payload);
    }
    case SET_LEGAL_FLOOR_AND_DOOR: {
      return store.setIn(["legalLocation", "floorAndDoor"], payload);
    }
    case SET_LEGAL_CITY: {
      return store.setIn(["legalLocation", "city"], payload);
    }
    case SET_LEGAL_ZIP: {
      return store.setIn(["legalLocation", "zip"], payload);
    }
    case SET_LEGAL_SERVICE_LOCATION_ID: {
      return store.setIn(["legalLocation", "serviceLocationId"], payload);
    }
    case `${FETCH_VOUCHER}${ACTION_SUCCESS}`: {
      return store
        .set("voucherToken", params.voucherToken)
        .set("voucherMessage", payload.data.discountMessage)
        .set("voucherStatus", PROMO_CODE_NOTIFICATION.SUCCESS);
    }
    case `${FETCH_VOUCHER}${ACTION_FAIL}`: {
      return store
        .set("voucherToken", defaultValues.bookingsToValidate.voucherToken)
        .set("voucherMessage", action.translatedErrorMessage || null)
        .set("voucherStatus", PROMO_CODE_NOTIFICATION.FAILURE);
    }
    case RESET_VOUCHER: {
      return store
        .set("voucherToken", defaultValues.bookingsToValidate.voucherToken)
        .set("voucherMessage", defaultValues.bookingsToValidate.voucherMessage)
        .set("voucherStatus", defaultValues.bookingsToValidate.voucherStatus);
    }
    case `${PUT_LEGAL_LOCATION}${ACTION_SUCCESS}`: {
      return store.set("isLegalLocationPut", true);
    }
    default: {
      return store;
    }
  }
};

export { bookingsToValidateReducer };
