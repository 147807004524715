import { Record, Map, List } from "immutable";
import { AnyAction } from "redux";

import { SingleOption, UserOptionRaw } from "@typings/userOptions";

import {
  ACTION_FAIL,
  ACTION_SUCCESS,
} from "@services/AsyncActionCreatorFactory";

import {
  SAVE_OPTION,
  FETCH_OPTIONS,
  FETCH_TRUST_MANDATE_2020_URLS,
  SET_HAS_SIGNED_TRUST_MANDATE_IN_DOCUSIGN,
} from "./userOptions.actions";
import { TrustMandate2020UrlsFactory } from "./userOptions.factories";
import { IUserOptions } from "./userOptions.typings";

export type UserOptionsReducer = Record<IUserOptions>;

const initialState = Record<IUserOptions>({
  options: Map(),
  requireTrustMandate: false,
  trustMandateTemplateFileUrl: "",
  trustMandateUploadUrl: "",
  trustMandate2020Urls: TrustMandate2020UrlsFactory([]),
  trustMandateUrlsFetched: false,
  hasSignedTrustMandateInDocusign: false,
});

const initialSingleOption = Record<SingleOption>({
  key: "",
  value: "",
  allowedValues: List(),
});

const userOptionsReducer = (
  store: UserOptionsReducer = new initialState(),
  action: AnyAction,
): Record<IUserOptions> => {
  const { type, params, payload } = action;

  switch (type) {
    case `${FETCH_OPTIONS}${ACTION_SUCCESS}`: {
      const userOptions = payload.data;

      if (typeof userOptions.options === "undefined") {
        return store;
      }

      const newOptions = userOptions.options.reduce(
        (acc: Map<string, SingleOption>, opt: UserOptionRaw) => {
          return acc.set(
            opt.key,
            initialSingleOption({
              ...opt,
              allowedValues: List(opt.allowedValues),
            }),
          );
        },
        Map(),
      );

      return store.merge({
        ...userOptions,
        options: newOptions,
      });
    }

    case `${FETCH_OPTIONS}${ACTION_FAIL}`: {
      return store;
    }

    case `${SAVE_OPTION}${ACTION_SUCCESS}`: {
      const { option, value } = params;
      return store.setIn(["options", option, "value"], value);
    }

    case `${SAVE_OPTION}${ACTION_FAIL}`: {
      return store;
    }

    case `${FETCH_TRUST_MANDATE_2020_URLS}${ACTION_SUCCESS}`: {
      return store
        .mergeIn(
          ["trustMandate2020Urls"],
          TrustMandate2020UrlsFactory(
            payload.data.docuSignTrustMandateUrl.urls,
          ),
        )
        .set("trustMandateUrlsFetched", true);
    }

    case SET_HAS_SIGNED_TRUST_MANDATE_IN_DOCUSIGN: {
      return store.set("hasSignedTrustMandateInDocusign", payload);
    }

    default: {
      return store;
    }
  }
};

export { userOptionsReducer };
