import { List, Record as ImmutableRecord, Map } from "immutable";

import {
  AVAILABLE_CURRENCIES,
  PETS,
  VAT_INFO,
  WEEKDAYS,
} from "@typings/globals";

import { MODALS } from "./modals";

export enum CLEANING_FREQUENCY {
  MORE_OFTEN = "MORE_OFTEN",
  WEEKLY = "WEEKLY",
  EVERY_2_WEEKS = "EVERY_2_WEEKS",
  EVERY_4_WEEKS = "EVERY_4_WEEKS",
  ONCE = "ONCE",
}

export enum KEY_PICK {
  SOMEONE_AT_HOME = "SOMEONE_AT_HOME",
  CONCIERGE = "CONCIERGE",
  HIDE_KEY = "HIDE_KEY",
}

export enum EXTRA_SERVICES_BOOKING {
  IRONING = "IRONING",
  HOME_CLEANING = "HOME-CLEANING",
  INSIDE_CABINETS = "INSIDE-CABINETS",
  INSIDE_FRIDGE = "INSIDE-FRIDGE",
  INSIDE_OVEN = "INSIDE-OVEN",
  LAUNDRY_WASH_AND_DRY = "LAUNDRY-WASH-AND-DRY",
  INTERIOR_WINDOWS = "INTERIOR-WINDOWS",
  BONUS_15 = "BONUS-15",
}

export enum CALENDAR_TYPE {
  HOURS = "HOURS",
  TIME_SLOTS = "TIME_SLOTS",
}

export enum RESERVATION_ERRORS {
  NO_AGENT_AVAILABLE = "NO_AGENT_AVAILABLE",
  TOTAL_DURATION_TOO_LOW = "TOTAL_DURATION_TOO_LOW",
  TOO_EARLY_DATE = "TOO_EARLY_DATE",
  TASK_UNAVAILABLE = "TASK_UNAVAILABLE",
  INVALID_PRODUCT = "INVALID_PRODUCT",
  INVALID_SERVICE_VOUCHER_DURATION = "INVALID_SERVICE_VOUCHER_DURATION",
  OTHER = "OTHER",
}

export type ExtraServicesModals = {
  [EXTRA_SERVICES_BOOKING.IRONING]: MODALS;
  [EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS]: MODALS;
};

export type ExtraServiceWithModalsAvailable =
  | EXTRA_SERVICES_BOOKING.IRONING
  | EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS;

export enum AGENT_PREFERENCE {
  ANY = "ANY",
  PRESELECTED = "PRESELECTED",
}

export enum ONCE_BOOKING_TYPE {
  ONCE_JOB = "onceJob",
  ONCE_CONTRACT = "onceContract",
}

export interface Task {
  service: EXTRA_SERVICES_BOOKING;
  duration: number;
  adminOnly?: boolean;
  selected?: boolean;
  bedrooms?: number;
  bathrooms?: number;
}

export type BookingTasks = Record<
  EXTRA_SERVICES_BOOKING,
  ImmutableRecord<Task>
>;

export interface PriceItem {
  amount: number;
  frequency: CLEANING_FREQUENCY;
}

export interface BookingPricing {
  pricesPerFrequency: List<PriceItem>;
  displayedPriceForFirstBooking: number;
  currency: AVAILABLE_CURRENCIES;
  hasSpecialPricing: boolean;
  specialPricingTranslatedMessage: string | null;
  totalServiceVouchers: number | null;
}

export enum CLEANING_INFORMATION {
  HAS_PETS = "hasPets",
  HAS_SERVICE_VOUCHERS = "hasServiceVouchers",
  HAS_URSSAF = "hasUrssaf",
}

export enum CALENDAR_WEEK_SHIFT {
  PREVIOUS = "PREVIOUS",
  NEXT = "NEXT",
}

export enum PART_OF_DAY {
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
}

export enum EXTRA_SERVICES_LEGACY {
  INSIDE_OWEN = "INSIDE-OWEN", // remove this enum value when "Micheal Owen gate" gets fixed
}

export enum CHECKOUT_FORM {
  EMAIL = "email",
  PASSWORD = "password",
  PASSWORD_CONFIRMATION = "passwordConfirmation",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  ADDRESS = "address",
  FLOOR_AND_DOOR = "floorAndDoor",
  ENTRY_CODE = "entryCode",
  CITY = "city",
  PHONE_NUMBER = "phoneNumber",
  ADDITIONAL_NUMBER = "additionalNumber",
  BIRTH_DATE = "birthDate",
  TERMS = "terms",
  SPECIAL_INSTRUCTIONS = "specialInstructions",
  LOCATION_UUID = "locationUuid",
  PAYMENT_METHOD = "paymentMethod",
  SERVICE_VOUCHER = "serviceVoucher",
  MARKETING_CONSENT = "marketingConsent",
}

export enum CHECKOUT_FORM_ERROR_MAP {
  EMAIL_ALREADY_TAKEN = CHECKOUT_FORM.EMAIL,
  EMAIL_NOT_VALID = CHECKOUT_FORM.EMAIL,
  PHONE_NUMBER_NOT_VALID = CHECKOUT_FORM.PHONE_NUMBER,
  ADDRESS_NOT_VALID = CHECKOUT_FORM.ADDRESS,
  PASSWORD_TOO_SIMPLE = CHECKOUT_FORM.PASSWORD,
  SERVICE_VOUCHER_NOT_VALID = CHECKOUT_FORM.SERVICE_VOUCHER,
}

export interface ChosenAgent {
  uuid: string;
  displayName: string;
  photoUrl: string | null;
  acceptanceRate: number;
  cleaningsDone: string;
  rating: number;
  isVerified: boolean;
  workPermit: string;
  doesIroning: boolean;
  hasVehicle: boolean;
  languages: string[];
}

export interface ChosenAgentWSortOrder extends ChosenAgent {
  sortOrder: number;
}

export enum PLAN {
  TAKE_AND_LEAVE,
  RELAX,
}

export enum AGENTS_FILTER {
  PREVIOUS = "PREVIOUS",
  ALL = "ALL",
  RECENTLY_EMPLOYED = "RECENTLY_EMPLOYED",
}

export interface IAgent {
  firstName: string;
  lastName: string;
  isPrevious: boolean;
  uuid: string;
  label: string;
  doesIroning?: boolean;
  profilePictureUrl?: string;
  phoneNumber?: string;
}

export interface IAgentRaw {
  firstName: string;
  lastName: string;
  isPrevious: boolean;
  uuid: string;
  doesIroning?: boolean;
  profilePictureUrl?: string;
  phoneNumber?: string;
}

export interface AgentSelectOption {
  value: string;
  label: string;
  isPrevious: boolean;
}

export interface BookingAvailabilityRaw {
  defaultSelection: string | null;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: DayItemRaw[];
}

export interface DayItemRaw {
  day: string;
  hours: HourItemRaw[];
  timeslots?: TimeSlotRaw[];
}

export interface HourItemRaw {
  time: string;
  availableAgentsRemaining?: number;
}

export interface TimeSlotRaw {
  id: string;
  fromTime: string;
  toTime: string;
  label: string;
  timeOfDay: string;
  isAvailable: boolean;
}

export interface CustomDay {
  date: string;
  edge: "left" | "right" | null;
}

export interface BookingAvailability {
  defaultSelection: string | null;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: List<string>;
  customDays: Record<string, CustomDay> | null;
  hours: Map<string, List<HourItem>>;
  timeSlots: Map<string, List<TimeSlot>>;
  hasNoAvailableDates: boolean;
  calendarType: CALENDAR_TYPE;
}

export interface DayItem {
  day: string;
  hours: HourItem[];
  timeSlots: TimeSlot[];
}

export interface HourItem {
  duration: string;
  availableAgentsRemaining: number;
  partOfDay?: PART_OF_DAY;
}

export interface TimeSlot {
  id: string;
  fromTime: string;
  toTime: string;
  partOfDay: PART_OF_DAY;
  label: string;
  isAvailable: boolean;
}

export interface PricePlan {
  uuid: string;
  frequency: string;
  name: string;
  description: string;
  commitmentMonthsDuration: number;
  freeCancellations: number;
  pricePerHour: number;
  isMostPopularPlan: boolean;
  hidden: boolean;
  grantsVipStatus: boolean;
  useForOnceBooking: boolean;
  currency: AVAILABLE_CURRENCIES;
  isCurrentlyUsed?: boolean;
  isAvailableForChange?: boolean;
  hasProductsSpecialPricing: boolean;
  pricePerHourVatInfo: VAT_INFO;
}

export interface NonStandardFrequencyShifts {
  dayOfWeek: WEEKDAYS;
  shift: string;
  duration: number;
}

export interface MoreOftenFrequencyTimeSlots {
  dayOfWeek: WEEKDAYS;
  startTime: string;
  duration: number;
}

interface CleaningProductBase {
  productId: string;
  name: string;
  description: string;
  imageUrl: string;
  regularPrice: number;
  specialPrice: number | null;
}

export interface CleaningProductRaw extends CleaningProductBase {
  currency: keyof typeof AVAILABLE_CURRENCIES;
}

export interface CleaningProduct extends CleaningProductBase {
  currency: AVAILABLE_CURRENCIES;
}

export type CleaningProductsType = List<
  ImmutableRecord<CleaningProduct>
> | null;

export interface AccessInformation {
  keyPick: KEY_PICK;
  locationComment: string;
  specialInstructions: string;
  entryCode: string;
}
export interface FrequenciesDto {
  default: CLEANING_FREQUENCY;
  availableFrequencies: CLEANING_FREQUENCY[];
}

export interface PetsDetails {
  types: PETS[];
  description?: string;
}

export enum END_OF_TENANCY_EXTRA_SERVICES {
  END_OF_TENANCY_CLEANING = "END-OF-TENANCY-CLEANING",
  INSIDE_FRIDGE = "INSIDE-FRIDGE",
  INSIDE_OVEN = "INSIDE-OVEN",
  DOOR_HANDLES = "DOOR-HANDLES",
  BASEBOARDS = "BASEBOARDS",
  INTERIOR_WINDOWS = "INTERIOR-WINDOWS",
  INSIDE_CABINETS = "INSIDE-CABINETS",
  WINDOW_BLINDS = "WINDOW-BLINDS",
  BALCONY = "BALCONY",
}
