import { List, Map, Record } from "immutable";

import { EXTRA_SERVICES_B2B } from "./b2b";
import { CLEANING_FREQUENCY, KEY_PICK, EXTRA_SERVICES_LEGACY } from "./booking";
import { TABBED_NAVIGATION_TABS } from "./clientDashboard";
import { AVAILABLE_CURRENCIES, PAYMENT_METHODS } from "./globals";
import { LOADING_STATE } from "./reduxThunkActions";

export enum MY_CLEANING_UI_STATE {
  CANCELLED = "cancelled",
  UPCOMING = "upcoming",
  PREVIOUS = "previous",
  CHANGED = "changed",
  REPLACEMENT_PENDING = "replacement_pending",
  VACATIONS_PENDING = "vacations_pending",
  ALL_UPCOMING = "all_upcoming",
  ALL_PREVIOUS = "all_previous",
  ALL = "all",
}

export enum CONTACT_PERIODICITY {
  ONCE = 0,
  WEEKLY = 7,
  BIWEEKLY = 14,
  MONTHLY = 28,
}

export enum EXTRA_SERVICES_ENUM {
  IRONING = "IRONING",
  HOME_CLEANING = "HOME-CLEANING",
  INSIDE_CABINETS = "INSIDE-CABINETS",
  INSIDE_FRIDGE = "INSIDE-FRIDGE",
  INSIDE_OVEN = "INSIDE-OVEN",
  LAUNDRY_WASH_AND_DRY = "LAUNDRY-WASH-AND-DRY",
  INTERIOR_WINDOWS = "INTERIOR-WINDOWS",
  BONUS_15 = "BONUS-15",
  DOOR_HANDLES = "DOOR-HANDLES",
  BASEBOARDS = "BASEBOARDS",
  WINDOW_BLINDS = "WINDOW-BLINDS",
  BALCONY = "BALCONY",
  END_OF_TENANCY_CLEANING = "END-OF-TENANCY-CLEANING",
}

export enum CANCELLATION_FEE {
  NO_CANCELLATION_FEE = "NO_CANCELLATION_FEE",
  PART_PRICE_CANCELLATION_FEE = "PART_PRICE_CANCELLATION_FEE",
  FULL_PRICE_CANCELLATION_FEE = "FULL_PRICE_CANCELLATION_FEE",
  FLAT_PRICE_CANCELLATION_FEE = "FLAT_PRICE_CANCELLATION_FEE",
}

export enum UI_STATE_ENUM {
  UPCOMING = "UPCOMING",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
  HOLIDAYS_PENDING = "HOLIDAYS_PENDING",
  REPLACEMENT_PENDING = "REPLACEMENT_PENDING",
}

export enum TYPE_ENUM {
  HOME_CLEANING = "HOME_CLEANING",
  END_OF_TENANCY = "END_OF_TENANCY",
  B2B_CLEANING = "B2B_CLEANING",
}

export type BOOKINGS_TO_VALIDATE_JOB_TYPE = Exclude<
  TYPE_ENUM,
  TYPE_ENUM.END_OF_TENANCY
>;

export enum JOB_ACTION {
  DETAILS = "DETAILS",
  CLIENT_CHANGE_REQUEST = "CLIENT_CHANGE_REQUEST",
  ADMIN_CHANGE_REQUEST = "ADMIN_CHANGE_REQUEST",
  KEY_INFO = "KEY_INFO",
  DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE",
  INIT_CANCELLATION = "INIT_CANCELLATION",
  CHECKLIST = "CHECKLIST",
  MANAGE_CLEANING = "MANAGE_CLEANING",
  CLIENT_CHANGE_REQUEST_DISABLED = "CLIENT_CHANGE_REQUEST_DISABLED",
  COMMENT = "COMMENT",
  LEGACY_EDIT_BOOKING_BY_ADMIN = "LEGACY_EDIT_BOOKING_BY_ADMIN",
  SPECIAL_INSTRUCTIONS = "SPECIAL_INSTRUCTIONS",
  ADD_TIP = "ADD_TIP",
  ADJUST_TIMING = "ADJUST_TIMING",
  ADD_ABSENCE = "ADD_ABSENCE",
  RATE_CLEANING = "RATE_CLEANING",
  END_OF_TENANCY_JOB_CHANGE_REQUEST_BY_ADMIN = "END_OF_TENANCY_JOB_CHANGE_REQUEST_BY_ADMIN",
}

export enum JOB_FETCH_TYPE {
  PREVIOUS = "PREVIOUS",
  UPCOMING = "UPCOMING",
}

export enum JOB_BATCH {
  PREV = "prevMoreBatch",
  NEXT = "nextMoreBatch",
}

export enum CANCELLATION_STATE {
  REDIRECT = "REDIRECT",
  SHOW_LIGHTBOX = "SHOW_LIGHTBOX",
  NOT_STARTED = "NOT_STARTED",
  FAILED = "FAILED",
  DONE = "DONE",
  STARTED = "STARTED",
  SENDING_REASON = "SENDING_REASON",
  SENDING_PAYMENT = "SENDING_PAYMENT",
  AWAITING_REASON_ANSWER = "AWAITING_REASON_ANSWER",
  AWAITING_PAYMENT_ANSWER = "AWAITING_PAYMENT_ANSWER",
  AWAITING_PAYMENT_SELECTION = "AWAITING_PAYMENT_SELECTION",
}

export enum CANCELLATION_REASON {
  CLEANING_NOT_NEEDED_ANYMORE = "CLEANING_NOT_NEEDED_ANYMORE",
  CLIENT_FORGOT = "CLIENT_FORGOT",
  CLIENT_NOT_SATISFIED = "CLIENT_NOT_SATISFIED",
  HOLIDAY = "HOLIDAY",
  I_MADE_A_MISTAKE_WHEN_BOOKING = "I_MADE_A_MISTAKE_WHEN_BOOKING",
  I_WANT_EDIT_MY_BOOKINGS = "I_WANT_EDIT_MY_BOOKINGS",
  NO_MAID_AVAILABLE = "NO_MAID_AVAILABLE",
  NO_SHOW_OF_MAID = "NO_SHOW_OF_MAID",
  NOT_CHOSEN_YET = "NOT_CHOSEN_YET",
  OTHER = "OTHER",
  RELOCATION = "RELOCATION",
  CUSTOM = "CUSTOM",
  REFUSED_RESCHEDULED_AUTOMATICALLY = "REFUSED_RESCHEDULED_AUTOMATICALLY",
  REFUSED_AGENT_REPLACED_AUTOMATICALLY = "REFUSED_AGENT_REPLACED_AUTOMATICALLY",
}

export enum CANCELLATION_ROOTS {
  MY_CLEANINGS = "my-cleanings",
  BOOKINGS_TO_VALIDATE = "bookings-to-validate",
  AUTOMATIC_RESCHEDULE = "automatic-reschedule",
  AGENT_REPLACEMENT = "agent-replacement",
  HELP_CENTER = "help-center",
}

export enum JOB_SORTING_OPTIONS_ENUM {
  DESC,
  ASC,
}

export enum CANCELLATION_RESULT_NEXT_STEP {
  DONE = "DONE",
  AWAITING_PAYMENT_SELECTION = "AWAITING_PAYMENT_SELECTION",
}

export enum ACTION_HIGHLIGHT {
  DEFAULT = "DEFAULT",
  RED = "RED",
  DISABLED = "DISABLED",
}

export interface JobExtraDescription {
  duration: number;
  type: string;
}

export interface JobLocationTemporary {
  location: string;
}

export interface IExtraService {
  duration: number;
  type: EXTRA_SERVICES_LEGACY | EXTRA_SERVICES_ENUM; // @TODO adjust when Micheal Owen is gone
  label?: string;
}

export interface JobAction {
  key: JOB_ACTION;
  href: string;
  highlight: ACTION_HIGHLIGHT;
  translationKey: string;
}

export interface ICancellationInputData {
  cancellationRequestUuid: string;
  currency: string;
  feeAmount: number;
  feeInHours: number;
  reasons: any;
  showIsCancellingContract: boolean;
  showIsFeeSkippedByAdmin: boolean;
  showIsCancellationCountingSkippedByAdmin: boolean;
  feeLevel: string | null;
  freeCancellationsLeft: number | null;
  lateJobCancellationThreshold: number | null;
  isFreeCancellation: boolean | null;
  customReasons?: CancellationReason | null;
  changeRequestRelativeUrl?: string;
}

export interface CancellationReasonDto {
  heading: string;
  description: string;
  reasons: CancellationReasonItem[];
}

export interface CancellationReason {
  heading: string;
  description: string;
  reasons: CancellationReasonItem[];
}

export interface CancellationReasonItem {
  uuid: string;
  name: string;
  commentField: boolean;
  finishType: ("CANCEL" | "EDIT")[];
  subReasons: CancellationReason | null;
}

export interface ICancellationData extends ICancellationInputData {
  reasons: List<CANCELLATION_REASON>;
}

interface JobExtraRaw extends Omit<IExtraService, "type"> {
  type: string;
}
export interface JobRaw {
  agentUuid: string;
  bathrooms: number;
  bedrooms: number;
  frequency: CLEANING_FREQUENCY;
  executionDate: string;
  extraServices: JobExtraRaw[];
  keyPick: KEY_PICK;
  locationComment: string;
  specialInstructions: string | null;
  locationUuid: string;
  pricePlanUuid: string | null;
}

export interface Job {
  actions: List<Record<JobAction>>;
  agent: string;
  agentPhoneNumber: string | null;
  agentProfilePictureUrl: string | null;
  amount: number;
  amountWithUrssaf?: number | null;
  bathrooms: number;
  bedrooms: number;
  canBeRated: boolean;
  cancellationProcess?: Record<ICancellationData>;
  cancellationState?: CANCELLATION_STATE;
  cancellationFeeLevel: CANCELLATION_FEE;
  contractUuid: string;
  hasOnboardingCompleted?: boolean;
  cleaningChecklist: Record<{
    canBeFilled: boolean;
    isCreated: boolean;
  }>;
  contractPeriodicity: CONTACT_PERIODICITY;
  currency: AVAILABLE_CURRENCIES;
  duration: number;
  executionDate: string; // TODO: store as Date
  extraServices: List<Record<IExtraService>>;
  floorAndDoor: string;
  hasCancellationFee: boolean;
  rescheduledAutomatically: boolean;
  keyPick: KEY_PICK;
  location: string;
  locationUuid: string;
  locationComment: string;
  rating: number;
  ratingComment: string;
  specialInstructions: string | null;
  type: TYPE_ENUM;
  uiState: UI_STATE_ENUM;
  uuid: string;
  agentUuid: string;
  warrantyIncluded: boolean;
  subscriptionUuid: string | null;
  rescheduling: Record<{
    rescheduled: boolean;
    triggeredBy: RESCHEDULING_TRIGGERED_BY;
    pendingClientPreferredDateRequest: boolean;
  }>;
  agentAbsenceAutomationProcess: Record<{
    status: AGENT_REPLACEMENT_STATUS;
    rescheduleDetails: {
      triggeredBy: RESCHEDULING_TRIGGERED_BY;
    };
    optInDetails: {
      absentAgentFullName: string | null;
    };
  }>;
}

export interface Job2DTO {
  actions: JobAction[];
  agent: string;
  agentPhoneNumber: string | null;
  agentProfilePictureUrl: string | null;
  amount: number;
  amountWithUrssaf: number | null;
  bathrooms: number;
  bedrooms: number;
  canBeRated: boolean;
  cancellationProcess?: ICancellationData;
  cancellationState?: CANCELLATION_STATE;
  cancellationFeeLevel: CANCELLATION_FEE;
  contractUuid: string;
  hasOnboardingCompleted?: boolean;
  cleaningChecklist: {
    canBeFilled: boolean;
    isCreated: boolean;
  };
  contractPeriodicity: CONTACT_PERIODICITY;
  currency: AVAILABLE_CURRENCIES;
  duration: number;
  executionDate: string;
  extraServices: IExtraService[];
  floorAndDoor: string;
  hasCancellationFee: boolean;
  keyPick: KEY_PICK;
  location: string;
  locationUuid: string;
  locationComment: string;
  rating: number;
  ratingComment: string;
  specialInstructions: string | null;
  type: TYPE_ENUM;
  uiState: UI_STATE_ENUM;
  uuid: string;
  agentUuid: string;
  warrantyIncluded: boolean;
  subscriptionUuid: string | null;
  rescheduling: {
    rescheduled: boolean;
    triggeredBy: RESCHEDULING_TRIGGERED_BY;
  };
  agentAbsenceAutomationProcess: {
    status: AGENT_REPLACEMENT_STATUS;
    rescheduleDetails: {
      triggeredBy: RESCHEDULING_TRIGGERED_BY;
    };
    optInDetails: {
      absentAgentFullName: string | null;
    };
  };
}

export type JobsMap = Map<string, Record<Job>>;

export interface JobLocation {
  jobs: List<string>;
  location: string;
  address?: string;
  nextMoreBatch?: string | null;
  prevMoreBatch?: string | null;
  total: number;
  uuid: string;
  city: string;
  state: string;
}

export interface JobLocationInput {
  uuid: string;
  location: string;
  jobs: string[];
  total: number;
  nextBatchUrl: string | null;
  city: string;
  state: string;
}

export type ListOfJobsByLocation = Map<string, Record<JobLocation>>;
export type MapOfJobs = Map<string, Record<Job>>;

export interface JobsReducer {
  locations: ListOfJobsByLocation;
  jobs: Map<string, Record<Job>>;
}

export interface JobRating {
  rating: number | null;
  comment?: string;
  shareWithAgent?: boolean;
}

export interface KeyPick {
  keyPick: KEY_PICK;
  locationComment: string;
}

export interface SpecialInstructions {
  specialInstructions: string;
  applyOnwards: boolean;
}

export interface CancellationForm {
  cancelContract?: boolean;
  comment?: string | null;
  reason: CANCELLATION_REASON | null;
  skipFeeByAdmin?: boolean;
  skipCancellationCountByAdmin?: boolean;
  coronavirusCompensation?: boolean;
  customReasonId?: string;
}

export interface CancellationInputResponse {
  nextStep: CANCELLATION_RESULT_NEXT_STEP;
  availablePaymentMethods: any;
}

export interface CancellationResponse extends CancellationInputResponse {
  availablePaymentMethods: List<PAYMENT_METHODS>;
}

export interface ICancelSubmitReason {
  jobId: string;
  cancellationId: string;
  body: CancellationForm;
}

export interface ICancelHandleReasonResponse {
  jobId: string;
  cancellationId: string;
  body: {
    method: PAYMENT_METHODS;
    returnUrl?: string;
  };
  cancellationV2Enabled?: boolean;
}

export enum CANCELLATION_STATUS_RETURN_TYPE {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
}

export interface MyCleaningsMobileProps {
  activeTab: TABBED_NAVIGATION_TABS;
  setActiveTab: (tab: TABBED_NAVIGATION_TABS) => void;
  showPrevious: boolean;
  hasPendingHolidays: boolean;
  // @TODO: remove isFetched && isFetching and set loadingState as required during batsoft migration
  isFetched?: boolean;
  isFetching?: boolean;
  loadingState?: LOADING_STATE;
}

export type JobExtraServiceGeneric = {
  service: EXTRA_SERVICES_ENUM | EXTRA_SERVICES_B2B | EXTRA_SERVICES_LEGACY;
  duration: number;
};

export interface AgentJobDto {
  uuid: string;
  startDate: string;
  endDate: string;
  duration: number;
  isAccepted: boolean;
  changeRequestUuid: string | null;
  clientName: string;
  clientPhoneNumber: string;
  cleaningAddress: string;
  coordinates: {
    latitude: number;
    longitude: number;
  } | null;
  floorAndDoor: string;
  entryCode: string | null;
  keyPick: string | null;
  locationComment: string;
  specialInstructions: string | null;
  cleaningInfo: JobExtraServiceGeneric[];
  netSalary: string | null;
  isB2B: boolean;
  companyName: string | null;
  premises: string | null;
}

export enum RESCHEDULING_TRIGGERED_BY {
  RECURRING_AVAILABILITY = "RECURRING_AVAILABILITY",
  VACATION = "VACATION",
  BANK_HOLIDAY = "BANK_HOLIDAY",
  NONE = "NONE",
}

export enum AGENT_REPLACEMENT_STATUS {
  AGENT_AUTO_REPLACED = "AGENT_AUTO_REPLACED",
  AGENT_AUTO_REPLACEMENT_PENDING = "AGENT_AUTO_REPLACEMENT_PENDING",
  HOLIDAY_AUTOMATION_AWAITING_CLIENT_CONFIRMATION = "HOLIDAY_AUTOMATION_AWAITING_CLIENT_CONFIRMATION",
  HOLIDAY_AUTOMATION_ONGOING = "HOLIDAY_AUTOMATION_ONGOING",
  RESCHEDULED = "RESCHEDULED",
  RESCHEDULING_PENDING = "RESCHEDULING_PENDING",
  AGENT_AUTO_REPLACED_AWAITING_AGENT_CONFIRMATION = "AGENT_AUTO_REPLACED_AWAITING_AGENT_CONFIRMATION",
  OPT_IN_NOTIFICATION_SENT = "OPT_IN_NOTIFICATION_SENT",
  NONE = "NONE",
}

export enum CHANGE_REQUEST_APPLY_CHANGES_TYPE {
  THIS_JOB_ONLY = "THIS_JOB_ONLY",
  THIS_JOB_ONWARDS = "THIS_JOB_ONWARDS",
}

export enum JOBS_REFERER {
  DEFAULT = "DEFAULT",
  RESCHEDULE_AUTOMATION = "RESCHEDULE_AUTOMATION",
  AGENT_REPLACEMENT_AUTOMATION = "AGENT_REPLACEMENT_AUTOMATION",
}
