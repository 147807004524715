import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AppConfig } from "@typings/appConfig";
import { FREQUENCY } from "@typings/b2b";
import { AVAILABLE_COUNTRIES } from "@typings/globals";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.AT],
  booking: {
    commitments: {
      enabled: false,
    },
  },
  b2b: {
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 20,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,
};

export { config as at };
