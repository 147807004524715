import {
  eachDayOfInterval,
  addDays,
  format,
  startOfWeek,
  parseISO,
  differenceInDays,
} from "date-fns";

import { CustomDay, DayItemRaw } from "@typings/booking";

import { DATE_FORMAT } from "@services/DateFormatter";

const generateStaticDays = (weekStartDate?: string): string[] => {
  if (weekStartDate) {
    return eachDayOfInterval({
      start: parseISO(weekStartDate),
      end: addDays(parseISO(weekStartDate), 6),
    }).map(day => format(day, DATE_FORMAT.YEAR_MONTH_DAY));
  }

  return eachDayOfInterval({
    start: startOfWeek(new Date(), { weekStartsOn: 1 }),
    end: addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 6),
  }).map(day => format(day, DATE_FORMAT.YEAR_MONTH_DAY));
};

const genericCustomStaticDays = (
  chunkSize = 3,
  calendarRange = 28, // 4 weeks
): Record<string, CustomDay> => {
  const today = new Date();
  const currentMonday = startOfWeek(today, { weekStartsOn: 1 });
  const diff = differenceInDays(today, currentMonday);
  const baseRangeSize = diff + calendarRange + 6;
  const daysRange = eachDayOfInterval({
    start: currentMonday,
    end: addDays(
      currentMonday,
      baseRangeSize + chunkSize - (baseRangeSize % chunkSize) - 1,
    ),
  }).map(day => format(day, DATE_FORMAT.YEAR_MONTH_DAY));
  const firstDaysOfWeek: string[] = [];
  const lastDaysOfWeek: string[] = [];
  const returnData: Record<string, CustomDay> = {};

  for (let i = 0; i <= Math.floor(daysRange.length / 7); ++i) {
    const index = i * 7;
    const firstDay = daysRange[index];
    const lastDay = daysRange[index + 6];

    if (daysRange.includes(firstDay)) {
      firstDaysOfWeek.push(firstDay);
    }

    if (daysRange.includes(lastDay)) {
      lastDaysOfWeek.push(lastDay);
    }
  }

  daysRange.map(day => {
    returnData[day] = {
      date: day,
      edge: firstDaysOfWeek.includes(day)
        ? "left"
        : lastDaysOfWeek.includes(day)
        ? "right"
        : null,
    };
  });

  return returnData;
};

export { generateStaticDays, genericCustomStaticDays };
