import {
  LOCATIONS_UPDATE_PETS_ENDPOINT,
  LOCATIONS_ENDPOINT,
  LOCATIONS_SET_DEFAULT_ENDPOINT,
  LOCATION_BY_UUID_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { IPetsUpdate } from "@soft/redux/locations/locations.typings";
import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("locations");

export const FETCH_LOCATIONS = action.make("fetch locations");
export const FETCH_LOCATION_BY_ID = action.make("fetch location by id");
export const UPDATE_PETS = action.make("update pets");
export const SET_DEFAULT_LOCATION = action.make("set default location");
export const SET_IN_LOCAL_STORAGE = action.make("set in local storage");
export const SET_FROM_LOCAL_STORAGE = action.make("set from local storage");
export const DELETE_LOCAL_STORAGE = action.make("delete local storage");

export const fetchLocations =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: LOCATIONS_ENDPOINT,
      action: FETCH_LOCATIONS,
    });

    return dispatch(action);
  };

export const fetchLocationById =
  (locationId: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: LOCATION_BY_UUID_ENDPOINT.replace("{uuid}", locationId),
      action: FETCH_LOCATION_BY_ID,
    });

    return dispatch(action);
  };

export const updatePets =
  (id: string, body: IPetsUpdate) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const url = LOCATIONS_UPDATE_PETS_ENDPOINT.replace("{uuid}", id);

    const action = await asyncActionCreator({
      url,
      body,
      params: {
        ...body,
        id,
      },
      action: UPDATE_PETS,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const setLocationAsDefault =
  (locationUuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: LOCATIONS_SET_DEFAULT_ENDPOINT,
      action: SET_DEFAULT_LOCATION,
      method: ASYNC_ACTION_TYPES.PUT,
      body: { locationUuid },
    });

    return dispatch(action);
  };
