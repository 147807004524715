import { AnyAction } from "redux";

import { HOLIDAYS_ENDPOINT } from "@config/endpoints";

import {
  ThunkActionDispatch,
  ThunkActionType,
} from "@typings/soft.reduxThunkActions";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";
import { IStore } from "@soft/redux/reducers";
import {
  getAddHolidayEndpointRequest,
  getEditHolidayEndpointRequest,
  getDeleteHolidayEndpointRequest,
} from "@soft/redux/holidays/holidays.repository";

import { Holiday } from "./holidays.typings";

const action = new ReduxActionNameCreator("holidays");

const FETCH_HOLIDAYS = action.make("fetch holidays");
const ADD_HOLIDAY = action.make("add holiday");
const EDIT_HOLIDAY = action.make("edit holiday");
const DELETE_HOLIDAY = action.make("delete holiday");

const INIT_PROCESSED_HOLIDAY = action.make("init processed hoiday");
const UPDATE_PROCESSED_HOLIDAY = action.make("update processed holiday");
const CLEAR_PROCESSED_HOLIDAY = action.make("clear processed holiday");

const fetchHolidays: ThunkActionType =
  () => async (dispatch: ThunkActionDispatch) => {
    const action = await asyncActionCreator({
      url: HOLIDAYS_ENDPOINT,
      action: FETCH_HOLIDAYS,
    });

    return dispatch(action);
  };

const addHoliday: ThunkActionType =
  () => async (dispatch: ThunkActionDispatch, store: () => IStore) => {
    const body = getAddHolidayEndpointRequest(store());

    const action = await asyncActionCreator({
      url: HOLIDAYS_ENDPOINT,
      action: ADD_HOLIDAY,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

const editHoliday: ThunkActionType =
  () => async (dispatch: ThunkActionDispatch, store: () => IStore) => {
    const { body, params } = getEditHolidayEndpointRequest(store());

    const action = await asyncActionCreator({
      url: `${HOLIDAYS_ENDPOINT}/${params.id}`,
      action: EDIT_HOLIDAY,
      method: ASYNC_ACTION_TYPES.PUT,
      body,
    });

    return dispatch(action);
  };

const deleteHoliday =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const { id } = getDeleteHolidayEndpointRequest(store());

    const action = await asyncActionCreator({
      url: `${HOLIDAYS_ENDPOINT}/${id}`,
      action: DELETE_HOLIDAY,
      method: ASYNC_ACTION_TYPES.DELETE,
      params: { id },
    });

    return dispatch(action);
  };

const initProcessedHoliday = (): AnyAction => ({
  type: INIT_PROCESSED_HOLIDAY,
});

const updateProcessedHoliday = (payload: Partial<Holiday>): AnyAction => ({
  type: UPDATE_PROCESSED_HOLIDAY,
  payload,
});

const clearProcessedHoliday = (): AnyAction => ({
  type: CLEAR_PROCESSED_HOLIDAY,
});

export {
  FETCH_HOLIDAYS,
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  DELETE_HOLIDAY,
  INIT_PROCESSED_HOLIDAY,
  UPDATE_PROCESSED_HOLIDAY,
  CLEAR_PROCESSED_HOLIDAY,
  fetchHolidays,
  addHoliday,
  editHoliday,
  deleteHoliday,
  initProcessedHoliday,
  updateProcessedHoliday,
  clearProcessedHoliday,
};
