import { CURRENCY_PER_COUNTRY } from "@config/consts";
import { AppConfig } from "@typings/appConfig";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

import { CLEANING_DURATION_MATRIX_PL } from "@config/consts";
import { FREQUENCY } from "@typings/b2b";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.PL],
  booking: {
    durationMatrix: CLEANING_DURATION_MATRIX_PL,
    defaultDuration: 3,
    defaultBedrooms: 2,
    defaultBathrooms: 1,
    minBookingTime: 2.5,
    commitments: {
      enabled: false,
    },
    contactForBooking: {
      sundayEnabled: true,
    },
    bookingsToValidate: {
      bankAccountEnabled: true,
      bankAccountText: "batmaid_pwa_bookings_to_validate.bank_account_pl",
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
      ],
      rows: [
        [false, true, true],
        [true, true, true],
        [true, true, true],
        [false, true, true],
        [true, true, true],
      ],
    },
  },
  b2b: {
    contactDetails: {
      phone: "(+48) 22 888 41 20",
      email: "klienci@batmaid.pl",
    },
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 50,
  welcomeRewardAmount: 50,
  marketingConsentEnabled: true,
  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLSchHEx5jefBSo-_GHYsh8ZcN8sYNiSYJg1OPl2mHzspK6As0Q/viewform?usp=pp_url",
      [AVAILABLE_LANGS.PL]:
        "https://docs.google.com/forms/d/e/1FAIpQLSdBAZVdo0nsnuwv0oycQUG1zMnfJNFmLvDHk-2CSX7wGdq48Q/viewform?usp=pp_url",
    },
  },
};

export { config as pl };
