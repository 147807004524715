// indexes of hours in the static hours array (generateStaticBookingHoursInADay())
const STATIC_HOUR_INDEX_7_00 = 14;
const STATIC_HOUR_INDEX_20_00 = 41;

const STATIC_HOUR_INDEX_5_00 = 10;
const STATIC_HOUR_INDEX_21_30 = 44;

type TimeIntervals = "quarter" | "half" | "full" | "half-simplified";

interface StaticHour {
  time: string;
  availableAgentsRemaining: number;
}

interface StaticHourBatwork {
  time: string;
  availableAgents: [];
}

interface GenerateHoursParams {
  from: number;
  to: number;
  interval?: TimeIntervals;
  excludeLastFullHour?: boolean;
  excludeLeadingZero?: boolean;
  separator?: string;
}

const generateStaticHours = ({
  from = 0,
  to = 24,
  interval = "full",
  excludeLastFullHour = false, // this option is useful for generating a full 24h clock
  excludeLeadingZero = false,
  separator = ":",
}: GenerateHoursParams): { value: string; label: string }[] => {
  const times = [];

  const getInvertals = (interval: TimeIntervals) => {
    const fullHours = ["00"]; // 14:00, 15:00
    const halfHours = ["00", "30"]; // 14:00, 14:30, 15:00
    const halfHoursSimplified = ["", "5"]; // 14, 14.5, 15
    const quarterHours = ["00", "15", "30", "45"]; // 14:00, 14:15, 14:30, 14:45

    switch (interval) {
      case "quarter":
        return quarterHours;
      case "half":
        return halfHours;
      case "half-simplified":
        return halfHoursSimplified;
      default:
        return fullHours;
    }
  };

  const intervals = getInvertals(interval);

  const leadingZero = excludeLeadingZero ? "" : "0";

  for (let i = from; i < to; i++) {
    for (const interval of intervals) {
      // only include separator if there is explicit value after it (e.g 15:00 vs 15);
      const separatorValue = interval ? separator : "";

      let time = i + `${separatorValue}` + interval;
      if (i < 10) {
        time = leadingZero + time;
      }
      times.push({ value: time, label: time });
    }
    if (i + 1 === to && !excludeLastFullHour) {
      const endHour = to < 10 ? `${leadingZero}${to}` : to;
      // only include separator if there is explicit value after it (e.g 15:00 vs 15);
      const separatorValue = intervals[0] ? separator : "";

      times.push({
        label: `${endHour}${separatorValue}${intervals[0]}`,
        value: `${endHour}${separatorValue}${intervals[0]}`,
      });
    }
  }

  return times;
};

/* 
  generate static hours starting from 0:00 and ending 23:30
*/
const generateStaticBookingHoursInADay = (): StaticHour[] =>
  Array.from(
    {
      /* 
        There're: ((24 * 60) / 30) 30 minutes parts in a day. (part = 00:00-00:30 or 00:30-1:00 etc.)
      */
      length: (24 * 60) / 30,
    },
    (_, i) => {
      let hour: string | number = Math.floor((i * 30) / 60);

      // eslint-disable-next-line no-mixed-operators
      let min = String(i * 30 - hour * 60);
      if (Number(min) < 7) {
        min = "0" + min;
      }
      if (Number(hour) <= 9) {
        hour = "0" + hour;
      }

      return {
        time: hour + ":" + min,
        availableAgentsRemaining: 0,
      };
    },
  );

/* 
  slice static hours and get the range starting at 7:00, ending at 20:00 (the standard range for bookings clients)
*/
const generateStaticBookingHours = (): StaticHour[] =>
  generateStaticBookingHoursInADay().slice(
    STATIC_HOUR_INDEX_7_00,
    STATIC_HOUR_INDEX_20_00,
  );

/* 
  slice static hours and get the range starting at 5:00, ending at 21:30 (a range for B2B bookings admins only)
*/
const generateStaticBookingHoursForB2BAdmins = (): StaticHour[] =>
  generateStaticBookingHoursInADay().slice(
    STATIC_HOUR_INDEX_5_00,
    STATIC_HOUR_INDEX_21_30,
  );

const generateStaticBookingHoursForBatwork = (): StaticHourBatwork[] =>
  generateStaticBookingHours().map(hour => ({
    time: hour.time,
    availableAgents: [],
  }));

export {
  generateStaticBookingHours,
  generateStaticBookingHoursForB2BAdmins,
  generateStaticBookingHoursForBatwork,
  generateStaticHours,
};
