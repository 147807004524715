import { useContext, useState } from "react";
import { Set, Map } from "immutable";

import { ModalContext } from "@hooks/modal/modalContext";

const useModalManager = (): ReturnType<typeof useModalProvider> => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      "You are using some components outside of the ModalContext context",
    );
  }

  return context;
};

interface UseModalProviderReturn {
  isModalActive: (id: string) => boolean;
  showModal: (id: string, data?: any) => void;
  hideModal: (id: string) => void;
  getOption: <T = unknown>(id: string) => T;
  active: Set<string>;
  options: Map<string, any>;
}

const useModalProvider = (): UseModalProviderReturn => {
  const [active, setActive] = useState<UseModalProviderReturn["active"]>(Set());
  const [options, setOptions] = useState<UseModalProviderReturn["options"]>(
    Map(),
  );

  const isModalActive: UseModalProviderReturn["isModalActive"] = id =>
    active.has(id);
  const showModal: UseModalProviderReturn["showModal"] = (id, data) => {
    if (data && !active.has(id)) {
      setOptions(prev => prev.set(id, data));
    }

    setActive(prev => prev.add(id));
  };
  const hideModal: UseModalProviderReturn["hideModal"] = id => {
    if (active.has(id)) {
      setOptions(prev => prev.delete(id));
    }

    setActive(prev => prev.delete(id));
  };
  const getOption: UseModalProviderReturn["getOption"] = id => options.get(id);

  return {
    isModalActive,
    showModal,
    hideModal,
    getOption,
    active,
    options,
  };
};

export { useModalManager, useModalProvider };
