exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-xx-tsx": () => import("./../../../src/pages/5xx.tsx" /* webpackChunkName: "component---src-pages-5-xx-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-unsupported-browser-tsx": () => import("./../../../src/pages/unsupported-browser.tsx" /* webpackChunkName: "component---src-pages-unsupported-browser-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-batsoft-about-page-tsx": () => import("./../../../src/templates/batsoft/AboutPage.tsx" /* webpackChunkName: "component---src-templates-batsoft-about-page-tsx" */),
  "component---src-templates-batsoft-contact-us-page-tsx": () => import("./../../../src/templates/batsoft/ContactUsPage.tsx" /* webpackChunkName: "component---src-templates-batsoft-contact-us-page-tsx" */),
  "component---src-templates-batsoft-help-page-tsx": () => import("./../../../src/templates/batsoft/HelpPage.tsx" /* webpackChunkName: "component---src-templates-batsoft-help-page-tsx" */),
  "component---src-templates-batsoft-homepage-tsx": () => import("./../../../src/templates/BatsoftHomepage.tsx" /* webpackChunkName: "component---src-templates-batsoft-homepage-tsx" */),
  "component---src-templates-batsoft-sponsor-tsx": () => import("./../../../src/templates/BatsoftSponsor.tsx" /* webpackChunkName: "component---src-templates-batsoft-sponsor-tsx" */),
  "component---src-templates-batsoft-voucher-tsx": () => import("./../../../src/templates/BatsoftVoucher.tsx" /* webpackChunkName: "component---src-templates-batsoft-voucher-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/ContentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-footer-tsx": () => import("./../../../src/templates/Footer.tsx" /* webpackChunkName: "component---src-templates-footer-tsx" */),
  "component---src-templates-global-content-page-tsx": () => import("./../../../src/templates/GlobalContentPage.tsx" /* webpackChunkName: "component---src-templates-global-content-page-tsx" */),
  "component---src-templates-help-article-tsx": () => import("./../../../src/templates/HelpArticle.tsx" /* webpackChunkName: "component---src-templates-help-article-tsx" */),
  "component---src-templates-help-category-tsx": () => import("./../../../src/templates/HelpCategory.tsx" /* webpackChunkName: "component---src-templates-help-category-tsx" */),
  "component---src-templates-whats-included-page-tsx": () => import("./../../../src/templates/WhatsIncludedPage.tsx" /* webpackChunkName: "component---src-templates-whats-included-page-tsx" */)
}

