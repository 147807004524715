import {
  getDay,
  getMonth,
  getYear,
  getDate,
  getHours,
  getMinutes,
  getSeconds,
  getTime,
  isEqual,
  startOfWeek,
  format,
  add,
  parse,
  isValid,
  previousMonday,
  isMonday,
  isSunday,
  nextSunday,
  parseISO,
} from "date-fns";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import de from "date-fns/locale/de";
import it from "date-fns/locale/it";
import pl from "date-fns/locale/pl";
import nl from "date-fns/locale/nl";
import be from "date-fns/locale/be";

import { AVAILABLE_LANGS } from "@typings/globals";

const DAY_IN_JANUARY = new Date("2021-01-01T12:00:00Z");

export const DAY_PICKER_INPUT_REGEX = /^(\d{2})\.(\d{2})\.(\d{4})$/;
// this powerful regex was brought to you by stackoverflow https://stackoverflow.com/a/15504877, the place where magical things happen
export const DAY_PICKER_VALID_DATE_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const DATE_FORMAT = {
  YEAR_MONTH_DAY: "yyyy-MM-dd",
  YEAR_MONTH: "yyyy/MM",
  YEAR_MONTH_DAY_HOUR_MINUTE_ISO_8601: "yyyy-MM-dd'T'HH:mm",
  ISO_8601_WITH_TZ: "yyyy-MM-dd'T'HH:mm:ssxxx", // 2023-01-08T11:00:00+01:00
  YEAR_MONTH_DAY_HOUR_MINUTE: "yyyy.MM.dd HH:mm",
  HOUR_MINUTE: "HH:mm",
  HOUR_MINUTE_SHORT: "H:mm",
  DAY_IN_WEEK: "EEEE",
  MONTH_DAY: "MMMM d",
  MONTH_DAY_REVERTED: "d MMMM",
  DAY_PICKER_INPUT: "dd.MM.yyyy",
  DAY_MONTH_YEAR_HOUR_MINUTE: "dd.MM.yyyy HH:mm",
  DAY_MONTH_HUMAN_YEAR_HOUR_MINUTE: "dd LLL yyyy HH:mm",
  SHORT_MONTH: "MMM",
  MONTH: "MMMM",
  DAY: "iiii",
};

const LANGUAGE = {
  EN: "enUs",
  DE: "de",
  IT: "it",
  FR: "fr",
  PL: "pl",
  NL: "nl",
  BE: "be",
};

export interface IDateFormatter {
  day: number;
  dayInWeek: number;
  hours: number;
  minutes: number;
  month: number;
  seconds: number;
  year: number;
}

/**
 * @deprecated use parse method from date-fns
 */
export default function dateFormatter(_time: number | string): IDateFormatter {
  let time = _time;

  if (typeof _time === "string") {
    const splitDate = _time.split(/[^0-9]/);
    const date = new Date(
      parseInt(splitDate[0]),
      parseInt(splitDate[1]) - 1,
      parseInt(splitDate[2]),
      parseInt(splitDate[3]) || 0,
      parseInt(splitDate[4]) || 0,
      parseInt(splitDate[5]) || 0,
    );
    time = getTime(date);
  }

  const date: Date = new Date(time);

  const day: number = getDate(date);
  const dayInWeek: number = getDay(date) === 0 ? 7 : getDay(date);
  const month: number = getMonth(date) + 1;
  const year: number = getYear(date);
  const hours: number = getHours(date);
  const minutes: number = getMinutes(date);
  const seconds: number = getSeconds(date);

  return {
    day,
    dayInWeek,
    month,
    year,
    hours,
    minutes,
    seconds,
  };
}

export const getLocale = (lang: string): Locale => {
  switch (lang) {
    case LANGUAGE.EN:
      return enUS;
    case LANGUAGE.FR:
      return fr;
    case LANGUAGE.IT:
      return it;
    case LANGUAGE.DE:
      return de;
    case LANGUAGE.PL:
      return pl;
    case LANGUAGE.NL:
      return nl;
    case LANGUAGE.BE:
      return be;
    default:
      return enUS;
  }
};

/**
 * @deprecated use format method from date-fns
 */
export const dateToCalendarFormat = (date: Date): string =>
  date.toISOString().replace("T", " ").split(".")[0];

/**
 * @deprecated use format method from date-fns
 */
export const simpleDate = (date: Date): string => {
  const [day, month, dayNum, year] = String(date).split(" ");
  return `${day} ${month} ${dayNum} ${year}`;
};

export const dateFromNow = (input?: number): Date => {
  const date = new Date();
  if (input) {
    const days = Number(input) || 0;
    date.setDate(date.getDate() + days);
  }
  return date;
};

export const isDateInRange = (
  checkDate: Date,
  fromDate: Date,
  toDate: Date,
): boolean =>
  checkDate.getTime() >= fromDate.getTime() &&
  checkDate.getTime() <= toDate.getTime();

/**
 * @deprecated use format method from date-fns for date formatting
 */
export const addZero = (n: number): string => (n < 10 ? `0${n}` : String(n));

export const getFirstDayOfWeek = (date: string): Date => {
  return startOfWeek(parseISO(date), { weekStartsOn: 1 });
};

export const areDatesInSameWeek = (
  firstDate: string,
  secondDate: string,
): boolean =>
  isEqual(getFirstDayOfWeek(firstDate), getFirstDayOfWeek(secondDate));

export const getWeekdaysShortByLang = (lang: AVAILABLE_LANGS): string[] => {
  const weekdaysShort = {
    [AVAILABLE_LANGS.EN]: ["S", "M", "T", "W", "T", "F", "S"],
    [AVAILABLE_LANGS.FR]: ["D", "L", "M", "M", "J", "V", "S"],
    [AVAILABLE_LANGS.DE]: ["S", "M", "D", "M", "D", "F", "S"],
    [AVAILABLE_LANGS.IT]: ["D", "L", "M", "M", "G", "V", "S"],
    [AVAILABLE_LANGS.PL]: ["N", "P", "W", "Ś", "C", "P", "S"],
    [AVAILABLE_LANGS.NL]: ["Z", "M", "D", "W", "D", "V", "Z"],
  };

  return weekdaysShort[lang];
};

export const getMonthsByLang = (lang: AVAILABLE_LANGS): string[] =>
  [...Array(12)].map((_, monthIndex) =>
    format(add(DAY_IN_JANUARY, { months: monthIndex }), DATE_FORMAT.MONTH, {
      locale: getLocale(lang),
    }),
  );

export const getDayFromDateTimeString = (dateTime: string): string =>
  dateTime.split("T")[0];

export const getHourFromDateTimeString = (dateTime: string): string =>
  dateTime.split("T")[1].substring(0, 5);

export const formatDayPickerInputIntoDate = (date: string | Date): Date => {
  if (!date) {
    return new Date();
  }

  if (date instanceof Date) {
    return date;
  }

  const [day, month, year] = date.split(".");

  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const getFullCalendarDate = (
  selectedDay: string,
  selectedHour: string,
): string => {
  // Safari date fix
  const zuluOffset = format(new Date(), "XXX");
  return `${selectedDay}T${selectedHour}:00${zuluOffset}`;
};

export const newDateSafe = (input: string, fallback?: Date): Date => {
  const timestamp = Date.parse(input);

  if (!isNaN(timestamp)) {
    return new Date(input);
  }

  return fallback || new Date();
};

interface WeekRangeObj {
  startDate: string;
  endDate: string;
}

type StringOrObject<T extends boolean> = T extends true ? WeekRangeObj : string;

export const getWeekRange = <T extends boolean>(
  weeks: number,
  asDatesObj?: T,
): StringOrObject<T> => {
  const todayDate = new Date();
  const weekDay = getDay(todayDate);

  const mondayInCurrentWeek =
    weekDay === 0 || weekDay === 1
      ? previousMonday(add(todayDate, { days: 1 })) // Sunday/Monday case
      : previousMonday(todayDate);

  const mondayInWeek = add(mondayInCurrentWeek, { days: weeks * 7 });
  const sundayInWeek = add(mondayInCurrentWeek, {
    days: weeks * 7 + 6,
  });

  const month = format(sundayInWeek, DATE_FORMAT.SHORT_MONTH);

  if (asDatesObj) {
    return {
      startDate: format(mondayInWeek, DATE_FORMAT.YEAR_MONTH_DAY),
      endDate: format(sundayInWeek, DATE_FORMAT.YEAR_MONTH_DAY),
    } as StringOrObject<T>;
  }

  return `${mondayInWeek.getDate()} - ${sundayInWeek.getDate()} ${month}` as StringOrObject<T>;
};

export const getWeekRangeDates = (
  date: Date,
): { startDate: Date; endDate: Date } => {
  const monday = isMonday(date) ? date : previousMonday(date);
  const sunday = isSunday(date) ? date : nextSunday(date);

  return { startDate: monday, endDate: sunday };
};

export const getTimeRange = (
  startDate: Date | null,
  endDate: Date | null,
): string => {
  if (!startDate || !endDate) {
    return "";
  }
  const timeRange = `${format(
    startDate,
    DATE_FORMAT.HOUR_MINUTE_SHORT,
  )} - ${format(endDate, DATE_FORMAT.HOUR_MINUTE_SHORT)}`;

  return timeRange;
};

export const parseDate = <T>(
  dateString: string,
  formatString: string,
  fallback: T,
  referenceDate: Date | number = new Date(),
): Date | T => {
  try {
    const date = parse(dateString, formatString, referenceDate);
    return isValid(date) ? date : fallback;
  } catch {
    return fallback;
  }
};

export const replaceTimeZone = (date: string | null): string | null => {
  if (!date || !date.includes("+")) {
    return null;
  }

  const timeZone = format(new Date(), "XXX");

  return date.split("+")[0] + timeZone;
};
