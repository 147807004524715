import { Record, Map } from "immutable";
import { AnyAction } from "redux";

import {
  CANCELLATION_STATE,
  JOB_FETCH_TYPE,
  UI_STATE_ENUM,
  JOB_ACTION,
  JobAction,
} from "@typings/jobs";

import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  ACTION_NO_DATA,
} from "@services/AsyncActionCreatorFactory";

import {
  FETCH_JOBS,
  FETCH_JOBS_FROM_INITIAL_STATE,
  SET_KEY_PICK,
  CANCEL_START,
  CANCEL_REASON,
  CANCEL_DONE,
  RATE_JOB,
  INIT_AGENT_REPLACEMENT,
  CANCEL_PAYMENT,
  SET_SPECIAL_INSTRUCTIONS,
  SET_JOB_RATING,
} from "./jobs.actions";
import {
  MapJobsLocationFactory,
  MapJobsFactory,
  CancellationFactory,
} from "./jobs.factories";
import { JobsReducer } from "./jobs.typings";

const initialState = Record<JobsReducer>({
  locations: Map(),
  jobs: Map(),
});

const jobsReducer = (
  store: Record<JobsReducer> = initialState(),
  action: AnyAction,
): Record<JobsReducer> => {
  const { payload, type } = action;

  switch (type) {
    case `${FETCH_JOBS}${ACTION_SUCCESS}`: {
      const {
        extra: { uiState },
      } = action;
      const {
        data: { jobs, jobsByLocation },
      } = payload;

      if (jobsByLocation.length === 0 || jobs.length === 0) {
        return store;
      }

      return store
        .set(
          "locations",
          MapJobsLocationFactory(
            jobsByLocation,
            store.get("locations"),
            uiState as JOB_FETCH_TYPE,
          ),
        )
        .set("jobs", MapJobsFactory(jobs, store.get("jobs")));
    }

    case `${FETCH_JOBS_FROM_INITIAL_STATE}${ACTION_SUCCESS}`: {
      const {
        extra: { uiState },
      } = action;
      const {
        data: { jobs, jobsByLocation },
      } = payload;

      if (jobsByLocation.length === 0 || jobs.length === 0) {
        return store;
      }

      return store
        .set(
          "locations",
          MapJobsLocationFactory(
            jobsByLocation,
            initialState().get("locations"),
            uiState as JOB_FETCH_TYPE,
          ),
        )
        .set("jobs", MapJobsFactory(jobs, initialState().get("jobs")));
    }

    case `${FETCH_JOBS}${ACTION_NO_DATA}`: {
      return store;
    }

    case `${FETCH_JOBS_FROM_INITIAL_STATE}${ACTION_NO_DATA}`: {
      return store;
    }

    case `${SET_KEY_PICK}${ACTION_SUCCESS}`: {
      const { params } = action;

      return store
        .setIn(["jobs", params.id, "keyPick"], params.keyPick)
        .setIn(["jobs", params.id, "locationComment"], params.locationComment);
    }

    case `${SET_SPECIAL_INSTRUCTIONS}${ACTION_SUCCESS}`: {
      const { params } = action;

      return store.setIn(
        ["jobs", params.id, "specialInstructions"],
        params.specialInstructions,
      );
    }

    case `${CANCEL_START}${ACTION_SUCCESS}`: {
      const { params } = action;
      const { data } = payload;

      return store
        .setIn(
          ["jobs", params.id, "cancellationState"],
          CANCELLATION_STATE.AWAITING_REASON_ANSWER,
        )
        .setIn(
          ["jobs", params.id, "cancellationProcess"],
          CancellationFactory(data),
        );
    }

    case `${CANCEL_START}${ACTION_FAIL}`: {
      const { params } = action;

      return store.setIn(
        ["jobs", params.id, "cancellationState"],
        CANCELLATION_STATE.FAILED,
      );
    }

    case `${CANCEL_REASON}${ACTION_SUCCESS}`: {
      const { params } = action;

      return store.setIn(
        ["jobs", params.id, "cancellationState"],
        CANCELLATION_STATE.AWAITING_PAYMENT_SELECTION,
      );
    }

    case `${CANCEL_PAYMENT}${ACTION_FAIL}`: {
      const { params } = action;

      return store
        .setIn(
          ["jobs", params.id, "cancellationState"],
          CANCELLATION_STATE.NOT_STARTED,
        )
        .setIn(["jobs", params.id, "cancellationProcess"], undefined)
        .setIn(["jobs", params.id, "uiState"], UI_STATE_ENUM.UPCOMING);
    }

    case CANCEL_DONE: {
      return store
        .setIn(
          ["jobs", payload.id, "cancellationState"],
          CANCELLATION_STATE.DONE,
        )
        .setIn(["jobs", payload.id, "amount"], payload.amount)
        .setIn(["jobs", payload.id, "cancellationProcess"], undefined)
        .setIn(["jobs", payload.id, "uiState"], UI_STATE_ENUM.CANCELLED)
        .setIn(
          ["jobs", payload.id, "actions"],
          store
            .getIn(["jobs", payload.id, "actions"])
            .filter(
              (action: JobAction) =>
                action.key === JOB_ACTION.DETAILS ||
                action.key === JOB_ACTION.DOWNLOAD_INVOICE,
            ),
        );
    }

    case `${INIT_AGENT_REPLACEMENT}${ACTION_SUCCESS}`: {
      const { params } = action;
      return store.setIn(
        ["jobs", params.id, "uiState"],
        UI_STATE_ENUM.REPLACEMENT_PENDING,
      );
    }

    case `${RATE_JOB}${ACTION_SUCCESS}`:
      return store
        .setIn(
          ["jobs", action.params.id, "ratingComment"],
          action.params.comment,
        )
        .setIn(["jobs", action.params.id, "rating"], action.params.rating);

    case SET_JOB_RATING:
      return store.setIn(["jobs", payload.id, "rating"], payload.rating);

    default: {
      return store;
    }
  }
};

export { jobsReducer };
