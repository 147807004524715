import { AnyAction } from "redux";
import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import {
  FETCH_USER_NOTIFICATIONS,
  SET_USER_NOTIFICATIONS,
} from "./userNotifications.actions";
import { UserNotificationsFactory } from "./userNotifications.factories";
import {
  UserNotificationsReducer,
  USER_NOTIFICATIONS,
} from "./userNotifications.typings";

const initialState = UserNotificationsFactory();

export const userNotificationsReducer = (
  store: UserNotificationsReducer = initialState,
  action: AnyAction,
): UserNotificationsReducer => {
  const { payload, type, params } = action;

  switch (type) {
    case `${FETCH_USER_NOTIFICATIONS}${ACTION_SUCCESS}`: {
      const { data } = payload;

      return UserNotificationsFactory(data);
    }

    case `${SET_USER_NOTIFICATIONS}${ACTION_SUCCESS}`: {
      // This setter should update each object properties
      // but at this moment works more like 'set new booking reminder notification'
      // rather than updating the whole object.
      // Please update the setter when API is changed.
      return store.set(
        USER_NOTIFICATIONS.SHOW_NEW_BOOKING_REMINDER,
        params.showNewBookingReminder,
      );
    }

    default: {
      return store;
    }
  }
};
