import { Record, Map } from "immutable";

import { Location, COUNTRY_ISO_ENUM } from "@typings/locations";

import { LocationsReducer, LocationsRawResponse } from "./locations.typings";

const initialLocation = Record<Location>({
  uuid: "",
  zip: "",
  address: "",
  state: "",
  city: "",
  country: "",
  countryIso: COUNTRY_ISO_ENUM.CH,
  firstName: "",
  lastName: "",
  invoicedCompany: "",
  entryCode: "",
  floorAndDoor: "",
  keyPick: "",
  howToGetIn: "",
  hasPets: false,
  ongoingJobs: 0,
  previousJobs: 0,
  upcomingJobs: 0,
  hideCityName: false,
  hasActiveContract: true,
});

export const locationsFactory = (
  input: LocationsRawResponse,
): Record<LocationsReducer> => {
  const locations = input.locations.reduce(
    (acc: Map<string, Record<Location>>, location: Location) => {
      return acc.set(location.uuid, initialLocation(location));
    },
    Map(),
  );

  return Record<LocationsReducer>({
    defaultLocationId: "",
    invoiceLocationId: "",
    legalLocationId: "",
    locations: Map(),
  })({ ...input, locations });
};
