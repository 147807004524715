import { AnyAction } from "redux";
import { Record } from "immutable";

import { USER_LOGIN_STATE } from "@typings/user";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import { FETCH_USER_DATA, LOGIN_USER, LOGOUT_USER } from "./user.actions";
import { UserFactory } from "./user.factories";
import { UserReducer, IUserReducerState } from "./user.typings";

const initialState = Record<IUserReducerState>({
  data: UserFactory(),
  isFetched: false,
  state: USER_LOGIN_STATE.UNKNOWN,
})();

const userReducer = (
  store: UserReducer = initialState,
  action: AnyAction,
): UserReducer => {
  const { payload, type } = action;

  switch (type) {
    case `${FETCH_USER_DATA}${ACTION_SUCCESS}`: {
      const { data } = payload;

      return store
        .mergeIn(["data"], UserFactory(data))
        .set(
          "state",
          data?.roles?.length > 0
            ? USER_LOGIN_STATE.LOGGED_IN
            : USER_LOGIN_STATE.LOGGED_OUT,
        )
        .set("isFetched", true);
    }

    case `${LOGIN_USER}${ACTION_SUCCESS}`: {
      return store
        .set("state", USER_LOGIN_STATE.LOGGED_IN)
        .setIn(["data", "userType"], payload.data.userType);
    }

    case `${LOGOUT_USER}${ACTION_SUCCESS}`: {
      return store
        .set("state", USER_LOGIN_STATE.LOGGED_OUT)
        .set("data", UserFactory());
    }

    default: {
      return store;
    }
  }
};

export { userReducer };
