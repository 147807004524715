import { AnyAction } from "redux";

import {
  JOBS_CANCELLATION_PAYMENT_ENDPOINT,
  JOBS_CANCELLATION_REASON_ENDPOINT,
  JOBS_CANCELLATION_START_ENDPOINT,
  JOBS_ENDPOINT,
  JOBS_KEY_PICK_ENDPOINT,
  JOBS_RATING_ENDPOINT,
  INIT_AGENT_REPLACEMENT_ENDPOINT,
  JOB_BY_UUID_ENDPOINT,
  JOBS_SPECIAL_INSTRUCTIONS_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";
import {
  ICancelHandleReasonResponse,
  ICancelSubmitReason,
  JobRating,
  KeyPick,
  SpecialInstructions,
  JOB_FETCH_TYPE,
} from "@typings/jobs";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { nullifyString } from "@services/NullifyString";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";
import { IStore } from "@soft/redux/reducers";

const action = new ReduxActionNameCreator("jobs");

// Actions
export const FETCH_JOBS = action.make("fetch jobs");
export const FETCH_JOBS_FROM_INITIAL_STATE = action.make(
  "fetch jobs from initial state",
);
export const FETCH_JOB_BY_ID = action.make("fetch job by id");
export const RATE_JOB = action.make("rate job");
export const SET_KEY_PICK = action.make("set keyPick");
export const SET_SPECIAL_INSTRUCTIONS = action.make("set special instructions");
export const CANCEL_START = action.make("cancellation start");
export const CANCEL_REASON = action.make("cancellation pass reason");
export const CANCEL_PAYMENT = action.make("cancellation payment process");
export const CANCEL_DONE = action.make("cancellation done");
export const INIT_AGENT_REPLACEMENT = action.make("init agent replacement");
export const FETCH_EMPLOYEE_ABSENCES = action.make("fetch emplyee absences");
export const SET_JOB_RATING = action.make("set job rating");

// Actions creators
export const fetchJobs =
  (jobState: JOB_FETCH_TYPE = JOB_FETCH_TYPE.UPCOMING, next?: string) =>
  async (dispatch: ThunkActionDispatch, store: () => IStore) => {
    let url = `${JOBS_ENDPOINT}?type=${jobState}`;

    if (next) {
      url += `&after=${next}`;
    }

    const action = await asyncActionCreator({
      extra: {
        uiState: jobState,
      },
      retryConnection: false,
      url,
      action: FETCH_JOBS,
    });

    return dispatch(action);
  };

/* 
fetchJobs action uses complicated logic for fetching all jobs, 
it uses existing store state instead of the initial one 
what causes you need to add more actions to achieve desired state. 
The action below fetchJobsFromInitialState operates on initialState 
so all the data will be calculated from the clear state and it doesn't need more calculation.
fetchJobs action and all connected actions could be removed 
and fetchJobsFromInitialState could be used instead.
*/
export const fetchJobsFromInitialState =
  (jobState: JOB_FETCH_TYPE = JOB_FETCH_TYPE.UPCOMING, next?: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    let url = `${JOBS_ENDPOINT}?type=${jobState}`;

    if (next) {
      url += `&after=${next}`;
    }

    const action = await asyncActionCreator({
      extra: {
        uiState: jobState,
      },
      retryConnection: false,
      url,
      action: FETCH_JOBS_FROM_INITIAL_STATE,
    });

    return dispatch(action);
  };

export const fetchJobById =
  (uuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: JOB_BY_UUID_ENDPOINT.replace("{uuid}", uuid),
      action: FETCH_JOB_BY_ID,
    });

    return dispatch(action);
  };

/**
 * Rating
 */

export const rateJob =
  (id: string, body: JobRating) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const url = JOBS_RATING_ENDPOINT.replace("{uuid}", id);

    if (typeof body.rating === "undefined") {
      body.rating = null;
    }

    const action = await asyncActionCreator({
      url,
      body,
      params: {
        id,
        ...body,
      },
      action: RATE_JOB,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

// updating state after submitting rating v2
export const setJobRating = (id: string, rating: number): AnyAction => ({
  type: SET_JOB_RATING,
  payload: {
    id,
    rating,
  },
});

/**
 * Key pick
 */

export const setKeyPick =
  (id: string, payload: KeyPick) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const url = JOBS_KEY_PICK_ENDPOINT.replace("{uuid}", id);

    const body = {
      ...payload,
      locationComment: nullifyString(payload.locationComment),
    };

    const action = await asyncActionCreator({
      url,
      body,
      params: {
        ...body,
        id,
      },
      action: SET_KEY_PICK,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const setSpecialInstructions =
  (id: string, payload: SpecialInstructions) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const url = JOBS_SPECIAL_INSTRUCTIONS_ENDPOINT.replace("{uuid}", id);

    const body = {
      ...payload,
      specialInstructions: nullifyString(payload.specialInstructions),
    };

    const action = await asyncActionCreator({
      url,
      body,
      params: {
        ...body,
        id,
      },
      action: SET_SPECIAL_INSTRUCTIONS,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

/**
 * Cancellation flow
 */

export const cancelStart =
  (id: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: JOBS_CANCELLATION_START_ENDPOINT.replace("{uuid}", id),
      action: CANCEL_START,
      method: ASYNC_ACTION_TYPES.POST,
      params: {
        id,
      },
    });

    return dispatch(action);
  };

export const cancelSubmitReason =
  ({ jobId, cancellationId, body }: ICancelSubmitReason) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: JOBS_CANCELLATION_REASON_ENDPOINT.replace(
        "{cancellationRequestUuid}",
        cancellationId,
      ),
      action: CANCEL_REASON,
      method: ASYNC_ACTION_TYPES.POST,
      body,
      params: {
        id: jobId,
      },
    });

    return dispatch(action);
  };

export const cancelHandlePayment =
  ({ jobId, cancellationId, body }: ICancelHandleReasonResponse) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: JOBS_CANCELLATION_PAYMENT_ENDPOINT.replace(
        "{cancellationRequestUuid}",
        cancellationId,
      ),
      action: CANCEL_PAYMENT,
      method: ASYNC_ACTION_TYPES.POST,
      params: {
        id: jobId,
        cancellationId,
      },
      body,
    });

    return dispatch(action);
  };

export const cancelFinished =
  (jobId: string, amount: number) =>
  (dispatch: ThunkActionDispatch): AnyAction => {
    return dispatch({
      type: CANCEL_DONE,
      payload: {
        id: jobId,
        amount,
      },
    });
  };

export const initAgentReplacement =
  (jobId: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: INIT_AGENT_REPLACEMENT_ENDPOINT.replace("{uuid}", jobId),
      action: INIT_AGENT_REPLACEMENT,
      method: ASYNC_ACTION_TYPES.POST,
      params: {
        id: jobId,
      },
    });

    return dispatch(action);
  };
