import { ITheme } from "@ui/themes/ThemeInterface";
import mq from "@ui/helpers/mq";
import rem from "@ui/helpers/rem";

import { palette } from "./palette";

export const breakpointsValues = {
  mobileSmall: 360,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  desktopLarge: 1200,
};

export const mediaQueries: Record<keyof typeof breakpointsValues, string> = {
  mobileSmall: `(max-width: ${breakpointsValues.mobileSmall}px)`,
  mobile: `(max-width: ${breakpointsValues.tablet - 1}px)`,
  tablet: `(min-width: ${breakpointsValues.tablet}px) and (max-width: ${
    breakpointsValues.desktop - 1
  }px)`,
  desktop: `(min-width: ${breakpointsValues.desktop}px) and (max-width: ${
    breakpointsValues.desktopLarge - 1
  }px)`,
  desktopLarge: `(min-width: ${breakpointsValues.desktopLarge}px)`,
};

const theme: ITheme = {
  animations: {
    durationShort: "50ms",
    duration: "150ms",
    durationLong: "350ms",
    easing: "ease-in-out",
  },
  breakpoints: {
    belowMobileSmall: mq([0, String(breakpointsValues.mobileSmall - 1)]),
    mobile: mq(String(breakpointsValues.mobile)),
    upToTablet: mq([0, String(breakpointsValues.mobile)]),
    tablet: mq(String(breakpointsValues.tablet)),
    upToDesktop: mq([0, String(breakpointsValues.tablet)]),
    tabletToDesktop: mq([
      String(breakpointsValues.tablet),
      String(breakpointsValues.desktop),
    ]),
    desktop: mq(String(breakpointsValues.desktop)),
    upToDesktopLarge: mq([0, String(breakpointsValues.desktopLarge)]),
    desktopToDesktopLarge: mq([
      String(breakpointsValues.desktop),
      String(breakpointsValues.desktopLarge),
    ]),
    desktopLarge: mq(String(breakpointsValues.desktopLarge)),
  },
  orientation: {
    landscape: `@media (orientation: landscape)`,
    portrait: `@media (orientation: portrait)`,
  },
  buttons: {
    borderRadius: rem(4),
    height: rem(56),
    heightSmall: rem(33),
    lineHeight: rem(16),
    sizes: {
      pill: rem(40),
      small: rem(124),
      medium: rem(248),
      large: rem(668),
      table: "auto",
      fill: "100%",
    },
    paddings: {
      vertical: rem(16),
      horizontal: rem(16),
      xhorizontal: rem(20),
    },
  },
  border: {
    radius: {
      half: rem(4),
      base: rem(8),
      base_x1_5: rem(12),
      base_x2: rem(16),
    },
  },
  container: {
    mobile: rem(0),
    tablet: rem(720),
    desktopSmall: rem(672),
    desktop: rem(960),
    desktopLarge: rem(1168),

    calendar: rem(360),
  },
  gradients: {
    blue800_blue600:
      "linear-gradient(135deg,rgba(0, 104, 153, 1) 0%,rgba(0, 139, 190, 1) 100%)",
    lightblue_white:
      "linear-gradient(180deg, rgba(245,248,251,1) 0%, rgba(255,255,255,1) 30%);",
  },
  palette,
  colors: {
    /** CURRENT **/
    white: "#FFFFFF",

    greySurface: "#F2F2F2",
    greyLight: "#F9F9F9",
    greyDark: "#D9D9D9",
    greyDisabledText: "#959595",

    grey20: "#F7F8F8",
    grey50: "#F2F2F2",
    grey100: "#D9D9D9",
    grey200: "#CCCCCC",
    grey300: "#A6A6A6",
    grey500: "#999999",
    grey600: "#747474",
    grey700: "#606060", // 60% of #000000

    greyDisabled: "#C2C2C2", // @ToDo: last legacy grey color
    black: "#555555", // @ToDo: main legacy black color, difficult to replace

    blue20: "#F0F8FA",
    blue50: "#DFF3F8",
    blue100: "#AEDFED",
    blue200: "#7ACAE2",
    blue300: "#48B5D7",
    blue400: "#1EA6D1",
    blue500: "#0097CB",
    blue600: "#008BBE",
    blue700: "#0079AC",
    blue800: "#006899",
    blue900: "#004A7A",

    blueDisabled: "#99B6C9",

    green50: "#EEf9E6",
    green100: "#D4f0C2",
    green200: "#B6E699",
    green300: "#97DC6E",
    green400: "#7DD449",
    green500: "#62CC1d",
    green600: "#52BC14",
    green700: "#38A703",
    green800: "#169300",
    green900: "#007100",

    black300: "#3B3B3B",
    black400: "#302E2E",
    black500: "#212121",
    black700: "#1F1F1F", // 87% of #000000
    black800: "#040404",

    gold: "#fecc02",

    red50: "#F7D4D7",
    red500: "#D9534F",
    red700: "#B00020",

    orange50: "#FFE5CC",
    orange500: "#F28039",
    orange700: "#FA6400",
    orange900: "#A35200",

    pink: "#F68892",

    /** LEGACY **/
    whiteHover: "#F5F5F5",
    whiteBorder: "#ECEFF1",

    facebook: "#3b5998",
    instagram: "#e1306c",
    twitter: "#1da1f2",
    googleplus: "#dd4b39",
    youtube: "#ff0000",
    google: "#c75340",
    wincasa: "#f28039",

    calendarDayMaid: "#C6D6DB",
    calendarDayActiveMaid: "#FF8090",
    calendarDayLightMaid: "#FFB8C1",
    calendarDayBorderMaid: "#FF8090",

    calendarDayClient: "#C6D6DB",
    calendarDayActiveClient: "#1EA6D1",
    calendarDayLightClient: "#DFF3F8",
    calendarDayBorderClient: "#7ACAE2",

    // checklist
    checklistGreen500: "#07B183",
    checklistGreen700: "#058562",
  },
  notifications: {
    blueBackground: "#DBF1F8",
    blueBorder: "#B8DAFF",
    blueText: "#2C5F98",

    redBackground: "#F8D7DA",
    redBorder: "#F5C6CB",
    redText: "#721C24",

    yellowBackground: "#FCF8E3",
    yellowBorder: "#FFEEBA",
    yellowText: "#856404",

    greenBackground: "#D4EDDA",
    greenBorder: "#D0E9C6",
    greenText: "#155724",
  },
  fonts: {
    default: '"Open Sans"',
    base: '"Open Sans", Helvetica, Arial, sans-serif',
    baseSize: "16px",
    weights: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    sizes: {
      /** CURRENT */
      h1: rem(72),
      h2: rem(48),
      h3: rem(40),
      h4: rem(32),
      h5: rem(24),
      h6: rem(20),
      body: rem(16),
      body2: rem(14),
      subtitle: rem(16),
      subtitle2: rem(14),
      caption: rem(12),
      overline: rem(10),

      /** LEGACY */
      enlarged: rem(20), //@ToDo: should probably stay, but name needs consulting with UX team
    },
    lineHeights: {
      normal: rem(24),
      h1: rem(96),
      h2: rem(72),
      h3: rem(48),
      h4: rem(40),
      h5: rem(40),
      h6: rem(24),
      subtitle: rem(24),
      subtitle2: rem(24),
      caption: rem(16),
      overline: rem(16),
    },
    letterSpacings: {
      normal: `${rem(0.24)}`,
      h1: `-${rem(1.16)}`,
      h2: `-${rem(0.48)}`,
      h3: "normal",
      h4: rem(0.24),
      h5: "normal",
      h6: "normal",
      subtitle: rem(0.16),
      subtitle2: "normal",
      caption: rem(0.4),
      overline: rem(2),
    },
  },
  grid: {
    columns: 12,
  },
  margins: {
    none: "0",
    quarter: rem(2),
    half: rem(4),
    base: rem(8),
    base_x1_5: rem(12),
    base_x2: rem(16),
    base_x3: rem(24),
    base_x4: rem(32),
    base_x5: rem(40),
    base_x6: rem(48),
    base_x7: rem(56),
    base_x8: rem(64),
    base_x9: rem(72),
    base_x10: rem(80),
  },
  icons: {
    sizes: {
      base: 8,
      base_x1_5: 12,
      base_x2: 16,
      base_x3: 24,
      base_x4: 32,
      base_x5: 40,
      base_x6: 48,
      base_x7: 56,
      base_x8: 64,
    },
  },
  name: "Default",
  zIndex: {
    modal: 200,
    loadingIndicator: 200,
    twaBottomSheet: 10,
    bottomSheet: 10,
    header: 5,
    bottomNavigation: 2,
    corruptedSession: 2,
  },
};

export default theme;
