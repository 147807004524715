import { Record } from "immutable";

import { defaultValues } from "@config/soft.defaultValues";

import { EmployerAccount, PersonalDetails } from "./accountCreation.typings";

export const personalDetailsInitialState = {
  title: defaultValues.accountCreation.personalDetails.title,
  firstName: defaultValues.accountCreation.personalDetails.firstName,
  lastName: defaultValues.accountCreation.personalDetails.lastName,
  phoneNumber: defaultValues.accountCreation.personalDetails.phoneNumber,
  email: defaultValues.accountCreation.personalDetails.email,
  password: defaultValues.accountCreation.personalDetails.password,
  birthDate: defaultValues.accountCreation.personalDetails.birthDate,
};

export const mainLocationInitialState = {
  address: defaultValues.accountCreation.mainLocation.address,
  serviceLocationId:
    defaultValues.accountCreation.mainLocation.serviceLocationId,
  city: defaultValues.accountCreation.mainLocation.city,
  zip: defaultValues.accountCreation.mainLocation.zip,
};

export const personalDetailsFactory = (
  personalDetails: PersonalDetails,
): Record<PersonalDetails> =>
  Record(personalDetailsInitialState)({
    ...personalDetails,
  });

export const legalLocationInitialState = {
  address: defaultValues.accountCreation.legalLocation.address,
  serviceLocationId:
    defaultValues.accountCreation.legalLocation.serviceLocationId,
  city: defaultValues.accountCreation.legalLocation.city,
  zip: defaultValues.accountCreation.legalLocation.zip,
};

export const employerAccountInitialState = {
  svaOffice: defaultValues.accountCreation.employerAccount.svaOffice,
  affiliateNumber:
    defaultValues.accountCreation.employerAccount.affiliateNumber,
  svaNumber: defaultValues.accountCreation.employerAccount.svaNumber,
};

export const employerAccountFactory = (
  employerAccount: EmployerAccount,
): Record<EmployerAccount> =>
  Record(employerAccountInitialState)({
    ...employerAccount,
  });
