export const ADMIN_DASHBOARD_LINK: string = "/admin/dashboard/";
export const ADMIN_LOGOUT_LINK: string = "/admin/crm/{uuid}?_switch_user=_exit";
export const BOOKINGS_TO_VALIDATE_LINK: string =
  "/client/booking/validate/list";
export const PROFILE_LINK: string = "/profile";
export const GIFT_CARDS_LINK: string = "/gift-card";
export const HELP_LINK_BATMAID: string = "/help-section";
export const HELP_LINK_BATWORK: string = "/help-section";
export const HELP_LINK_BATSOFT: string = "/help-section";
export const BECOME_A_BATMAID_LINK = "/become-a-batmaid";
export const BECOME_A_BATWORKER_LINK = "/become-a-batworker";
export const CONTACT_LINK_BATMAID = "/contact-us";
export const CONTACT_LINK_BATMAID_LEGACY = "/batmaid-contact-us";
export const CONTACT_LINK_BATWORK: string = "/batwork-contact-us";
export const CONTACT_LINK_BATSOFT: string = "/batsoft-contact-us";
export const CANCELLATION_CONDITION_LINK: string = "/cancellation-conditions";
export const EDIT_BOOKING_LINK: string = "/client/edit/initialize/{uuid}";
export const OUR_SERVICES_GLOBAL_LINK = "/our-services";
export const BECOME_A_BATMAID_GLOBAL_LINK = "/become-batmaid";
export const ABOUT_BATMAID_GLOBAL_LINK = "/about-batmaid";

export const LOGIN_WITH_FACEBOOK_LINK = "/remember-url-on-login/facebook";
export const LOGIN_WITH_GOOGLE_LINK = "/remember-url-on-login/google";

// MIGROS_LINK is connected directly with @services/GetPartnerLink/GetPartnerLink.ts
export const MIGROS_LINK =
  "https://www.migros.ch/{{lang}}/category/laundry-cleaning?utm_source=batmaid&utm_medium=referral&utm_term={{utmTerm}}";

export const CALENDLY_B2B_LINK = "https://calendly.com/batmaid-b2b";
export const BATMAID_CLUB_LINK = "https://batmaidclub.ch/";

export const DEFAULT_PROFILE_PICTURE_LINK =
  "/uploads/media/profile/picture/default.png";

export const BATSOFT_LINK = `https://${process.env.BATSOFT_HOST}/{{lang}}`;
export const BATWORK_LINK = `https://${process.env.BATWORK_HOST}/{{lang}}`;
export const BATMAID_LINK = `https://${process.env.BATMAID_HOST}/{{lang}}`;

export const AGENT_SCHEDULE_LINK = "/agent/schedule";
export const AGENT_MY_CLEANINGS_LINK = "/agent/booking";
export const AGENT_NEW_CLEANINGS_LINK = "/agent/booking/new";
export const AGENT_AVAILABILITIES_LINK = "/agent/availability";
export const AGENT_PROFILE_LINK = "/profile"; // used in Batsoft
export const AGENT_LOGOUT_LINK = "/cleanup-session-on-logout";

export const AGENT_SWITCH_USER_LINK =
  "/agent/booking/?_switch_user={email}&selected_country=CH";
export const CLIENT_SWITCH_USER_LINK =
  "/admin_redirect/?target=/{lang}/client%23/client/my-cleanings&_switch_user={email}";
