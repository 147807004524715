import { Map, Record } from "immutable";

export enum AGENT_COMPENSATION_TYPE {
  FULL = "full",
  ONE_HOUR = "one_hour",
  CUSTOM_AMOUNT = "custom_amount",
}

export interface HolidayBase {
  startDate: string;
  endDate: string;
  skipCleanings: boolean;
  compensateAgent: boolean;
}

export interface Holiday extends HolidayBase {
  id: string;
  visible: boolean;
  agentCompensationAmount: number;
  agentCompensationType?: AGENT_COMPENSATION_TYPE;
  calendarStartDate?: string;
}

export type Holidays = Map<string, Record<Holiday>>;

export type HolidayReducer = Record<{
  holidays: Holidays;
  processedHoliday: Record<Holiday> | null;
}>;
