import { AnyAction } from "redux";
import { Record, List } from "immutable";

import { defaultValues } from "@config/soft.defaultValues";

import { BookingPricing } from "@typings/booking";

import { getQueryValue } from "@services/QueryParamsParser";
import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import {
  BookingReducer,
  BookingReducerState,
  BookingAvailability,
  BookingAddressForm,
} from "./booking.typings";
import { BOOKING_ACTIONS } from "./booking.actions";
import {
  bookingTasks,
  pricingInitialState,
  bookingAvailabilityInitialState,
  bookingAvailabilityFactory,
  agentsFactory,
  bookingFormInitialState,
  bookingFromContractFactory,
} from "./booking.factories";

export const getServiceLocationIdFromUrl = (url: string): number =>
  !isNaN(Number(getQueryValue(url, "serviceLocationId")))
    ? Number(getQueryValue(url, "serviceLocationId"))
    : defaultValues.booking.serviceLocationId;

const initialState = Record<BookingReducerState>({
  serviceLocationId: defaultValues.booking.serviceLocationId,
  onceBookingType: defaultValues.booking.onceBookingType,
  contractUuid: defaultValues.booking.contractUuid,
  tasks: bookingTasks,
  keyPick: defaultValues.booking.keyPick,
  locationComment: defaultValues.booking.locationComment,
  hasPets: defaultValues.booking.pets.selectedOptionValue,
  specialInstructions: defaultValues.booking.specialInstructions,
  frequency: defaultValues.booking.cleaningFrequency,
  pricing: Record<BookingPricing>(pricingInitialState)(),
  calendar: Record<BookingAvailability>(bookingAvailabilityInitialState)(),
  executionDateTime: defaultValues.booking.executionDateTime,
  queryParams: defaultValues.booking.queryParams,
  locationUuid: defaultValues.booking.locationUuid,
  mainLocationUuid: defaultValues.booking.mainLocationUuid,
  hasSomePaymentAliases: defaultValues.booking.hasSomePaymentAliases,
  previousEmployee: defaultValues.booking.previousEmployee,
  hasPreviousActiveContract: defaultValues.booking.hasPreviousActiveContract,
  preselectedAgentUuid: defaultValues.booking.preselectedAgentUuid,
  agentPreference: defaultValues.booking.agentPreference,
  previousAgents: List(),
  isChoosingAnyEmployee: defaultValues.booking.isChoosingAnyEmployee,
  form: Record<BookingAddressForm>(bookingFormInitialState)(),
});

const bookingReducer = (
  store: BookingReducer = initialState(),
  action: AnyAction,
): BookingReducer => {
  const { payload, type } = action;

  switch (type) {
    case BOOKING_ACTIONS.SET_FROM_LOCAL_STORAGE: {
      return action.payload || store;
    }
    case BOOKING_ACTIONS.SET_SERVICE_LOCATION_ID:
      return store.set("serviceLocationId", payload);
    case BOOKING_ACTIONS.SET_CONTRACT_UUID:
      return store.set("contractUuid", payload);
    case BOOKING_ACTIONS.SET_ONCE_BOOKING_TYPE:
      return store.set("onceBookingType", payload);
    case BOOKING_ACTIONS.SET_TASK: {
      return store.mergeIn(["tasks", payload.service], {
        ...payload,
      });
    }
    case BOOKING_ACTIONS.SET_KEY_PICK: {
      return store.set("keyPick", payload);
    }
    case `${BOOKING_ACTIONS.FETCH_PAYMENT_ALIASES}${ACTION_SUCCESS}`: {
      return store.set("hasSomePaymentAliases", payload.data.length > 0);
    }
    case BOOKING_ACTIONS.SET_LOCATION_COMMENT: {
      return store.set("locationComment", payload);
    }
    case BOOKING_ACTIONS.SET_PETS: {
      return store.set("hasPets", payload);
    }
    case BOOKING_ACTIONS.SET_SPECIAL_INSTRUCTIONS: {
      return store.set("specialInstructions", payload);
    }
    case BOOKING_ACTIONS.SET_FREQUENCY: {
      return store.set("frequency", payload.frequency);
    }
    case `${BOOKING_ACTIONS.FETCH_ONCE_BOOKING_AVAILABILITY}${ACTION_SUCCESS}`: {
      return store.set("calendar", bookingAvailabilityFactory(payload.data));
    }
    case BOOKING_ACTIONS.SET_WEEK_START_DATE: {
      return store.setIn(["calendar", "weekStartDate"], payload);
    }
    case BOOKING_ACTIONS.SET_EXECUTION_DATETIME: {
      return store.set("executionDateTime", payload);
    }
    case BOOKING_ACTIONS.SET_AGENT_PREFERENCE: {
      return store.set("agentPreference", payload.agentPreference);
    }
    case BOOKING_ACTIONS.SET_PRESELECTED_AGENT_UUID: {
      return store.set("preselectedAgentUuid", payload.preselectedAgentUuid);
    }
    case BOOKING_ACTIONS.SET_QUERY_PARAMS: {
      return store.set("queryParams", payload);
    }
    case BOOKING_ACTIONS.SET_LOCATION_UUID: {
      return store.set("locationUuid", payload);
    }
    case `${BOOKING_ACTIONS.ADD_LOCATION}${ACTION_SUCCESS}`: {
      return store.set("locationUuid", payload.data.uuid);
    }
    case `${BOOKING_ACTIONS.FETCH_PREVIOUS_AGENTS}${ACTION_SUCCESS}`: {
      return store.set("previousAgents", agentsFactory(payload.data));
    }
    case `${BOOKING_ACTIONS.SET_FORM}`: {
      return store.mergeIn(["form"], payload);
    }
    case `${BOOKING_ACTIONS.SET_BOOKING_STATE_FROM_CONTRACT}`: {
      return store.merge(bookingFromContractFactory(payload, bookingTasks));
    }
    case BOOKING_ACTIONS.SET_IS_CHOOSING_ANY_EMPLOYEE: {
      return store.set("isChoosingAnyEmployee", payload);
    }
    default:
      break;
  }

  return store;
};

export { bookingReducer };
