import { List, Record } from "immutable";
import { AnyAction } from "redux";
import { isBefore } from "date-fns";

import {
  ACTION_FAIL,
  ACTION_SUCCESS,
} from "@services/AsyncActionCreatorFactory";

import { defaultValues } from "@config/soft.defaultValues";

import { Absence, AbsencesReducer, ABSENCE_UI_STATE } from "./absences.typings";
import { FETCH_EMPLOYEE_ABSENCES } from "./absences.actions";

const absencesReducer = (
  store: List<Record<Absence>> = defaultValues.absences,
  action: AnyAction,
): AbsencesReducer => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_EMPLOYEE_ABSENCES}${ACTION_SUCCESS}`: {
      const {
        data: { absences },
      } = payload;

      const transformedData = absences.map((item: Absence) => {
        const uiState = isBefore(new Date(), new Date(item.startDate))
          ? ABSENCE_UI_STATE.UPCOMING
          : ABSENCE_UI_STATE.PREVIOUS;

        return Record({
          uuid: "",
          agentName: "",
          absenceType: "",
          startDate: "",
          endDate: "",
          uiState: "",
        })({
          ...item,
          uiState,
        });
      });

      return List(transformedData);
    }

    case `${FETCH_EMPLOYEE_ABSENCES}${ACTION_FAIL}`: {
      return store;
    }

    default: {
      return store;
    }
  }
};

export { absencesReducer };
