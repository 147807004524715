import { Record } from "immutable";
import { AnyAction } from "redux";

import { defaultValues } from "@config/soft.defaultValues";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import {
  AccountCreationReducer,
  EmployerAccount,
} from "./accountCreation.typings";
import {
  personalDetailsInitialState,
  personalDetailsFactory,
  legalLocationInitialState,
  mainLocationInitialState,
  employerAccountInitialState,
  employerAccountFactory,
} from "./accountCreation.factories";
import {
  SET_PERSONAL_DETAILS,
  SET_MAIN_LOCATION_ADDRESS,
  SET_MAIN_LOCATION_CITY,
  SET_MAIN_LOCATION_ZIP,
  SET_MAIN_LOCATION_SERVICE_LOCATION_ID,
  SET_LEGAL_ADDRESS,
  SET_LEGAL_CITY,
  SET_LEGAL_ZIP,
  SET_LEGAL_SERVICE_LOCATION_ID,
  SET_IS_LEGAL_LOCATION_THE_SAME,
  SET_EMPLOYER_ACCOUNT,
  SET_HAS_EMPLOYER_ACCOUNT,
  PUT_LEGAL_LOCATION,
  PUT_EMPLOYER_INFO,
} from "./accountCreation.actions";
import { LegalLocation } from "./accountCreation.typings";

const initialState = Record<AccountCreationReducer>({
  personalDetails: Record(personalDetailsInitialState)(),
  mainLocation: Record(mainLocationInitialState)(),
  legalLocation: Record<LegalLocation>(legalLocationInitialState)(),
  employerAccount: Record<EmployerAccount>(employerAccountInitialState)(),
  isLegalLocationTheSame: defaultValues.accountCreation.isLegalLocationTheSame,
  isLegalLocationPut: defaultValues.accountCreation.isLegalLocationPut,
  hasEmployerAccount: defaultValues.accountCreation.hasEmployerAccount,
  isEmployerInfoPut: defaultValues.accountCreation.isEmployerInfoPut,
});

const accountCreationReducer = (
  store: Record<AccountCreationReducer> = initialState(),
  action: AnyAction,
): Record<AccountCreationReducer> => {
  const { type, payload } = action;

  switch (type) {
    case SET_PERSONAL_DETAILS: {
      return store.set("personalDetails", personalDetailsFactory(payload));
    }

    /** Main Location */
    case SET_MAIN_LOCATION_ADDRESS: {
      return store.setIn(["mainLocation", "address"], payload);
    }
    case SET_MAIN_LOCATION_CITY: {
      return store.setIn(["mainLocation", "city"], payload);
    }
    case SET_MAIN_LOCATION_ZIP: {
      return store.setIn(["mainLocation", "zip"], payload);
    }
    case SET_MAIN_LOCATION_SERVICE_LOCATION_ID: {
      return store.setIn(["mainLocation", "serviceLocationId"], payload);
    }

    /** Legal Location */
    case SET_LEGAL_ADDRESS: {
      return store.setIn(["legalLocation", "address"], payload);
    }
    case SET_LEGAL_CITY: {
      return store.setIn(["legalLocation", "city"], payload);
    }
    case SET_LEGAL_ZIP: {
      return store.setIn(["legalLocation", "zip"], payload);
    }
    case SET_LEGAL_SERVICE_LOCATION_ID: {
      return store.setIn(["legalLocation", "serviceLocationId"], payload);
    }
    case SET_IS_LEGAL_LOCATION_THE_SAME: {
      return store.set("isLegalLocationTheSame", payload);
    }

    /** Employer Account */
    case SET_EMPLOYER_ACCOUNT: {
      return store.set("employerAccount", employerAccountFactory(payload));
    }
    case SET_HAS_EMPLOYER_ACCOUNT: {
      return store.set("hasEmployerAccount", payload);
    }

    case `${PUT_LEGAL_LOCATION}${ACTION_SUCCESS}`: {
      return store.set("isLegalLocationPut", true);
    }

    case `${PUT_EMPLOYER_INFO}${ACTION_SUCCESS}`: {
      return store.set("isEmployerInfoPut", true);
    }

    default: {
      return store;
    }
  }
};

export { accountCreationReducer };
