import { Map, Record } from "immutable";
import { formatISO, roundToNearestMinutes } from "date-fns";

import { AVAILABLE_CURRENCIES } from "@typings/globals";
import { CLEANING_FREQUENCY, KEY_PICK } from "@typings/booking";
import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";
import { TERMINATION_PERIOD } from "@typings/bookingsToValidate";
import {
  Contract,
  Contracts,
  Employee,
  Employees,
  EmployeeDetails,
} from "@typings/contracts";

export const initialEmployeeDetails: EmployeeDetails = {
  firstName: "",
  lastName: "",
  profilePictureUrl: null,
  email: null,
  dateOfBirth: null,
  homePhone: null,
  mobilePhone: null,
  address: "",
  zip: "",
  city: "",
  workPermit: "",
  socialNumber: "",
  bankAccount: null,
  contracts: [],
};

export const contractInitialState: Contract = {
  agreeToProfessionalAccidentInsurance: true,
  bathrooms: 1,
  bedrooms: 1,
  keyPick: KEY_PICK.SOMEONE_AT_HOME,
  locationComment: "",
  specialInstructions: "",
  contractUuid: "",
  agentUuid: null,
  locationUuid: "",
  employeeName: null,
  employeeEmail: "",
  employeeProfilePictureUrl: "",
  employeePhoneNumber: null,
  startDateTime: formatISO(
    roundToNearestMinutes(new Date(0), { nearestTo: 30 }),
  ),
  creationDateTime: "",
  terminationDate: null,
  frequency: CLEANING_FREQUENCY.WEEKLY,
  cleaningHours: "0",
  tasks: [],
  grossSalary: 0,
  serviceFee: 0,
  currency: AVAILABLE_CURRENCIES.CHF,
  terminationPeriod: TERMINATION_PERIOD.SEVEN_DAYS,
  earliestLegalTerminationDate: "",
  address: "",
  isTerminated: false,
  isPending: false,
  totalHoursDone: 0,
  allowedActions: [],
  canChangeLegalLocation: false,
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT.NONE,
  sicknessInsurance: SICKNESS_INSURANCE.FIFTY_FIFTY,
  serviceLocationId: 0,
  insurancePolicy: false,
  insurancePolicyNumber: "",
  insurancePolicyWillBeProvidedLater: false,
};

export const ContractsFactory = (
  input: Contract[],
  initialData: Contracts = Map(),
  agentUuidFilter?: string,
): Contracts => {
  const resolvedData = agentUuidFilter
    ? input.filter(ctrct => ctrct.agentUuid === agentUuidFilter)
    : input;

  return resolvedData.reduce(
    (acc: Map<string, Record<Contract>>, contract: Contract) =>
      acc.set(
        contract.contractUuid,
        Record(contractInitialState)({
          ...contract,
        }),
      ),
    initialData,
  );
};

export const EmployeeDetailsFactory = (
  input: EmployeeDetails,
): Record<EmployeeDetails> => Record(initialEmployeeDetails)(input);

export const EmployeeFactory = (
  contract: Contract,
  filteredContracts: Contract[],
): Record<Employee> => {
  const employeeInitialState: Employee = {
    agentUuid: null,
    employeeName: null,
    employeeEmail: "",
    employeePhoneNumber: null,
    employeeProfilePictureUrl: "",
    contracts: Map(),
    employeeDetails: null,
  };

  return Record(employeeInitialState)({
    agentUuid: contract.agentUuid,
    employeeName: contract.employeeName,
    employeeEmail: contract.employeeEmail,
    employeePhoneNumber: contract.employeePhoneNumber,
    employeeProfilePictureUrl: contract.employeeProfilePictureUrl,
    contracts: ContractsFactory(
      filteredContracts,
      Map(),
      String(contract.agentUuid),
    ),
    employeeDetails: null,
  });
};

export const EmployeesFactory = (
  input: Contract[],
  initialData: Employees = Map(),
): Employees => {
  const filteredContracts = input.filter(contract => contract.agentUuid);

  return filteredContracts.reduce(
    (acc: Map<string, Record<Employee>>, contract: Contract) =>
      acc.set(
        String(contract.agentUuid),
        EmployeeFactory(contract, filteredContracts),
      ),
    initialData,
  );
};
