import { AppConfig } from "@typings/appConfig";

import {
  CLEANING_DURATION_MATRIX_BE,
  CLEANING_DURATION_MATRIX_BE_SERVICE_VOUCHERS,
  CURRENCY_PER_COUNTRY,
} from "@config/consts";
import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";
import { FREQUENCY } from "@typings/b2b";

const config: AppConfig = {
  currency: CURRENCY_PER_COUNTRY[AVAILABLE_COUNTRIES.BE],
  booking: {
    durationMatrix: CLEANING_DURATION_MATRIX_BE,
    commitments: {
      enabled: false,
    },
    serviceVouchers: {
      enabled: true,
      step: 1,
      minBookingTime: 3,
      minBookingTimeAdmin: 1,
      durationMatrix: CLEANING_DURATION_MATRIX_BE_SERVICE_VOUCHERS,
    },
    whatIsIncluded: {
      headers: [
        "periodicity_once",
        "periodicity_weekly",
        "periodicity_every_2_weeks",
      ],
      rows: [
        [false, true, true],
        [true, true, true],
        [true, true, true],
        [false, true, true],
        [true, true, true],
      ],
    },
  },
  b2b: {
    contactDetails: {
      phone: "+32 2 808 14 45",
      email: "client@batmaid.be",
    },
    disabledFrequencies: [FREQUENCY.EVERY_4_WEEKS],
  },
  refRewardAmount: 20,
  referralEnabled: false,
  welcomeRewardAmount: 20,
  marketingConsentEnabled: true,

  helpCenter: {
    reportDamageUrl: {
      [AVAILABLE_LANGS.EN]:
        "https://docs.google.com/forms/d/e/1FAIpQLScs1uyJb_N2j8p82VDlCVunO4bTPn70BSOnJkjfMXd7Nvdomw/viewform",
      [AVAILABLE_LANGS.FR]:
        "https://docs.google.com/forms/d/e/1FAIpQLSfPO6S92EU2HVGIFv9FZ13GuqSKz1FL3dJ00Wf87Xm8VrDRJA/viewform",
      // [AVAILABLE_LANGS.NL]: "https://docs.google.com/forms/d/e/1FAIpQLSfPO6S92EU2HVGIFv9FZ13GuqSKz1FL3dJ00Wf87Xm8VrDRJA/viewform",
    },
  },
};

export { config as be };
