/**
 * @author tomekbuszewski
 * @since 2019-6-24
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { rem, transition } from "@ui/helpers";
import { Check as CheckIcon } from "@ui/Assets/Symbolicons/Check";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { FormError } from "@ui/Atoms/Form/FormError";
import { Label, LabelWrapper } from "@ui/Atoms/Form/Label/Label";
import { ITheme } from "@ui/themes/ThemeInterface";
import {
  ExtendedInputProps,
  INPUT_ERROR_CLASS,
} from "@ui/Atoms/Form/Form.types";
import theme from "@ui/themes/default";

interface Props {
  children?: React.ReactNode;
  checked?: boolean;
  isDisabled?: boolean;
  theme: ITheme;
}

interface CheckboxProps extends ExtendedInputProps {
  labelTestId?: string;
  testId?: string;
  className?: string;
  inline?: boolean;
}

const Checkbox = (props: CheckboxProps): React.ReactElement => (
  <React.Fragment>
    <LabelWrapperStyled
      asCheck
      inline={props.inline}
      className={props.className}
      isDisabled={props.disabled}
    >
      <TestWrapper testId={props.testId}>
        <PristineCheckbox
          type="checkbox"
          isDisabled={props.disabled}
          id={props.name}
          className={
            props.errors
              ? `${props.className} ${INPUT_ERROR_CLASS}`
              : props.className
          }
          {...props}
        />
      </TestWrapper>
      <CheckElement isDisabled={props.disabled}>
        <CheckIcon
          size={10}
          variant="solid"
          color={props.disabled ? theme.colors.whiteBorder : theme.colors.white}
        />
      </CheckElement>
      <TestWrapper testId={props.labelTestId}>
        <StyledLabel
          htmlFor={props.name}
          active={props.checked || !!props.value}
          isDisabled={props.disabled}
        >
          {props.label}
        </StyledLabel>
      </TestWrapper>
    </LabelWrapperStyled>
    {props.errors && <FormError>{props.errors}</FormError>}
  </React.Fragment>
);

const LabelWrapperStyled = styled(LabelWrapper)`
  --pristine-checkbox-size: ${rem(16)};
  --pristine-checkbox-position: ${rem(4.5)};

  ${props =>
    props.inline &&
    css`
      align-items: center;
    `}

  > label {
    ${props =>
      props.inline &&
      css`
        padding-top: 0;
        padding-bottom: 0;
      `}
  }

  > input {
    ${props =>
      props.inline &&
      css`
        top: 50%;
        transform: translateY(-50%);
      `}
  }
`;

const PristineCheckbox = styled.input<Props>`
  top: 0;
  margin-top: var(--pristine-checkbox-position);
  width: var(--pristine-checkbox-size);
  height: var(--pristine-checkbox-size);
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  opacity: 0;
  position: absolute;
  z-index: 10;

  &:disabled {
    opacity: 0;
  }

  ${props =>
    props.value &&
    css`
      + span {
        border-color: ${props.theme.colors.blue400};

        &::after {
          opacity: 1;
        }
      }
    `}
`;

const CheckElement = styled.span<Props>`
  transition: ${transition(["background", "border"])};

  position: relative;
  margin-top: var(--pristine-checkbox-position);
  width: var(--pristine-checkbox-size);
  height: var(--pristine-checkbox-size);

  border: ${rem(2)} solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.buttons.borderRadius};
  background: ${props => props.theme.colors.white};

  ${PristineCheckbox}:not(:disabled):not(:checked):hover + & {
    border-color: ${props => props.theme.colors.black500};
  }
  ${PristineCheckbox}:not(:disabled):checked + & {
    border-color: ${props => props.theme.colors.blue400};
  }
  ${PristineCheckbox}:disabled + & {
    background: ${props => props.theme.colors.whiteBorder};
  }

  &::after {
    transition: ${transition(["opacity", "background-color"])};

    position: absolute;
    content: "";
    display: block;

    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    border-radius: 0;
    background-color: ${props => props.theme.colors.blue400};
    opacity: 0;

    ${PristineCheckbox}:disabled + &,
    ${PristineCheckbox}:disabled:hover + & {
      background-color: ${props => props.theme.colors.grey300};
    }

    ${PristineCheckbox}:checked + & {
      opacity: 1;
    }
  }

  & > svg {
    fill: ${props => props.theme.colors.white};
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${rem(8)};

    ${PristineCheckbox}:disabled + & {
      fill: ${props => props.theme.colors.whiteBorder};
    }
  }
`;

const StyledLabel = styled(Label)`
  ${props =>
    props.isDisabled &&
    css`
      color: ${props => props.theme.colors.grey500};
    `}
`;

export { Checkbox, PristineCheckbox };

Checkbox.displayName = "Checkbox";
