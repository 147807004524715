import { PROMO_CODE_NOTIFICATION } from "@typings/globals";

export enum EMPLOYER_DATA {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  ADDRESS = "employerAddress",
  FLOOR_AND_DOOR = "employerFloorAndDoor",
  ENTRY_CODE = "entryCode",
  CITY = "employerCity",
  PHONE_NUMBER = "phoneNumber",
  ADDITIONAL_NUMBER = "additionalPhoneNumber",
  BIRTH_DATE = "birthDate",
}

export enum LEGAL_LOCATION {
  IS_LEGAL_ADDRESS_THE_SAME = "isLegalAddressTheSame",
  LEGAL_ADDRESS = "address",
  LEGAL_FLOOR_AND_DOOR = "floorAndDoor",
  LEGAL_ZIP = "zipCode",
  LEGAL_CITY = "city",
}

export enum VALIDATE_CONTRACT_FORM {
  TERMS = "terms",
  COMMITMENT = "commitment",
  PAYMENT_METHOD = "paymentMethod",
}
export interface VoucherInfo {
  voucherMessage: string;
  voucherStatus: PROMO_CODE_NOTIFICATION;
}

export enum TERMINATION_PERIOD {
  SEVEN_DAYS,
  ONE_MONTH,
  TWO_MONTHS,
  THREE_MONTHS,
}
