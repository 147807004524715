import { Record, List } from "immutable";

import { defaultValues } from "@config/work.defaultValues";

import {
  SicknessInsuranceCosts,
  SecondPillarRetirementCosts,
  SalaryDefaults,
  BookingSalaryRaw,
  BookingSalary,
  ContributionsItemRaw,
  ContributionsItem,
} from "@typings/salary";

export const sicknessInsuranceCostsFactory = (
  input: SicknessInsuranceCosts,
): Record<SicknessInsuranceCosts> =>
  Record<SicknessInsuranceCosts>({
    fiftyFifty: 0,
    fullCoverageByEmployer: 0,
  })({
    ...input,
  });

export const secondPillarRetirementCostsFactory = (
  input: SecondPillarRetirementCosts,
): Record<SecondPillarRetirementCosts> =>
  Record<SecondPillarRetirementCosts>({
    none: 0,
    fiftyFifty: 0,
    fullCoverageByEmployer: 0,
  })({
    ...input,
  });

export const salaryDefaultsFactory = (
  input: SalaryDefaults,
): Record<SalaryDefaults> =>
  Record<SalaryDefaults>({ ...defaultValues.booking.salaryDefaults })({
    ...input,
  });

export const registerFormInitialState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  address: "",
  serviceLocationId: 0,
  floorAndDoor: "",
  entryCode: "",
  phoneNumber: "",
  birthDate: "01.01.1970",
  additionalNumber: "",
};

const contributionFactory = (
  item: ContributionsItemRaw,
): Record<ContributionsItem> =>
  Record({
    shortName: "",
    value: 0,
    longDescription: "",
  })(item);

export const salaryFactory = (
  input: BookingSalaryRaw,
): Record<BookingSalary> => {
  return Record(defaultValues.booking.salary)({
    ...input,
    employerContributions: List(
      input.employerContributions.map(contributionFactory),
    ),
    employerContributionsTotal: input.employerContributions.reduce(
      (acc: number, item: any) => acc + item.value,
      0,
    ),
    employeeContributions: List(
      input.employeeContributions.map(contributionFactory),
    ),
    employeeContributionsTotal: input.employeeContributions.reduce(
      (acc: number, item: any) => acc + item.value,
      0,
    ),
  });
};
