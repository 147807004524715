import { Record, Map } from "immutable";

import { AUTH_REDIRECTS } from "./globals";

export enum COUNTRY_ISO_ENUM {
  LU = "LU",
  CH = "CH",
  FR = "FR",
  PL = "PL",
  IT = "IT",
  DE = "DE",
  AT = "AT",
  BE = "BE",
  NL = "NL",
  UK = "UK",
  US = "US",
}

export interface LocationRaw {
  uuid: string;
  zip: string;
  address: string;
  state: string;
  country: string;
  city: string;
  countryIso: string;
  firstName: string;
  lastName: string;
  entryCode: string;
  keyPick: string; // This should be KEY_PICK_ENUM, but there's a bug in the API, more info in swagger.yml
  howToGetIn: string;
  hasPets: boolean;
  hideCityName: boolean;
  floorAndDoor?: string; // only used in client locations
  invoicedCompany?: string; // only used in client locations
  companyName?: string; // only used in b2b locations
  previousJobs: number;
  upcomingJobs: number;
  ongoingJobs: number;
  hasActiveContract: boolean;
}

export interface Location extends LocationRaw {
  countryIso: COUNTRY_ISO_ENUM;
}

export type LocationsCollection = Map<string, Record<Location>>;

export interface AuthGuardState {
  reason: AUTH_REDIRECTS;
  referer: string;
  originalRoute: string;
}

export interface ZipSuggestion {
  label: string;
  value: string;
}
