import { Record } from "immutable";
import { AnyAction } from "redux";

import { OAUTH_PROVIDERS, IOAuthState } from "@typings/oauth";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import {
  CONNECT_USER,
  LOGIN_BY_ACCESS_TOKEN,
  SET_USER_DATA,
} from "./oauth.actions";

const initialState = Record<IOAuthState>({
  accessToken: null,
  provider: OAUTH_PROVIDERS.DEFAULT,
  email: null,
  firstName: null,
  lastName: null,
  userType: null,
});

const oauthReducer = (
  store: Record<IOAuthState> = initialState(),
  action: AnyAction,
): Record<IOAuthState> => {
  const { type, payload } = action;

  switch (type) {
    case `${CONNECT_USER}${ACTION_SUCCESS}`: {
      return store;
    }

    case `${LOGIN_BY_ACCESS_TOKEN}${ACTION_SUCCESS}`: {
      return store.set("userType", payload.data.userType);
    }

    case SET_USER_DATA: {
      return store.merge(payload);
    }

    default: {
      return store;
    }
  }
};

export { oauthReducer };
