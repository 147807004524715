import { Map, Record } from "immutable";

import { defaultValues } from "@config/soft.defaultValues";

import {
  IssueDTO,
  SubIssue,
  IssuesMap,
  SubIssuesMap,
  RatingDTO,
  IssueKey,
  SubIssueKey,
  Picture,
  PicturesMap,
} from "@typings/rating";
import { LOADING_STATE } from "@typings/reduxThunkActions";
import { SERVICE_TYPE } from "@typings/globals";

import { RatingReducer } from "./rating.typings";

const subIssuesFactory = (input: SubIssue[]): SubIssuesMap => {
  const subIssues = input.reduce(
    (acc: SubIssuesMap, subIssue: SubIssue) =>
      acc.set(subIssue.key, Record(subIssue)()),
    Map(),
  );

  return subIssues;
};

const issuesFactory = (input: IssueDTO[]): IssuesMap => {
  const issues = input.reduce(
    (acc: IssuesMap, issue: IssueDTO) =>
      acc.set(
        issue.key,
        Record({
          key: "" as IssueKey,
          name: "",
          selected: false,
          subIssues: Map() as SubIssuesMap,
        })({
          ...issue,
          key: issue.key as IssueKey,
          subIssues: subIssuesFactory(issue.subIssues),
        }),
      ),
    Map(),
  );

  return issues;
};

const picturesFactory = (
  input: Picture[],
  initialState?: PicturesMap,
): PicturesMap =>
  input.reduce(
    (acc: PicturesMap, picture: Picture) =>
      acc.set(picture.thumbnailUrl, Record(picture)()),
    initialState || Map(),
  );

const ratingFactory = (
  input: RatingDTO,
  initialState?: Record<RatingReducer>,
): Record<RatingReducer> => {
  return Record<RatingReducer>({
    job: {
      uuid: "",
      startDate: new Date(),
      endDate: new Date(),
      mainServiceType: "" as SERVICE_TYPE,
    },
    rating: 0,
    negativeRatings: [],
    positiveRatings: [],
    negativeIssues: Map(),
    positiveIssues: Map(),
    comment: "",
    pictures: Map(),
    shareWithAgent: true,
    loadingState: LOADING_STATE.IDLE,
    showReferral: false,
    allowPictures: false,
    editable: false,
    maxPictures: 0,
    pictureErrorMessage: "",
  })({
    ...initialState,
    ...input,
    rating: Number(input.rating),
    job: {
      uuid: input.job.uuid,
      startDate: new Date(input.job.startDate),
      endDate: new Date(input.job.endDate),
      mainServiceType: input.job.mainServiceType as SERVICE_TYPE,
    },
    negativeIssues: issuesFactory(input.negativeIssues),
    positiveIssues: issuesFactory(input.positiveIssues),
    loadingState: LOADING_STATE.SUCCEEDED,
    comment: input.comment || "",
    shareWithAgent: !input.rating
      ? defaultValues.rating.shareWithAgent // default true for new ratings
      : input.shareWithAgent,
    pictures: picturesFactory(input.pictures),
  });
};

const unselectedSubIssuesFactory = (input: SubIssuesMap): SubIssuesMap => {
  const subIssues = input.valueSeq().reduce(
    (acc: SubIssuesMap, subIssue: Record<SubIssue>) =>
      acc.set(
        subIssue.get("key"),
        Record({ key: "" as SubIssueKey, name: "", selected: false })({
          ...subIssue.toJS(),
          selected: false,
        }),
      ),
    Map(),
  );

  return subIssues;
};

export {
  issuesFactory,
  subIssuesFactory,
  ratingFactory,
  unselectedSubIssuesFactory,
  picturesFactory,
};
