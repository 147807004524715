import { Record } from "immutable";
import { AnyAction } from "redux";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import { defaultValues } from "@config/soft.defaultValues";

import {
  FETCH_PAYMENT_STATUS,
  SET_REFERENCE_NUMBER,
  SET_ORIGIN_ROUTE,
  SET_TARGET_ROUTE,
} from "./payment.actions";
import { PaymentReducer } from "./payment.typings";
import { paymentOriginRouteFactory } from "./payment.factories";

const initialState = Record<PaymentReducer>({
  status: defaultValues.payment.status,
  referenceNumber: defaultValues.payment.referenceNumber,
  publicMessage: defaultValues.payment.publicMessage,
  originRoute: defaultValues.payment.originRoute,
  targetRoute: defaultValues.payment.targetRoute,
});

const paymentReducer = (
  store: Record<PaymentReducer> = initialState(),
  action: AnyAction,
): Record<PaymentReducer> => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_PAYMENT_STATUS}${ACTION_SUCCESS}`: {
      return store
        .set("status", payload.data.status)
        .set("publicMessage", payload.data.publicMessage);
    }
    case SET_REFERENCE_NUMBER: {
      return store.set("referenceNumber", payload);
    }
    case SET_TARGET_ROUTE: {
      return store.set("targetRoute", payload);
    }
    case SET_ORIGIN_ROUTE: {
      return store.set(
        "originRoute",
        paymentOriginRouteFactory(
          payload.originRoute,
          payload.serviceLocationId,
        ),
      );
    }
    default: {
      return store;
    }
  }
};

export { paymentReducer };
