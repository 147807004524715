import { GatsbyBrowser, Script, ScriptStrategy } from "gatsby";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { I18nextProvider } from "react-i18next";

import { initSentry } from "@config/sentry";
import { GOOGLE_LOGIN_CLIENT_ID } from "@config/consts";
import { initGTM } from "@config/gtm";
import i18next from "@config/i18next";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { ConfigProvider } from "@hooks/useConfig";
import { FeatureToggleProvider } from "@hooks/useFeatureToggle";
import { TelemetryProvider } from "@hooks/useTelemetry";

import { repositoryConfigs } from "./src/config/repositoryConfig";
import { Providers } from "./wrap-page-with-provider";
import { getFeatureToggles } from "./gatsby-fts-loader";

const wrapPageElement: GatsbyBrowser["wrapPageElement"] = Providers;

const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  initSentry((process.env.ORIGIN || ORIGIN.BATMAID) as ORIGIN);
};

const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] = () => {
  window.history.scrollRestoration = "manual";
  window.scrollTo(0, 0);
};

// wrapRootElement MUST RETURN THE SAME COMPONENTS IN gatsby-browser & gatsby-ssr
const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <React.Fragment>
      <I18nextProvider i18n={i18next}>
        <ConfigProvider
          country={
            (process.env.COUNTRY as AVAILABLE_COUNTRIES) ||
            AVAILABLE_COUNTRIES.CH
          }
        >
          <TelemetryProvider>
            <FeatureToggleProvider
              builtConfig={getFeatureToggles()}
              disableLocalStorageData
            >
              <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
                <GoogleOAuthProvider clientId={String(GOOGLE_LOGIN_CLIENT_ID)}>
                  {element}
                </GoogleOAuthProvider>
              </PrismicPreviewProvider>
            </FeatureToggleProvider>
          </TelemetryProvider>
        </ConfigProvider>
      </I18nextProvider>
      <Script
        id="gtm-script"
        strategy={ScriptStrategy.offMainThread}
        forward={[`dataLayer.push`]}
      >
        {`${initGTM((process.env.ORIGIN || ORIGIN.BATMAID) as ORIGIN)}`}
      </Script>
    </React.Fragment>
  );
};

export {
  onClientEntry,
  wrapRootElement,
  wrapPageElement,
  onInitialClientRender,
};
