import { List, Map, OrderedMap } from "immutable";
import { formatISO, roundToNearestMinutes } from "date-fns";

import { PaymentAliasType } from "@typings/payments";
import { SICKNESS_INSURANCE, SECOND_PILLAR_RETIREMENT } from "@typings/salary";
import { GENDER_OPTS, PROMO_CODE_NOTIFICATION } from "@typings/globals";
import {
  EXTRA_SERVICES_BOOKING,
  KEY_PICK,
  CLEANING_FREQUENCY,
  AGENT_PREFERENCE,
  ONCE_BOOKING_TYPE,
} from "@typings/booking";
import { EMPLOYEE_VACATIONS } from "@typings/declareEmployee";
import { LOADING_STATE } from "@typings/reduxThunkActions";

import { IS_PROD } from "@config/consts";

export const defaultValues = {
  misc: {
    minPasswordLength: 6,
  },
  bookingsToValidate: {
    selectedPaymentMethod: null,
    contractId: "0",
    serviceLocationId: 0,
    voucherToken: "",
    voucherMessage: "",
    voucherStatus: PROMO_CODE_NOTIFICATION.NONE,
    isLegalLocationTheSame: true,
    isLegalLocationPut: false,
  },
  addTip: {
    minValue: 1,
    maxValue: 50,
    step: 0.5,
  },
  addBonus: {
    minValue: 1,
    maxValue: 100000, // instead of infinity
    defaultValue: 20,
    step: 1,
  },
  profile: {
    referral: {
      referralCode: "",
      referralUrl: "",
      shareFacebookUrl: "",
      shareTwitterUrl: "",
    },
    accountBalance: {
      moneyBalance: {
        balance: 0,
        currency: "CHF",
      },
      durationBalance: 0,
    },
    basicInfo: {
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      homePhone: "",
      mobilePhone: "",
      oAuthProvider: null,
      serviceVoucher: null,
    },
    paymentAliases: {
      type: PaymentAliasType.VIS,
      maskedNumber: "",
      expirationDate: "",
      uuid: "",
      default: false,
    },
  },
  payment: {
    status: null,
    publicMessage: null,
    referenceNumber: "",
    originRoute: "",
    targetRoute: "",
  },
  salary: {
    reservationUuid: null,
    serviceLocationId: IS_PROD ? 0 : 4,
    isExecutionDateTimeValidated: false,
    chosenAgentId: null,
    chosenAgents: Map(),
    chosenAgentIds: List(),
    salarySlider: {
      step: 0.25,
      superGrossSalary: 0,
    },
    sicknessInsurance: SICKNESS_INSURANCE.FIFTY_FIFTY,
    sicknessInsuranceCosts: {
      fiftyFifty: 0,
      fullCoverageByEmployer: 0,
    },
    professionalAccidentInsurance: false,
    agreeToProfessionalAccidentInsurance: true,
    insurancePolicyNumber: "",
    insurancePolicyWillBeProvidedLater: false,
    secondPillarRetirement: SECOND_PILLAR_RETIREMENT.NONE,
    secondPillarRetirementCosts: {
      none: 0,
      fiftyFifty: 0,
      fullCoverageByEmployer: 0,
    },
    salaryDefaultsFetched: false,
    sliderChangeCount: 0,
    salaryDefaults: {
      minimumSuperGrossSalary: 0,
      minimumNetSalary: 0,
      defaultSuperGrossSalary: 0,
      maximumSuperGrossSalary: 50,
      secondPillarRetirementNone: 0,
      secondPillarRetirementFiftyFifty: 0,
      secondPillarRetirementFullCoverageByEmployer: 0,
      sicknessInsuranceFiftyFifty: 0,
      sicknessInsuranceFullCoverageByEmployer: 0,
      relaxPlanHourlyFee: 0,
      takeAndLeaveFee: 0,
    },
    salary: {
      superGrossSalary: 0,
      grossSalaryIncludingVacation: 0,
      netSalary: 0,
      holidayAllowance: 0,
      holidayAllowancePercent: 0,
      employerContributions: List(),
      employerContributionsTotal: 0,
      employeeContributions: List(),
      employeeContributionsTotal: 0,
    },
    cachePreviousData: false,
    cachedData: null,
  },
  absences: List(),
  booking: {
    contractUuid: "",
    onceBookingType: ONCE_BOOKING_TYPE.ONCE_JOB,
    hasSomePaymentAliases: false,
    bedroomNo: 1,
    bathroomNo: 1,
    keyPick: KEY_PICK.SOMEONE_AT_HOME,
    locationComment: "",
    specialInstructions: "",
    preselectedAgentUuid: "",
    agentPreference: AGENT_PREFERENCE.ANY,
    tasks: {
      [EXTRA_SERVICES_BOOKING.HOME_CLEANING]: {
        selected: true,
        duration: 2,
      },
      [EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.IRONING]: {
        selected: false,
        duration: 0.5,
        maxDuration: 11,
      },
      [EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY]: {
        selected: false,
        duration: 1,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_OVEN]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_CABINETS]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.BONUS_15]: {
        selected: false,
        duration: 0.25,
      },
    },
    homeCleaningTime: {
      max: 15,
      min: 2,
      step: 0.5,
    },
    homeCleaningTimeAdmin: {
      min: 0.5,
    },
    cleaningFrequency: CLEANING_FREQUENCY.WEEKLY,
    pets: {
      selectedOptionValue: false,
    },
    executionDateTime: formatISO(
      roundToNearestMinutes(new Date(0), { nearestTo: 30 }),
    ),
    reservationUuid: null,
    serviceLocationId: IS_PROD ? 0 : 1,
    chosenAgentIds: List(),
    salarySlider: {
      step: 0.25,
      superGrossSalary: 0,
    },
    chosenAgents: Map(),
    chosenAgentId: null,
    sicknessInsurance: SICKNESS_INSURANCE.FIFTY_FIFTY,
    sicknessInsuranceCosts: {
      fiftyFifty: 0,
      fullCoverageByEmployer: 0,
    },
    professionalAccidentInsurance: false,
    agreeToProfessionalAccidentInsurance: true,
    insurancePolicyNumber: "",
    insurancePolicyWillBeProvidedLater: false,
    secondPillarRetirement: SECOND_PILLAR_RETIREMENT.NONE,
    secondPillarRetirementCosts: {
      none: 0,
      fiftyFifty: 0,
      fullCoverageByEmployer: 0,
    },
    queryParams: "",
    locationUuid: "",
    mainLocationUuid: "",
    salaryDefaultsFetched: false,
    sliderChangeCount: 0,
    salaryDefaults: {
      minimumSuperGrossSalary: 0,
      minimumNetSalary: 0,
      defaultSuperGrossSalary: 0,
      maximumSuperGrossSalary: 50,
      secondPillarRetirementNone: 0,
      secondPillarRetirementFiftyFifty: 0,
      secondPillarRetirementFullCoverageByEmployer: 0,
      sicknessInsuranceFiftyFifty: 0,
      sicknessInsuranceFullCoverageByEmployer: 0,
      relaxPlanHourlyFee: 0,
      takeAndLeaveFee: 0,
    },
    salary: {
      superGrossSalary: 0,
      grossSalaryIncludingVacation: 0,
      netSalary: 0,
      holidayAllowance: 0,
      holidayAllowancePercent: 0,
      employerContributions: List(),
      employerContributionsTotal: 0,
      employeeContributions: List(),
      employeeContributionsTotal: 0,
    },
    previousEmployee: null,
    hasPreviousActiveContract: false,
    isChoosingAnyEmployee: false,
  },
  userNotifications: {
    pendingPaymentsAlert: false,
    remindMissingTrustMandate: false,
    showNewBookingReminder: false,
    hasBookingsToValidate: false,
    showHolidayAutomationAlert: false,
    trustMandateIsApproved: false,
    showMissingTrustMandate2020: false,
    showEndOfTenancyReservationWithoutJobs: false,
  },
  declareEmployee: {
    agentUuid: "",
    agentData: {
      displayName: "",
      phoneNumber: "",
    },
    employeeContactDetails: {
      serviceLocationId: 0,
    },
    employeeJobAddress: {
      serviceLocationId: 0,
    },
    serviceLocationId: 0,
    jobTime: {
      max: 15,
      min: 2,
      step: 0.5,
    },
    voucherToken: "",
    voucherMessage: "",
    voucherStatus: PROMO_CODE_NOTIFICATION.NONE,
    declarationUuid: "",
    paymentAliasId: "",
    vacations: EMPLOYEE_VACATIONS.FOUR_WEEKS,
    isSalaryChosen: false,
  },
  accountCreation: {
    personalDetails: {
      title: GENDER_OPTS.MALE,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      birthDate: "",
    },
    mainLocation: {
      address: "",
      serviceLocationId: 0,
      city: "",
      zip: "",
    },
    legalLocation: {
      address: "",
      serviceLocationId: 0,
      city: "",
      zip: "",
    },
    employerAccount: {
      svaOffice: "",
      affiliateNumber: "",
      svaNumber: "",
    },
    isLegalLocationTheSame: true,
    isLegalLocationPut: false,
    hasEmployerAccount: false,
    isEmployerInfoPut: false,
  },
  rating: {
    job: {
      uuid: "",
      startDate: new Date(),
      endDate: new Date(),
      mainServiceType: "",
    },
    rating: 0,
    negativeRatings: [],
    positiveRatings: [],
    negativeIssues: Map(),
    positiveIssues: Map(),
    comment: "",
    pictures: OrderedMap(),
    shareWithAgent: true,
    loadingState: LOADING_STATE.IDLE,
    showReferral: false,
    allowPictures: false,
    editable: false,
    maxPictures: 10,
    pictureErrorMessage: "",
  },
};
