/**
 * @author jakubbujakowski
 * @since 2021-04-01
 *
 * Because of Apple's environment requirements, this component fully works only in staging/prod env.
 */

import React from "react";
import styled, { useTheme } from "styled-components";
// @ts-ignore
import AppleSignin from "react-apple-signin-auth";

import { APPLE_LOGIN_CLIENT_ID } from "@config/consts";
import { LOGIN } from "@config/testIds/login";

import { Apple as AppleLogo, AppleIcon } from "@ui/Assets/SocialIcons";
import { rem } from "@ui/helpers";
import { Button, TestWrapper } from "@ui/Atoms";

import { randomString } from "@services/RandomString";

/**
 * When using connect-account variant, please remember to pass connectAccountText translation
 */
type Variant = "login" | "connect-account" | "button";

interface SuccessResp {
  authorization: {
    code: string;
    id_token: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

interface RenderProps {
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  onSuccess: (resp: SuccessResp) => void;
  onError: () => void;
  render?: (renderProps: RenderProps) => JSX.Element;
  variant?: Variant;
  translations?: {
    connectAccountText?: string;
    buttonText?: string;
  };
}

const AppleLogin = (props: Props): React.ReactElement => {
  const theme = useTheme();
  const nonce = randomString(8);
  const state = randomString(6);

  const appleColor = theme.colors.black800;

  const render = (renderProps: RenderProps): React.ReactElement => {
    switch (props.variant) {
      case "login":
        return (
          <TestWrapper testId={LOGIN.APPLE_ICON}>
            <IconInCircle onClick={renderProps.onClick} color={appleColor}>
              <AppleLogo color={appleColor} />
            </IconInCircle>
          </TestWrapper>
        );

      case "connect-account":
        return (
          <React.Fragment>
            <IconSection>
              <IconWrapper socialColor={appleColor}>
                <AppleLogo color={theme.colors.white} />
              </IconWrapper>
              <span>Apple</span>
            </IconSection>
            <a href="#" onClick={renderProps.onClick}>
              {props.translations?.connectAccountText}
            </a>
          </React.Fragment>
        );

      case "button":
        return (
          <React.Fragment>
            <StyledButton
              type={"button"}
              variant={"borderBlack"}
              size={"fill"}
              onClick={renderProps.onClick}
            >
              <ButtonContentWrapper>
                <AppleIcon size={32} />
                {props.translations?.buttonText}
              </ButtonContentWrapper>
            </StyledButton>
          </React.Fragment>
        );

      default:
        return <React.Fragment />;
    }
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: String(APPLE_LOGIN_CLIENT_ID),
        scope: "email name",
        redirectURI: `${window.location.origin}/oauth/check-apple`,
        state,
        nonce,
        usePopup: true,
      }}
      onSuccess={(resp: SuccessResp) => {
        props.onSuccess(resp);
      }}
      onError={(err: any) => {
        console.error(err);
        props.onError();
      }}
      render={props.render ? props.render : render}
    />
  );
};

export { AppleLogin };

const IconInCircle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props => props.theme.margins.base_x4};
  max-width: ${props => props.theme.margins.base_x4};
  min-height: ${props => props.theme.margins.base_x4};
  max-height: ${props => props.theme.margins.base_x4};
  border: 1px solid ${props => props.color};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.color};

    svg {
      path {
        ${props => `fill: ${props.theme.colors.white}`};
      }
    }
  }
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span<{ socialColor: string }>`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.socialColor};
  padding: ${props => props.theme.margins.half};
  width: ${rem(30)};
  height: ${rem(30)};
  margin-right: ${props => props.theme.margins.base_x2};
`;

const StyledButton = styled(Button)`
  text-align: left;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(22)};

  svg {
    margin-right: ${props => props.theme.margins.base};
  }
`;
