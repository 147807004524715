import { useAuthDispatch, useAuthSelector } from "@hooks/useAuth/redux";

import {
  getIsAdminImpersonator,
  getIsUserAdmin,
  getIsUserAgent,
  getIsUserB2B,
  getIsUserClient,
  getIsUserDataFetched,
  getIsUserLoggedIn,
  getIsUserStateKnown,
  getUserData,
  getUserDefaultCleaningType,
  getUserZipCode,
  logoutUser,
  getUrssafRegistrationStatus,
  getUserUrssafRegistrationDate,
  getUserPaymentMethodStatus,
} from "./redux/user";
import { getOauthProvider, getOauthUser } from "./redux/oauth";

const useAuth = () => {
  const dispatch = useAuthDispatch();
  const user = useAuthSelector(getUserData);
  const isUserStateKnown = useAuthSelector(getIsUserStateKnown);
  const isUserLoggedIn = useAuthSelector(getIsUserLoggedIn);
  const isUserDataFetched = useAuthSelector(getIsUserDataFetched);
  const isUserAdmin = useAuthSelector(getIsUserAdmin);
  const userZipCode = useAuthSelector(getUserZipCode);
  const isUserClient = useAuthSelector(getIsUserClient);
  const isUserAgent = useAuthSelector(getIsUserAgent);
  const isAdminImpersonator = useAuthSelector(getIsAdminImpersonator);
  const isUserB2B = useAuthSelector(getIsUserB2B);
  const userDefaultCleaningType = useAuthSelector(getUserDefaultCleaningType);
  const oAuthProvider = useAuthSelector(getOauthProvider);
  const oAuthUser = useAuthSelector(getOauthUser);
  const urssafRegistrationStatus = useAuthSelector(getUrssafRegistrationStatus);
  const urssafRegistrationDate = useAuthSelector(getUserUrssafRegistrationDate);
  const paymentMethodStatus = useAuthSelector(getUserPaymentMethodStatus);

  const logout = () => {
    return dispatch(logoutUser());
  };

  return {
    user,
    isUserStateKnown,
    isUserLoggedIn,
    isUserDataFetched,
    isUserAdmin,
    userZipCode,
    isUserClient,
    isUserAgent,
    isAdminImpersonator,
    isUserB2B,
    userDefaultCleaningType,
    oAuthProvider,
    oAuthUser,
    logout,
    urssafRegistrationStatus,
    urssafRegistrationDate,
    paymentMethodStatus,
  };
};

export { useAuth };
